import React, { useState } from "react";
import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router";
import SimpleHeaderSection from "../../../components/abs/organisms/SimpleHeaderSection";
import { SignupPath } from "../../../../router/RouterPath";
import {
  textColorDeepGray,
  textColorGray,
  textColorLightBlack,
} from "../../../themes/Color";
import Spacer from "../../../components/abs/atoms/Spacer";
import BookWithInfo from "../../../components/context/book/BookWithInfo";
import { VerticalScrollableStack } from "../../../components/abs/base/Scrollable";
import { CancelRounded } from "@mui/icons-material";
import { BookEntity } from "../../../../domain/entities/book/BookEntity";
import SearchBookDialog from "../../../dialogs/childs/SearchBookDialog";
import { v4 } from "uuid";
import { useSignupState } from "../../../../redux/features/local/signup/SignupSlice";
import SearchBar from "../../../components/context/search/SearchBar";
import { ReactComponent as SearchIcon } from "../../../../icons/icon_search.svg";
import { BookmarkTypes } from "../../../../domain/types/BookmarkTypes";

const Header = ({
  onBackClick,
  onSkipClick,
}: {
  onBackClick: () => void;
  onSkipClick: () => void;
}) => {
  return (
    <>
      <Stack>
        <SimpleHeaderSection
          titleTextComponent={"이야기 나누고 싶은 책은"}
          onClose={onBackClick}
          optionComponent={
            <Button onClick={onSkipClick}>
              <Typography
                variant={"caption"}
                color={textColorDeepGray}
                children={"건너뛰기"}
              />
            </Button>
          }
        />
        <Spacer h={"8px"} />

        <Stack paddingX={"1rem"}>
          <Typography
            variant={"caption"}
            color={textColorGray}
            children="읽은 책을 등록하면, 같은 책을 읽은 사람을 소개받을 수 있어요"
          />
        </Stack>
      </Stack>
    </>
  );
};

const RepresentBookItem = ({
  book,
  onItemClick,
  onDeleteClick,
}: {
  book: BookEntity;
  onItemClick: () => void;
  onDeleteClick: () => void;
}) => {
  return (
    <>
      <Stack width={"100%"}>
        <Button onClick={onItemClick}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <BookWithInfo book={book} />

            <IconButton
              onClick={onDeleteClick}
              sx={{ width: "fit-content", height: "fit-content" }}
              children={<CancelRounded />}
            />
          </Stack>
        </Button>

        <Divider sx={{ my: "1rem" }} />
      </Stack>
    </>
  );
};

const useRepresentBook = () => {
  const history = useHistory();

  const [openSearchDialog, setOpenSearchDialog] = useState(false);

  const { data, mutate } = useSignupState();
  const { representBooks: selectedBooks } = data;

  const isMaxLengthSelectedBooks = selectedBooks.length >= 10;

  const handleSelectBook = (book: BookEntity) => {
    if (isMaxLengthSelectedBooks) {
      alert("대표 책은 최대 10개까지 등록 가능합니다.");
      return;
    }

    if (selectedBooks.some((it) => it.book.bookId === book.bookId)) {
      alert("이미 대표 책으로 등록되어 있습니다.");
      return;
    }

    const newSignupState = { ...data };
    newSignupState.representBooks = [...selectedBooks, {book: book, type: BookmarkTypes.FINISHED}];
    mutate(newSignupState);

    setOpenSearchDialog(false);
  };

  const handleMoreAddBookClick = () => {
    if (isMaxLengthSelectedBooks) {
      alert("대표 책은 최대 10개까지 등록 가능합니다.");
      return;
    }

    setOpenSearchDialog(true);
  };

  const handleDeleteBook = (book: BookEntity) => {
    const newState = selectedBooks.filter((it) => it.book.bookId !== book.bookId);

    const newSignupState = { ...data };
    newSignupState.representBooks = newState;
    mutate(newSignupState);
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleSkip = () => {
    history.push(SignupPath.WELCOME);
  };

  const handleSubmit = () => {
    history.push(SignupPath.WELCOME);
  };

  return {
    selectedBooks,
    openSearchDialog,
    setOpenSearchDialog,
    handleSelectBook,
    handleMoreAddBookClick,
    handleDeleteBook,
    handleBack,
    handleSkip,
    handleSubmit,
  };
};

const RepresentBook = () => {
  const {
    selectedBooks,
    openSearchDialog,
    setOpenSearchDialog,
    handleSelectBook,
    handleMoreAddBookClick,
    handleDeleteBook,
    handleBack,
    handleSkip,
    handleSubmit,
  } = useRepresentBook();

  const isEmptySelectedBooks = selectedBooks.length <= 0;

  //

  const renderSearchBar = isEmptySelectedBooks && (
    <SearchBar
      placeholder="책제목/저자/출판사를 검색해보세요"
      variant="outlined"
      size={"small"}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"}>
            <IconButton
              edge="start"
              aria-label="search"
              children={<SearchIcon />}
            />
          </InputAdornment>
        ),
      }}
      onClick={() => setOpenSearchDialog(true)}
    />
  );

  const renderResultSection = !isEmptySelectedBooks && (
    <Stack flex={1}>
      <Stack flex={1}>
        {selectedBooks.map((bookmark) => (
          <RepresentBookItem
            key={v4()}
            book={bookmark.book}
            onItemClick={() => {}}
            onDeleteClick={() => handleDeleteBook(bookmark.book)}
          />
        ))}
      </Stack>
      <Spacer h={"2rem"} />

      <Button onClick={handleMoreAddBookClick}>
        <Typography
          variant={"caption"}
          fontWeight={500}
          sx={{ textDecoration: "underline" }}
          children={`더 추가할래요! (${selectedBooks.length}/10)`}
        />
      </Button>
      <Spacer h={"1rem"} />
    </Stack>
  );

  const renderSubmitButton = !isEmptySelectedBooks && (
    <Stack px={"1rem"}>
      <Button
        variant="contained"
        color="primary"
        size={"large"}
        fullWidth
        children={"계속"}
        onClick={handleSubmit}
      />
      <Spacer h={"1rem"} />
    </Stack>
  );

  return (
    <>
      <Stack height={"100%"} color={textColorLightBlack}>
        <VerticalScrollableStack height={"100%"}>
          <Header onBackClick={handleBack} onSkipClick={handleSkip} />
          <Spacer h={"40px"} />

          <Stack
            justifyContent={"space-between"}
            height={"100%"}
            paddingX={"1rem"}
          >
            {renderSearchBar}
            {renderResultSection}
          </Stack>
        </VerticalScrollableStack>
        {renderSubmitButton}

        <SearchBookDialog
          isOpen={openSearchDialog}
          setOpen={setOpenSearchDialog}
          onItemClick={handleSelectBook}
          type={BookmarkTypes.FINISHED}
        />
      </Stack>
    </>
  );
};

export default RepresentBook;
