import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {ReviewEntity} from "../../../../../domain/entities/review/ReviewEntity";
import { RootState } from "../../../../store/QihanoStore";

const initialState: Array<ReviewEntity> = []

//PayloadAction : 액션의 payload 필드의 타입을 지정할 수 있게 해주는 제네릭
const ReviewsSlice = createSlice({
    name: 'reviews', //action
    initialState: initialState, //default value
    reducers: {
        pullReviews: (state, action: PayloadAction<Array<ReviewEntity>>) => action.payload,
    }
})

export const {pullReviews} = ReviewsSlice.actions;

export const useReviewsState = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.reviews, shallowEqual)

    const mutate = (state: Array<ReviewEntity>) => {
        dispatch(pullReviews(state))
    }


    return {
        mutate,
        data
    }
}

export default ReviewsSlice.reducer;