import styled from "@emotion/styled";
import {isMobile} from "../../../../utils/responsive/CheckResponsive";
import {Stack} from "@mui/material";


const HorizontalScrollable = styled("div")`
  overflow-x: scroll;
  padding-bottom: 8px;
`

export const VerticalScrollableWithNoScrollbar = styled("div")`
  overflow-y: scroll;

  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none;
  }
`

export const HorizontalScrollableStyle = `
  overflow-x: scroll;
  padding-bottom: 1rem;
  
  ${
    isMobile() ? `
      padding-bottom: 0;

      scrollbar-width: none; 
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    ` : ""
}
`

export const VerticalScrollableStyle = `
  overflow-y: scroll;

  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none;
  }
`

export const HorizontalScrollableStack = styled(Stack)`
  ${HorizontalScrollableStyle}
`

export const VerticalScrollableStack = styled(Stack)`
  ${VerticalScrollableStyle}
`

export default HorizontalScrollable
