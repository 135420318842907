import {Button, Divider, Stack} from "@mui/material";
import {NotificationItem} from "../../components/NotificationItem";
import React from "react";
import {VerticalScrollableStack} from "../../../../../components/abs/base/Scrollable";
import { v4 } from "uuid";
import { NoticeEntity } from "../../../../../../domain/entities/notice/NoticeEntity";

export const NoticeSection = ({noticeList}:{noticeList:Array<NoticeEntity>}) => {
    // const temp = Array.from({length: 10}, () => "asd")

    const handleNoticeClick = (url: string) => {
        window.open(url)
    }

    return <>
        <VerticalScrollableStack height={"100%"}>
            {noticeList.map(it =>
                <Stack key={v4()} width={"100%"}>
                    <Button sx={{padding: "1rem"}} onClick={() => handleNoticeClick(it.content)}>
                        <NotificationItem
                            title={it.title}
                            dateString={it.createDate}
                            tag={it.writerId}
                        />
                    </Button>
                    <Divider sx={{mx: "1rem"}}/>
                </Stack>
            )}
        </VerticalScrollableStack>
    </>
}
