import BottomSheet from "../../../../../../../../components/abs/atoms/BottomSheet";
import {Button, Stack} from "@mui/material";
import {DrawerProps} from "@mui/material/Drawer/Drawer";
import {ReactionTypes} from "../../../../../../../../../domain/types/ReactionTypes";
import React from "react";
import {useReactionsBottomSheetSlice} from "../../../../../../../../../redux/features/local/dialogs/reaction/reaction_bs/ReactionBottomSheetSlice";
import {v4} from "uuid";

export type ReactionsBottomSheetProps = {
    drawerProps: DrawerProps,
    onReaction: (reactionType: ReactionTypes) => void
}

export const ReactionsBottomSheet = () => {
    const {data} = useReactionsBottomSheetSlice()
    const {drawerProps, onReaction} = data

    //

    const renderItems =
        Object
            .values(ReactionTypes)
            .map((it, index) =>
                <Button
                    key={v4()}
                    size={"large"}
                    sx={{justifyContent: "start"}}
                    children={it}
                    onClick={() => onReaction(it)}
                />
            )

    return <>
        <BottomSheet
            anchor={"bottom"}
            {...drawerProps}
        >
            <Stack py={"1rem"}>
                {renderItems}
            </Stack>
        </BottomSheet>
    </>
}
