import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BookEntity} from "../../../../domain/entities/book/BookEntity";

/**
 * 마이 페이지 내 서재 - 책 목록
 * */
export type MyPageBooks = {
    finishedBooks: Array<BookEntity>,
    wishBooks: Array<BookEntity>
}

const initialState: MyPageBooks = {finishedBooks: [], wishBooks: []}

const myPageBooksSlice = createSlice({
    name: 'myPageBooks',
    initialState: initialState,
    reducers: {
        pullMyPageBooks: (state, action: PayloadAction<MyPageBooks>) =>
            action.payload,
    }
})

export const {pullMyPageBooks} = myPageBooksSlice.actions
export default myPageBooksSlice.reducer
