import React, { ChangeEvent, useState } from "react";
import { Button, Stack, TextField } from "@mui/material";
import { useHistory } from "react-router";
import SimpleHeaderSection from "../../../components/abs/organisms/SimpleHeaderSection";
import { SignupPath } from "../../../../router/RouterPath";
import { VerticalScrollableStack } from "../../../components/abs/base/Scrollable";
import Spacer from "../../../components/abs/atoms/Spacer";
import { useSignupState } from "../../../../redux/features/local/signup/SignupSlice";
import { validateName } from "../../../../utils/regex/Regex";
import { getByteSizeOfText } from "../../../../utils/string/String";
import {
  NAME_MAX_BYTE_SIZE,
  NAME_MIN_BYTE_SIZE,
} from "../../../../domain/policy/name.policy";
import { doubleCheckName } from "../../../../redux/features/remote/account/AccountApi";


const NamePage = () => {
  const history = useHistory();

  const { data, mutate } = useSignupState();
  const { username } = data;
  const [error, setError] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isValueOverMaxByte = getByteSizeOfText(value) > NAME_MAX_BYTE_SIZE;

    if (isValueOverMaxByte) {
      return;
    }

    const newSignupState = { ...data };
    newSignupState.username = value;
    mutate(newSignupState);
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleSubmit = async () => {
    setIsDisabled(true)
    if (!validateName(username)) {
      setError("이름은 한글 또는 영문으로만 입력해주세요.");
      setIsDisabled(false)
      return;
    }

    doubleCheckName(username).then(response => {
      setIsDisabled(false)
      if(!!response) {
        setError(response)
      } else {
        history.push(SignupPath.GENDER);
      }
    })
  };

  const nameByteSize = getByteSizeOfText(username);
  const isNameUnderMinByte = nameByteSize < NAME_MIN_BYTE_SIZE;

  const submitBtnDisable = isNameUnderMinByte;

  //

  return (
    <>
      <VerticalScrollableStack height={"100%"}>
        <SimpleHeaderSection
          titleTextComponent={"내 닉네임은"}
          onClose={handleBack}
        />

        <Spacer h={"94px"} />

        <Stack
          justifyContent={"space-between"}
          height={"100%"}
          padding={"1rem"}
        >
          <TextField
            type="text"
            required
            variant="standard"
            autoComplete="off"
            placeholder="본명 혹은 별명을 입력하세요"
            error={!!error}
            helperText={error && error}
            value={username}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />

          <Button
            size={"large"}
            variant="contained"
            fullWidth
            disabled={submitBtnDisable || isDisabled}
            children={"계속"}
            onClick={handleSubmit}
          />
        </Stack>
      </VerticalScrollableStack>
    </>
  );
};

export default NamePage;
