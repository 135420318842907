import {BookEntity} from "../../../../../../domain/entities/book/BookEntity";
import {Button, Stack, Typography} from "@mui/material";
import {textColorDeepGray, textColorGray, textDisabledColor} from "../../../../../themes/Color";
import Spacer from "../../../../../components/abs/atoms/Spacer";

export const BookDetailInfoSection = (
    {book}: { book: BookEntity }
) => {

    const handleDetailInfoButton = () => {
        window.open(book.directUrl)
    }

    return <>
        <Stack>
            <Stack textAlign={"center"}>
                <Typography
                    variant={"h6"}
                    color={textColorDeepGray}
                    children={book.title}
                />
                <Typography
                    variant={"subtitle2"}
                    color={textDisabledColor}
                    children={book.authors.join(', ')}
                />
            </Stack>
            <Spacer h={"1rem"}/>

            <Stack>
                <Typography
                    variant={"caption"}
                    color={textColorDeepGray}
                    fontWeight={500}
                    children={"책소개"}
                />
                <Spacer h={"0.4rem"}/>

                <Typography
                    variant={"body2"}
                    color={textColorGray}
                    children={book.description+"…"}
                />
                <Spacer h={"0.4rem"}/>

                <Button sx={{width: "fit-content", ml: "-6px"}} onClick={handleDetailInfoButton}>
                    <Typography
                        variant={"caption"}
                        color={"secondary"}
                        fontWeight={500}
                        children={"더 알아보기"}
                    />
                </Button>
            </Stack>
        </Stack>
    </>
}
