import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ChatRoom } from "../../../../domain/entities/room/ChatRoom";
import { RootState } from "../../../store/QihanoStore";


const initialState: Array<ChatRoom> = []

const ChatRoomsSlice = createSlice({
    name: 'chatRooms', // action
    initialState: initialState, // default value
    reducers: {
        pullChatRooms: (state, action: PayloadAction<Array<ChatRoom>>) =>
            action.payload,
    }
})

const {pullChatRooms} = ChatRoomsSlice.actions

export const useChatRoomsState = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.chatRooms, shallowEqual)

    const mutate = (state: Array<ChatRoom>) => {
        dispatch(pullChatRooms(state))
    }


    return {
        mutate,
        data
    }
}

export default ChatRoomsSlice.reducer