import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../store/QihanoStore";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BookEntity, initialBookEntity} from "../../../../../../domain/entities/book/BookEntity";
import {BookDetailActionType} from "../../../../../../ui/dialogs/childs/book/detail/ButtonActionsCase";

type BookDetailDialogParams = {
    isOpen: boolean, //다이얼로그 열림 여부
    book: BookEntity,
    type: number,
    uid: string
}

const initialState: BookDetailDialogParams = {
    isOpen: false,
    book: initialBookEntity,
    type: BookDetailActionType.FINISH_BOOK,
    uid: ""
}

const BookDetailDialogSlice = createSlice({
    name: 'bookDetailDialog',
    initialState: initialState,
    reducers: {
        setBookDetailDialogState: (state, action: PayloadAction<BookDetailDialogParams>) =>
            action.payload,
    }
})

export const useBookDetailDialogSlice = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.bookDetailDialog)

    const mutate = (data: BookDetailDialogParams) => {
        dispatch(setBookDetailDialogState({
            ...data
        }))
    }

    const close = () => {
        mutate({
            ...data,
            isOpen: false
        })
    }

    return {
        close,
        mutate,
        data
    }
}

export const {setBookDetailDialogState} = BookDetailDialogSlice.actions
export default BookDetailDialogSlice.reducer
