import { Button, Divider, Stack, Typography } from "@mui/material";
import { textColorDeepGray, textColorGray, textColorLightBlack } from "../../themes/Color";
import { useHistory } from "react-router";
import { VerticalScrollableStack } from "../../components/abs/base/Scrollable";
import Spacer from "../../components/abs/atoms/Spacer";
import SimpleHeaderSection from "../../components/abs/organisms/SimpleHeaderSection";
import { useEffect, useState } from "react";
import { SessionPropertyKey } from "../../../session/SessionPropertyKey";
import ImagePickerSwiper from "../../components/context/organisms/image_picker/ImagePickerSwiper";
import { Picture } from "../../../domain/vo/Picture";
import { decideFaceAuth, fetchFaceAuth } from "../../../redux/features/remote/account/AccountApi";
import { FaceAuthEntity } from "../../../domain/entities/admin/FaceAuthEntity";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperClass from "swiper/types/swiper-class";
import { v4 } from "uuid";
import { createStyles, makeStyles } from "@material-ui/styles";
import AspectRatioBox from "../../components/abs/molecules/AspectRatioBox";
import { ImagePickerItemCheckable } from "../../components/context/organisms/image_picker/ImagePickerItem";
import styled from "@emotion/styled";

const useSwiperStyles = makeStyles(() =>
  createStyles({
    styledSwiper: {
      width: "100%",
      height: "100%",
    },
  })
);

const StyledImage = styled("img")`
  width: 50%;
  height: 50%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 1px 1px 3px #999;

  // mobile chrome aspect ratio
  aspect-ratio: 1/1;
`

const AdminFaceAuthPage = () => {
  const history = useHistory();
  const styles = useSwiperStyles();

  const myId = sessionStorage.getItem(SessionPropertyKey.USER_ID) ?? "-1";

  const [faceAuths, setFaceAuths] = useState<Array<FaceAuthEntity>>([]);
  const [imageFiles, setImageFiles] = useState<Array<Picture>>([]);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [swiperIndex, setSwiperIndex] = useState(0);

  useEffect(() => {
    fetchFaceAuth().then((response) => {
      if (response) {
        setFaceAuths(response);
        setImageFiles(faceAuths.map((it) => it.picture));
      }
    });
  }, []);

  const handleBack = () => {
    history.goBack();
  };


  const handleSubmit = (isApprove: boolean) => {
    decideFaceAuth(faceAuths[swiperIndex], isApprove).then(response => {
    })
  };

  const handleSwiperChange = (swiper: SwiperClass) => {
    setSwiperIndex(swiper.realIndex);
  };

  const renderFaceAuths = faceAuths.map((it) => (
    <SwiperSlide key={v4()}>
      <Stack>
      <StyledImage src={it.picture.url} alt="picture"/>
       <Typography variant={"caption"} color={textColorDeepGray}>
                <span>등록날짜 : {it.date}</span><br/>
                <span>성별 : {it.gender}</span><br/>
                <span>닉네임 : {it.nickname}</span>
            </Typography>
      </Stack>
    </SwiperSlide>
  ))

  return myId === process.env.REACT_APP_ADMIN_UID ? (
    <>
      <VerticalScrollableStack height={"100%"} color={textColorLightBlack}>
        <SimpleHeaderSection
          titleTextComponent={"얼굴인증"}
          onClose={handleBack}
        />
        <Spacer h={"8px"} />
        <Stack height={"100%"} justifyContent={"space-between"}>
          <Swiper
            className={styles.styledSwiper}
            onSwiper={(swiper) => setSwiper(swiper)}
            onSlideChange={(swiper) => handleSwiperChange(swiper)}
          >
            {renderFaceAuths}
          </Swiper>
          <Spacer h={"100px"} />

          <Stack
            alignItems={"center"}
            direction={"row"}
            textAlign={"center"}
            px={"1rem"}
            pb={"1rem"}
          >
            <Button
            fullWidth
              variant={"contained"}
              size={"large"}
              children={"반려"}
              onClick={()=>{handleSubmit(false)}}
            />
            <Spacer w="10px"/>
            <Button
            fullWidth
              variant={"contained"}
              size={"large"}
              children={"승인"}
              onClick={()=>{handleSubmit(true)}}
            />
          </Stack>
        </Stack>
      </VerticalScrollableStack>
    </>
  ) : (
    <></>
  );
};

export default AdminFaceAuthPage;
