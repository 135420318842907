import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../store/QihanoStore";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReviewEditDialogProps} from "../../../../../../ui/dialogs/childs/review/edit/ReviewEditDialog";


const initialState: ReviewEditDialogProps = {
    isOpen: false
}

const ReviewEditDialogSlice = createSlice({
    name: 'editReviewDialog',
    initialState: initialState,
    reducers: {
        setReviewEditDialogState: (state, action: PayloadAction<ReviewEditDialogProps>) =>
            action.payload,
    }
})

export const useReviewEditDialogSlice = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.reviewEditDialog)

    const mutate = (state: ReviewEditDialogProps) => {
        dispatch(setReviewEditDialogState(state))
    }

    const close = () => {
        mutate({
            ...data,
            isOpen: false,
        })
    }


    return {
        close,
        mutate,
        data
    }
}

export const {setReviewEditDialogState} = ReviewEditDialogSlice.actions
export default ReviewEditDialogSlice.reducer
