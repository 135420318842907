import {Button, IconButton, Stack, Toolbar, Typography} from "@mui/material";
import {AddRounded, CloseRounded} from "@mui/icons-material";
import QihanoDialog from "../../../../../components/context/base/QihanoDialog";
import {SlideUpTransition} from "../../../../../animations/Transitions";
import {VerticalScrollableStack} from "../../../../../components/abs/base/Scrollable";
import {ImageFilesState} from "../../../../../components/context/organisms/image_picker/ImagePickerHook";
import ImagePickerSwiper from "../../../../../components/context/organisms/image_picker/ImagePickerSwiper";
import Spacer from "../../../../../components/abs/atoms/Spacer";
import {primaryVariantColor, textColorDeepGray, textColorGray} from "../../../../../themes/Color";
import React, {useState} from "react";
import {Picture} from "../../../../../../domain/vo/Picture";
import {Swiper} from "swiper";
import {ReactComponent as CertificationIcon} from "../../../../../../icons/icon_certification_40.svg";


const Header = (p: { onClose: () => void }) => {
    return <>
        <Toolbar>
            <IconButton
                edge="start"
                onClick={p.onClose}
                aria-label="close"
                children={<CloseRounded/>}
            />
        </Toolbar>
    </>
}


const GuideSection = () => {
    return <>
        <Stack width={"100%"} alignItems={"center"} textAlign={"center"}>
            <CertificationIcon/>
            <Spacer h={"1rem"}/>
            <Typography
                variant={"h5"}
                children={"얼굴인증"}
            />
            <Spacer h={"0.5rem"}/>

            <Typography variant={"caption"} color={textColorGray}>
                <span>회원님 얼굴이 나온 사진을 한 장 골라 인증해주세요.</span><br/>
                <span>얼굴인증 배지를 받으면 다른 회원의 사진을 최대 세 장까지 볼 수 있어요.</span>
            </Typography>
        </Stack>
    </>
}


export type FaceAuthDialogProps = {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    onSubmitAuth: (picture: Picture) => void,
} & ImageFilesState

const FaceAuthDialog = (
    {isOpen, setIsOpen, onSubmitAuth, imageFiles, setImageFiles}: FaceAuthDialogProps
) => {
    const [slideIndex, setSlideIndex] = useState(0)

    // const isMaxImageFiles = imageFiles.length >= 3



    const handleClose = () => {
        setIsOpen(false)
    }

    const handleSlideChange = (swiper: Swiper) => {
        setSlideIndex(swiper.realIndex)
    }

    const handleSubmit = () => {
        onSubmitAuth(imageFiles[slideIndex])
    }

    //

    const [checkedPicture, setCheckedPicture] = useState<Picture | undefined>(undefined)
    const handleCheckedPicture = (picture: Picture) => {
        if(checkedPicture === picture) {
            setCheckedPicture(undefined)
            return
        }

        setCheckedPicture(picture)
    }

    // const renderImageAddItemContent =
    //     <Stack color={primaryVariantColor} alignItems={"center"}>
    //         <AddRounded fontSize={"large"}/>
    //         <Typography variant={"caption"} children={"직접 올리기"}/>
    //     </Stack>

    // const imageAddItemProps = {
    //     contentItem: !isMaxImageFiles && renderImageAddItemContent
    // }

    //

    return (
        <QihanoDialog
            fullScreen={true}
            open={isOpen}
            TransitionComponent={SlideUpTransition}
        >
            <VerticalScrollableStack height={"100%"}>
                <Header onClose={handleClose}/>
                <Spacer h={"1.5%"}/>

                <GuideSection/>
                <Spacer h={"5%"}/>

                <Stack height={"100%"} justifyContent={"space-between"}>
                    <ImagePickerSwiper
                        imageFiles={imageFiles}
                        setImageFiles={setImageFiles}
                        swiperProps={{
                            onSlideChange: handleSlideChange
                        }}
                        // imageAddItemProps={imageAddItemProps}
                        checkedPicture={checkedPicture}
                        setCheckedPicture={handleCheckedPicture}
                        disableDelete={true}
                    />
                    <Spacer h={"100px"}/>

                    <Stack
                        alignItems={"center"}
                        textAlign={"center"}
                        px={"1rem"}
                        pb={"1rem"}
                    >
                        <Typography
                            variant={"caption"}
                            color={textColorDeepGray}
                        >
                            <span>인증심사가 완료된 후 프로필사진을 변경하시면</span><br/>
                            <span>얼굴인증 배지가 사라집니다.</span>
                        </Typography>
                        <Spacer h={"24px"}/>

                        <Button
                            fullWidth
                            variant={"contained"}
                            disabled={!checkedPicture}
                            size={"large"}
                            children={"인증 요청하기"}
                            onClick={handleSubmit}
                        />
                    </Stack>
                </Stack>

            </VerticalScrollableStack>
        </QihanoDialog>
    )
}

export default FaceAuthDialog
