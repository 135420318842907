import {AppBar, IconButton, Stack, Toolbar, Typography} from "@mui/material";
import {ArrowBackRounded} from "@mui/icons-material";
import Spacer from "../../../../../components/abs/atoms/Spacer";
import React from "react";
import {ReactComponent as MoreIcon} from "../../../../../../icons/icon_more.svg";
import { UserEntity } from "../../../../../../domain/entities/user/UserEntity";

export type ChatAppbarProps = {
    partner: UserEntity,
    onClose: () => void,
    onOption: () => void
}

export const ChatAppbar = (
    {partner, onClose, onOption}: ChatAppbarProps
) => {
    return <>
        <AppBar position={"relative"} color={"transparent"} elevation={0}>
            <Toolbar sx={{justifyContent: "space-between"}}>
                <Stack direction={"row"} alignItems={"center"}>
                    <IconButton
                        edge="start"
                        onClick={onClose}
                        children={
                            <ArrowBackRounded/>
                        }
                    />
                    <Spacer w={"0.2rem"}/>
                    <Typography
                        variant={"subtitle2"}
                        children={partner.nickname}
                    />
                </Stack>
                <Stack>
                    <IconButton
                        edge="end"
                        onClick={onOption}
                        children={
                            <MoreIcon/>
                        }
                    />
                </Stack>
            </Toolbar>
        </AppBar>
    </>
}
