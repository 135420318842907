import { Stack, Typography } from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../../../firebase-config";
import GeneralPath, { AuthPath } from "../../../router/RouterPath";
import { SessionPropertyKey } from "../../../session/SessionPropertyKey";
import QihanoTextLogo from "../../components/context/TextLogo";
import { primaryColor } from "../../themes/Color";

export const SplashPage = () => {
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        //로그인되어있는 경우
        sessionStorage.setItem(SessionPropertyKey.USER_ID, user.uid);
        window.location.href = GeneralPath.MAIN;
      } else {
        window.location.href = AuthPath.SOCIAL;
      }
    });
  }, []);

  //   setTimeout(() => {
  //     window.location.href = AuthPath.SOCIAL;
  //   }, 1500);

  return (
    <>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        height={"85%"}
        color={"white"}
        // sx={{ backgroundColor: primaryColor }}
      >
        {/* <QihanoTextLogo /> */}
        <img src={`/assets/img/qihano_logo.png`} alt={"qihano"} width={"15%"} />
        {/* <Typography
          variant={"body1"}
          children={"키하노"}
          sx={{ opacity: 0.8 }}
        /> */}
      </Stack>
      <Stack justifyContent={"center"} alignItems={"center"}>
        <img
          src={`/assets/img/mhsj_horizontal.png`}
          alt={"mhsj"}
          width={"30%"}
        />
      </Stack>
    </>
  );
};
