import React from "react";
import {SlideUpTransition} from "../../../animations/Transitions";
import {textColorLightBlack} from "../../../themes/Color";
import QihanoDialog from "../../../components/context/base/QihanoDialog";
import {VerticalScrollableStack} from "../../../components/abs/base/Scrollable";
import {AppBar, Button, IconButton, Stack, Toolbar} from "@mui/material";
import {CloseRounded} from "@mui/icons-material";
// import {myInfoMock, userMock3} from "../../../../domain/mocks/user/UsersDB";
import Spacer from "../../../components/abs/atoms/Spacer";
import {ThinkronizedAvatarsSection} from "./sections/avatars/ThinkronizedAvatarsSection";
import {ThinkronizedTextSection} from "./sections/text/ThinkronizedTextSection";
import {useThinkronizedDialogSlice} from "../../../../redux/features/local/dialogs/thinkronized/ThinkronizedDialogSlice";
import {useChatDialogSlice} from "../../../../redux/features/local/dialogs/chat/ChatDialogSlice";
import { initialUserEntity } from "../../../../domain/entities/user/UserEntity";
import { chatRoom1 } from "../../../../domain/mocks/ChatRoom.mock";
import { useUsersState } from "../../../../redux/features/remote/users/UsersSlice";
import { useMyInfo } from "../../../../redux/features/remote/users/myInfo/MyInfoSlice";
import { useChatRoomsState } from "../../../../redux/features/remote/chat/ChatRoomsSlice";
import { ChatRoom } from "../../../../domain/entities/room/ChatRoom";
import { deleteThinkronized } from "../../../../redux/features/remote/chat/ChatApi";
import { MainBottomNavTypes } from "../../../../domain/types/MainBottomNavTypes";
import { useMainBottomNavState } from "../../../../redux/features/local/main/bottom_nav/MainBottomNavSlice";


export const Header = (
    {onClose}: { onClose: () => void }
) => {
    return <>
        <AppBar position={"relative"} color={"transparent"} elevation={0}>
            <Toolbar sx={{justifyContent: "space-between"}}>
                <Stack direction={"row"} alignItems={"center"}>
                    <IconButton
                        edge="start"
                        onClick={onClose}
                        children={
                            <CloseRounded/>
                        }
                    />
                </Stack>
            </Toolbar>
        </AppBar>
    </>
}

export type ThinkronizedDialogProps = {
    isOpen: boolean,
    partnerId: string
}

export const ThinkronizedDialog = () => {
    const {mutate: mutateChatDialog} = useChatDialogSlice()

    const {close, data} = useThinkronizedDialogSlice()
    const {isOpen, partnerId} = data
    const {data:userDB} = useUsersState()
    const {data:myInfo} = useMyInfo()
    const {data:chatRooms} = useChatRoomsState()
    const {data:bottomNavState, mutate: mutateBottomNavState} = useMainBottomNavState()

    const me = myInfo
    const partner = userDB.find(user => user.uid === partnerId)    

    const handleClose = () => {
        if(partner){
            deleteThinkronized(me.uid, partner.uid);
            close();
        }
    }

    const handleSubmit = () => {
        if(bottomNavState === MainBottomNavTypes.MESSAGE){
            if(partner){
                const usersId = [me.uid, partner.uid].sort();
                const targetChatRoom = chatRooms.find(it => it.id === usersId.join('_'))
                if(targetChatRoom){
                    mutateChatDialog({isOpen: true, chatRoom: targetChatRoom});
                    deleteThinkronized(me.uid, partner.uid);
                    close()
                }
            }
        } else {
            mutateBottomNavState(MainBottomNavTypes.MESSAGE)
        }
    }

    //

    return (typeof partner !== 'undefined')? <>
        <QihanoDialog
            fullScreen={true}
            open={isOpen}
            TransitionComponent={SlideUpTransition}
        >
            <VerticalScrollableStack
                height={"100%"}
                color={textColorLightBlack}
                bgcolor={"#f9f9f9"}
                justifyContent={"space-between"}
            >
                <Stack
                sx={{ position: "absolute" }}
                width={"100%"}
                alignItems={"center"}
                >
                    <Spacer h={"3rem"}/>
                    <img src={"/assets/img/thinkronized_backlight.svg"} alt={"backlight"} />
                </Stack>
                
                <Stack>
                    <Header onClose={handleClose}/>
                    <Spacer h={"60px"}/>

                    <ThinkronizedAvatarsSection me={me} partner={partner}/>
                    <Spacer h={"44px"}/>

                    <ThinkronizedTextSection partner={partner}/>
                </Stack>

                <Stack px={"1rem"} pb={"1rem"}>
                    <Button
                        size={"large"}
                        variant={"contained"}
                        color={"secondary"}
                        children={`${partner.nickname}님의 첫 메시지 확인하기`}
                        onClick={handleSubmit}
                    />
                </Stack>
            </VerticalScrollableStack>
        </QihanoDialog>
    </> : <></>
}
