import {InputBaseProps} from "@mui/material/InputBase/InputBase";
import {Stack, Typography} from "@mui/material";
import {textColorGray} from "../../../../../themes/Color";
import Spacer from "../../../../../components/abs/atoms/Spacer";
import {FlatInput} from "../../../../../components/abs/atoms/FlatInput";
import React from "react";

export const TitleWithFlatInput = (
    p: {
        title: string
    } & InputBaseProps
) => {
    return <>
        <Stack>
            <Typography
                variant={"subtitle2"}
                color={textColorGray}
                children={p.title}
                pl={"0.8rem"}
            />
            <Spacer h={"0.4rem"}/>

            <FlatInput
                {...p}
            />
        </Stack>
    </>
}
