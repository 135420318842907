import {TermEntity} from "../../entities/terms/TermEntity";

export const termMock1: TermEntity = {
    termsIdx: 1,
    title: "이용약관 동의",
    content: "이용약관 동의 내용",
    isRequired: true
}

export const termMock2: TermEntity = {
    termsIdx: 2,
    title: "위치 기반 서비스 약관 동의",
    content: "위치 기반 서비스 약관 내용",
    isRequired: true
}

export const termMock3: TermEntity = {
    termsIdx: 3,
    title: "개인정보 수집 및 이용 동의",
    content: "개인정보 수집 및 이용",
    isRequired: true
}

export const termMock4: TermEntity = {
    termsIdx: 4,
    title: "마케팅 정보 활용 동의",
    content: "마케팅 정보 활용",
    isRequired: false
}


export const termsMock =
    [termMock1, termMock2, termMock3, termMock4]
