import { UserEntity } from './../../../../domain/entities/user/UserEntity';
import { ChatRoom, getFlattenMessages } from './../../../../domain/entities/room/ChatRoom';
import { v4 } from 'uuid';
import { MessageEntity } from './../../../../domain/entities/message/MessageEntity';
import { cloneObj } from './../../../../utils/etc';
import { BookEntity } from './../../../../domain/entities/book/BookEntity';
import { ref, set, push, child, update, get, remove } from "firebase/database"
import { database, db } from "../../../../firebase-config"
import { GroupedMessagesByDate } from '../../../../domain/entities/message/GroupedMessageEntity';
import { AnswerEntity } from '../../../../domain/entities/message/AnswerEntity';
import { deleteAnswer } from '../answer/AnswerApi';
import { toStringByFormatting } from '../../../../utils/datetime/DateTime';
import { doc, setDoc } from 'firebase/firestore';
import { NotificationEntity } from '../../../../domain/entities/notice/NotificationEntity';


export const makeChatRoom = async (myInfo: UserEntity, answer: AnswerEntity, greetingMessage: string) => {
    const partnerId = answer.senderId
    const questionMessage = answer.question
    const answerMessage = answer.message
    const sharedBook = answer.targetReview.targetBook
    let chatRooms: Array<ChatRoom> = [];
    let response = false;

    await get(child(ref(database), "chatRooms")).then((snapshot) => {
        if(snapshot.exists()){
            chatRooms = Object.values(snapshot.val());
            if(!chatRooms.some(it=>it.usersId.includes(myInfo.uid) && it.usersId.includes(partnerId))) { //이미 개설된 채팅방이 없을 때
                response = true;
            }
            else {
                alert("이미 대화하고 있는 상대입니다.")
                return false;
            }
        } else {
            response = true;
        }
    })
    .catch((error) => {
        console.log(error);
    })

    if(response){
        const regDate = toStringByFormatting(new Date());
        const usersId = [myInfo.uid, partnerId].sort();
        const questionMsg: MessageEntity = {id: v4(), senderId: myInfo.uid, message: questionMessage, regDate: regDate, userIdWhoRead: [myInfo.uid, partnerId]}
        const answerMsg: MessageEntity = {id: v4(), senderId: partnerId, message: answerMessage, regDate: regDate, userIdWhoRead: [myInfo.uid, partnerId]}
        const greetingMsg: MessageEntity = {id: v4(), senderId: myInfo.uid, message: greetingMessage, regDate: regDate, userIdWhoRead: [myInfo.uid]}
        const messages:Array<GroupedMessagesByDate> = 
        [{date: regDate.slice(0,10), messagesByTime:
            [{time:"", messagesByUserId:[{userId: myInfo.uid, messages:[questionMsg]}, {userId: partnerId, messages:[answerMsg]}]},
             {time:regDate.slice(11,16), messagesByUserId:[{userId: myInfo.uid, messages:[greetingMsg]}]}]}]

        const newChatRoom: ChatRoom = {
            id: usersId.join('_'),
            usersId: usersId,
            messages: messages,
            sharedBook: sharedBook,
            regDate: regDate
        }

        const notificationData: NotificationEntity = {
            id: usersId.join('_'),
            type: "chat",
            title: `${myInfo.nickname}님이 대화방에 회원님을 맞이했어요`,
            subTitle: "첫 메시지를 확인하세요!",
            date: toStringByFormatting(new Date())
          }
    
        set(ref(database, "chatRooms/"+usersId.join('_')), newChatRoom)
        deleteAnswer(answer)

        // const newKey = push(child(ref(database), partnerId + "/thinkronized")).key ?? "-1";
        const updates = {};
        updates[partnerId + "/thinkronized/"+myInfo.uid] = regDate;
        updates[partnerId + "/notifications/" + usersId.join('_')] = notificationData;

        update(ref(database), updates);
        return newChatRoom
    }
}

export const deleteThinkronized = (userId: string, partnerId: string) => {
    const chatRoomId = [userId, partnerId].sort().join('_');
    remove(ref(database, userId + "/thinkronized/" + partnerId))
    remove(ref(database, userId + "/notifications/" + chatRoomId))
}

export const sendMessage = (chatRoom: ChatRoom, userId: string, message: string) => {
    const cloneChatRoom: ChatRoom = cloneObj(chatRoom);
    let chatRoomMessages: Array<GroupedMessagesByDate> = cloneChatRoom.messages;
    const regDate = toStringByFormatting(new Date());
    const sendDate = regDate.slice(0,10);
    const sendTime = regDate.slice(11,16);
    const msg:MessageEntity = {id: v4(), senderId: userId, message: message, regDate: regDate, userIdWhoRead: [userId]}
    const messagesByDate = chatRoomMessages.find(it => it.date === sendDate);
    if(messagesByDate){
        const messagesByDateIndex = chatRoomMessages.findIndex(it => it.date === sendDate);
        const messagesByTime = messagesByDate.messagesByTime.find(it => it.time === sendTime)
        if(messagesByTime){
            const messagesByTimeIndex = messagesByDate.messagesByTime.findIndex(it => it.time === sendTime)
            const messagesByUser = messagesByTime.messagesByUserId.find(it => it.userId === userId)
            if(messagesByUser){
                const messageByUserIdIndex = messagesByTime.messagesByUserId.findIndex(it => it.userId === userId)
                chatRoomMessages[messagesByDateIndex].messagesByTime[messagesByTimeIndex].messagesByUserId[messageByUserIdIndex].messages = [
                    ...chatRoomMessages[messagesByDateIndex].messagesByTime[messagesByTimeIndex].messagesByUserId[messageByUserIdIndex].messages, msg]
            } else {
                chatRoomMessages[messagesByDateIndex].messagesByTime[messagesByTimeIndex].messagesByUserId = [
                    ...chatRoomMessages[messagesByDateIndex].messagesByTime[messagesByTimeIndex].messagesByUserId, {userId: userId, messages: [msg]}
                ]
            }
        } else {
            chatRoomMessages[messagesByDateIndex].messagesByTime = [
                ...chatRoomMessages[messagesByDateIndex].messagesByTime, {time: sendTime, messagesByUserId:[{userId: userId, messages: [msg]}]}
            ]
        }
    } else {
        chatRoomMessages = [...chatRoomMessages, {date: sendDate, messagesByTime:[{time: sendTime, messagesByUserId:[{userId: userId, messages: [msg]}]}]}]
    }

    const usersId = cloneChatRoom.usersId.sort().join('_');
    const updates = {};
    updates["/chatRooms/"+usersId+"/messages"] = chatRoomMessages
    update(ref(database), updates);
}


export const readAllMessages = async (chatRoom: ChatRoom, userId: string) => {
    const cloneChatRoom: ChatRoom = cloneObj(chatRoom);
    const usersId = cloneChatRoom.usersId.sort().join('_');
    const updates = {};

    const promises = cloneChatRoom.messages.map((it1, index1) => {
        return new Promise<void>((resolve, reject) => {
            it1.messagesByTime.forEach((it2, index2)=>{
                it2.messagesByUserId.forEach((it3, index3)=> {
                    it3.messages.forEach((it4, index4)=>{
                        if(!it4.userIdWhoRead.some(id=>id === userId)){
                            updates["/chatRooms/"+usersId+"/messages/"+index1+"/messagesByTime/"+index2+"/messagesByUserId/"+index3+"/messages/"+index4+"/userIdWhoRead"]
                             = [...it4.userIdWhoRead, userId]
                        }
                        resolve();
                    })
                })
            })
        })
        
    })
    await Promise.all(promises);
    update(ref(database), updates);
}


export const removeMyIdFromChatRoom = (myId: string, chatRoom: ChatRoom) => {
    const updates = {};
    updates["/chatRooms/"+chatRoom.id+"/usersId"] = chatRoom.usersId.filter(it => it !== myId);
    update(ref(database), updates);
}


export const deleteChatRoom = (chatRoom: ChatRoom) => {
    remove(ref(database, "chatRooms/" + chatRoom.id))
}


export const reportChatUser = async (myId: string, partnerId: string, chatRoom: ChatRoom) => {
    const regDate = toStringByFormatting(new Date())
    const allMessages = getFlattenMessages(chatRoom.messages)

    await setDoc(doc(db, "admin", "report_" + regDate), {
        date: regDate,
      type: "reportChatUser",
      uid: myId,
      partnerId: partnerId,
      messages: allMessages
    })
  
    return true;
  }