import {Button} from "@mui/material";
import React, {ReactNode} from "react";
import {ButtonProps} from "@mui/material/Button/Button";
import styled from "@emotion/styled";
import Spacer from "../../abs/atoms/Spacer";

const StyledButton = styled(Button)`
  justify-content: space-between;
`


type SocialLoginButtonModel = {
    icon: ReactNode,
    text: ReactNode
}

type SocialLoginButtonProps = {
    model: SocialLoginButtonModel
} & ButtonProps

const SocialLoginButton = (p: SocialLoginButtonProps) => {
    const {model} = p

    return (
        <StyledButton
            size={"large"}
            startIcon={model.icon}
            endIcon={<Spacer w={"1rem"}/>}
            children={model.text}
            {...p}
        />
    )
}

export const KakaoLoginButton = styled(SocialLoginButton)`
  background-color: #ffe500;
  color: inherit;

  &:hover {
    background-color: #ffe500;
    color: inherit;
    opacity: 0.8;
  }
`

export default SocialLoginButton
