import {Button, Card, Stack, Typography} from "@mui/material";
import React from "react";
import {textColorDeepGray, textDisabledColor} from "../../../../../../../../themes/Color";
import BookWithInfoLayout from "../../../../../../../../components/context/book/BookWithInfoLayout";
import {BookItem} from "../../../../../../../../components/context/book/BookItem";
import {BookEntity} from "../../../../../../../../../domain/entities/book/BookEntity";

export const ReviewDetailTopSection = (
    {book, onOptionClick}: {
        book: BookEntity,
        onOptionClick: () => void
    }
) => {

    const handleOptionClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        onOptionClick()
    }

    //

    const bookItem =
        <BookItem
            width={"16%"}
            imageUrl={book.thumbnailUrl}
        />

    const infoItem =
        <Stack overflow={"hidden"} whiteSpace={"nowrap"}>
            <Typography
                variant={"body1"}
                color={textColorDeepGray}
                fontSize={"15px"}
                children={book.title}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
            />

            <Stack direction={"row"}>
                <Typography
                    variant={"caption"}
                    color={textDisabledColor}
                    fontSize={"12px"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    children={book.authors.join(', ')}
                />
            </Stack>
        </Stack>

    //

    return <>
        <Stack width={"100%"}>
            <Card
                elevation={0}
                sx={{
                    border: "1px solid #efefef",
                    borderRadius: "8px",
                }}
            >
                <Stack
                    direction={"row"}
                    width={"100%"}
                    padding={"0.8rem"}
                    justifyContent={"space-between"}
                >
                    <Stack px={"4px"} overflow={"hidden"} direction={"row"}>
                        <BookWithInfoLayout bookItem={bookItem} infoItem={infoItem}/>
                    </Stack>

                    <Stack direction={"row"} justifyContent={"flex-end"} display={"none"}>
                        <Button
                            variant={"outlined"}
                            sx={{
                                width: "fit-content",
                                height: "fit-content",
                                whiteSpace: "nowrap",
                                borderRadius: "36px"
                            }}
                            onClickCapture={handleOptionClick}
                        >
                            <Typography
                                variant={"caption"}
                                fontWeight={500}
                                children={"읽고싶어요"}
                            />
                        </Button>
                    </Stack>
                </Stack>
            </Card>
        </Stack>
    </>
}
