import {useDispatch, useSelector} from "react-redux";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../../../store/QihanoStore";
import {NoticeDialogProps} from "../../../../../ui/dialogs/childs/notice/NoticeDialog";


const initialState: NoticeDialogProps = {
    isOpen: false,
}

const NoticeDialogSlice = createSlice({
    name: 'noticeDialog',
    initialState: initialState,
    reducers: {
        setNoticeDialogState: (state, action: PayloadAction<NoticeDialogProps>) =>
            action.payload,
    }
})

export const useNoticeDialogSlice = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.noticeDialog)

    const mutate = (state: NoticeDialogProps) => {
        dispatch(setNoticeDialogState(state))
    }

    const close = () => {
        mutate({
            ...data,
            isOpen: false
        })
    }

    const open = () => {
        mutate({
            isOpen: true
        })
    }

    return {
        close,
        open,
        mutate,
        data
    }
}

export const {setNoticeDialogState} = NoticeDialogSlice.actions
export default NoticeDialogSlice.reducer
