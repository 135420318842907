import {BookEntity} from "../../../../domain/entities/book/BookEntity";
import {Button, Stack} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import {v4 as generateUniqueKey} from "uuid";
import {BookItem} from "../book/BookItem";
import styled from "@emotion/styled";
import {createStyles, makeStyles} from "@material-ui/styles";

const BackgroundGradientStack = styled(Stack)`
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f7f7f7);
  box-shadow: 1px 3px 3px #ddd;
  
  overflow: hidden;
`


const useStyles = makeStyles(() => createStyles({
    styledSwiper: {
        width: "100%",
        padding: "0 1.7rem",
        marginBottom: "-6px",

        "& .swiper-wrapper": {
            display: "flex",
            alignItems: "flex-end"
        }
    },
    styledSwiperSlideItem: {
        width: "6rem",

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end"
    }
}))

//

export type HorizontalBooksProps = {
    books: Array<BookEntity>,
    onItemClick: (book: BookEntity) => void
}

export const HorizontalBooksSwiper = (
    {books, onItemClick}: HorizontalBooksProps
) => {
    const classes = useStyles()

    //

    const renderBooksSwiper =
        <Swiper
            spaceBetween={15}
            slidesPerView={"auto"}
            className={classes.styledSwiper}
        >
            {books.map((book) =>
                <SwiperSlide
                    key={generateUniqueKey()}
                    className={classes.styledSwiperSlideItem}
                >
                    <Button
                        sx={{padding: 0}}
                        onClick={() => onItemClick(book)}
                    >
                        <BookItem imageUrl={book.thumbnailUrl}/>
                    </Button>
                </SwiperSlide>
            )}
        </Swiper>

    return <>
        <BackgroundGradientStack>
            {renderBooksSwiper}
        </BackgroundGradientStack>
    </>
}
