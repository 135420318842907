import styled from "@emotion/styled";
import {ReactNode} from "react";

/**
 * 비율 유지 박스
 *
 * 박스 width 에 따라, 지정한 비율이 유지된다.
 * */

export enum RatioType {
    "1/2" = "200%",
    "1/1.2" = "120%",
    "1/1.3" = "130%",
    "1/1.5" = "150%",
    "1/1.6" = "160%",
    "1/1" = "100%",
    "4/3" = "75%",
    "2/1" = "50%",
    "2.2/1" = "45%",
    "4/1" = "25%",
    "16/9" = "56.25%",
    "30%" = "30%",
    "35%" = "35%"
}

const AspectRatioBoxContainer = styled("div")<{ ratio: RatioType }>`
  position: relative;
  width: 100%;

  padding-top: ${p => p.ratio};
  overflow: hidden;
`

const AspectRatioBoxInner = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  height: auto;
`


export type AspectRatioBoxProps = {
    ratio: RatioType,
    children: ReactNode
}

const AspectRatioBox = (
    {ratio, children}: AspectRatioBoxProps
) => {

    return (
        <AspectRatioBoxContainer ratio={ratio}>
            <AspectRatioBoxInner>
                {children}
            </AspectRatioBoxInner>
        </AspectRatioBoxContainer>
    )
}

export default AspectRatioBox
