import {MessageEntity} from "./MessageEntity";
import {initialReviewEntity, ReviewEntity} from "../review/ReviewEntity";
import { toStringByFormatting } from "../../../utils/datetime/DateTime";

export type AnswerEntity = {
    readonly isExpiration: boolean,
    readonly expirationDate: string,
    readonly receiverId: string,
    readonly targetReview: ReviewEntity,
    readonly question: string
} & MessageEntity

export const initialAnswerEntity: AnswerEntity = {
    isExpiration: false,
    expirationDate: toStringByFormatting(new Date()),
    receiverId: "",
    targetReview: initialReviewEntity,
    question: "",
    id: "",
    message: "",
    regDate: toStringByFormatting(new Date()),
    senderId: "",
    userIdWhoRead: []
}