import styled from "@emotion/styled";
import {CancelRounded} from "@mui/icons-material";
import {Button, IconButton, Stack} from "@mui/material";
import React, {ReactNode} from "react";
import {Picture} from "./data/Picture";
import AspectRatioBox, {RatioType} from "../../../abs/molecules/AspectRatioBox";
import {ReactComponent as CheckIcon} from "../../../../../icons/icon_check.svg";
import {primaryVariantColor} from "../../../../themes/Color";


const StyledCancelRounded = styled(CancelRounded)`
  background-color: #fff;
  border-radius: 36px;
  width: 1.2rem;
  height: 1.2rem;
`

const AbsoluteIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  color: #444;
`


const StyledImage = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 1px 1px 3px #999;

  // mobile chrome aspect ratio
  aspect-ratio: 1/1;
`


const ButtonWrapper = styled(Button)`
  position: relative;
  padding: 2px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
`

const Root = styled(Stack)`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0.8rem 0.8rem 1px 1px;
`


export type ImagePickerItemProps = {
    picture: Picture,
    onClick: (profileContent: Picture) => void,
    onDeleteClick: (picture: Picture) => void,
    disableDelete?: boolean
}

const ImagePickerItem = (
    {picture, onClick, onDeleteClick, disableDelete}: ImagePickerItemProps
) => {

    return (
        <Root>
            <AspectRatioBox ratio={RatioType["1/1"]}>
                <ButtonWrapper onClick={() => onClick(picture)}>
                    <StyledImage src={picture.url} alt="picture"/>
                </ButtonWrapper>
            </AspectRatioBox>

            {!disableDelete &&
            <AbsoluteIconButton
                size={"small"}
                onClick={() => onDeleteClick(picture)}
                children={<StyledCancelRounded/>}
            />
            }
        </Root>
    )
}


export const ImageAddItem = (
    {contentItem, onClick}: {
        contentItem: ReactNode
        onClick: () => void
    }
) => {
    return <>
        <Root>
            <AspectRatioBox ratio={RatioType["1/1"]}>
                <ButtonWrapper onClick={onClick}>
                    <Stack
                        justifyContent={"center"}
                        alignItems={"center"}
                        width={"100%"}
                        height={"100%"}
                        borderRadius={"8px"}
                        boxShadow={"1px 1px 3px #999"}
                    >
                        {contentItem}
                    </Stack>
                </ButtonWrapper>
            </AspectRatioBox>
        </Root>
    </>
}

//

const PictureCurtain = () => {
    return (
        <Stack
            position={"absolute"}
            width={"98%"}
            height={"98%"}
            borderRadius={"8px"}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Stack
                position={"absolute"}
                width={"100%"}
                height={"100%"}
                bgcolor={"#00000066"}
            />

            <Stack
                width={"40px"}
                height={"40px"}
                borderRadius={"36px"}
                justifyContent={"center"}
                alignItems={"center"}
                bgcolor={primaryVariantColor}
                zIndex={3}
                pr={"6px"}
                pb={"6px"}
            >
                <CheckIcon fill={"#fff"}/>
            </Stack>
        </Stack>
    )
}

export const ImagePickerItemCheckable = (
    {picture, onClick, onDeleteClick, disableDelete, isChecked}: {
        isChecked: boolean
    } & ImagePickerItemProps
) => {
    const handleClick = () => {
        onClick(picture)
    }

    return (
        <Root>
            <AspectRatioBox ratio={RatioType["1/1"]}>
                <ButtonWrapper onClick={handleClick}>
                    {isChecked && <PictureCurtain/>}
                    <StyledImage src={picture.url} alt="picture"/>
                </ButtonWrapper>
            </AspectRatioBox>

            {!disableDelete &&
            <AbsoluteIconButton
                size={"small"}
                onClick={() => onDeleteClick(picture)}
                children={<StyledCancelRounded/>}
            />
            }
        </Root>
    )
}

export default ImagePickerItem
