import {
  SwipeableList,
  SwipeableListItem,
} from "@sandstreamdev/react-swipeable-list";
import "@sandstreamdev/react-swipeable-list/dist/styles.css";
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import {
  errorColor,
  textColorDeepGray,
  textColorGray,
  textColorLightGray,
  textDisabledColor,
} from "../../../../../themes/Color";
import Spacer from "../../../../../components/abs/atoms/Spacer";
import { v4 as generateUniqueKey } from "uuid";
import React, { useEffect, useState } from "react";
import { VerticalScrollableStack } from "../../../../../components/abs/base/Scrollable";
import {
  computeDiffDay,
  getCurrentDateTime,
} from "../../../../../../utils/datetime/DateTime";
import { ReactionIReceivedEntity } from "../../../../../../domain/entities/reaction/ReactionIReceivedEntity";
import { useProfileDetailDialogSlice } from "../../../../../../redux/features/local/dialogs/profile/ProfileDetailDialogSlice";
import { SessionPropertyKey } from "../../../../../../session/SessionPropertyKey";
import { useUsersState } from "../../../../../../redux/features/remote/users/UsersSlice";
import { initialUserEntity } from "../../../../../../domain/entities/user/UserEntity";
import { deleteReaction } from "../../../../../../redux/features/remote/reaction/ReactionApi";
import { useReviewDetailDialogSlice } from "../../../../../../redux/features/local/dialogs/review/detail/ReviewDetailDialogSlice";
import { ReactionEntity } from "../../../../../../domain/entities/reaction/ReactionEntity";

// 아이템을 왼쪽으로 밀면 나오는 메뉴
export const RightSwipeHiddenComponent = () => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"flex-end"}
      alignItems={"center"}
      width={"100%"}
      height={"100%"}
      padding={"0.5rem"}
      sx={{
        backgroundColor: errorColor,
        opacity: 0.8,
      }}
    >
      <Typography
        variant={"body1"}
        color={"white"}
        children={"밀어서 삭제하기"}
      />
      <Spacer w={"1rem"} />
      <IconButton
        aria-label="delete"
        onClick={() => {}}
        sx={{ border: `1px solid white` }}
        children={<DeleteForever htmlColor={"white"} />}
      />
    </Stack>
  );
};

const ReactionIReceivedItem = ({
  onProfileClick,
  reactionIReceived,
}: {
  onProfileClick: (reaction: ReactionEntity) => void;
  reactionIReceived: ReactionIReceivedEntity;
}) => {
  const { senderId, targetReview, type } = reactionIReceived;
  const { data: userDB } = useUsersState();

  const sender = userDB.find((user) => user.uid === senderId);
  const fewDay = computeDiffDay(
    getCurrentDateTime(),
    new Date(reactionIReceived.regDate)
  );
  const formattedFewDay = fewDay === 0 ? "오늘" : `${fewDay}일 전`;

  const formattedReviewTitle =
    targetReview.title.length > 5
      ? targetReview.title.slice(0, 5) + "..."
      : targetReview.title;

  //

  if (!sender) {
    return <></>;
  }

  return (
    <>
      <Stack direction={"row"} width={"100%"}>
        <Button
          onClick={() => onProfileClick(reactionIReceived)}
          children={
            <Avatar src={sender.profileImages[0].url} alt="profile image" />
          }
        />
        <Spacer w={"0.5rem"} />

        <Stack textAlign={"start"} overflow={"hidden"}>
          <Typography
            variant={"body2"}
            color={textColorDeepGray}
            fontSize={"13px"}
            fontWeight={500}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            whiteSpace={"nowrap"}
            children={`${sender.nickname}님이 [${formattedReviewTitle}]에 공감했어요`}
          />
          <Typography
            variant={"caption"}
            color={textColorGray}
            fontSize={"12px"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            whiteSpace={"nowrap"}
            children={`"${type}"`}
          />
          <Spacer h={"0.2rem"} />

          <Typography
            variant={"caption"}
            color={textDisabledColor}
            fontSize={"11px"}
            fontWeight={500}
            children={formattedFewDay}
          />
        </Stack>
      </Stack>
    </>
  );
};

const useReactionIReceivedPage = () => {
  const { data: userDB } = useUsersState();

  const { mutate: mutateProfileDetailDialog } = useProfileDetailDialogSlice();
  const { data: ReviewDetailDialogData, mutate: mutateReviewDetailDialog } =
    useReviewDetailDialogSlice();

  

  const handleItemClick = (reactionIReceived: ReactionIReceivedEntity) => {
    // console.log("click")
  };

  const handleProfileClick = (reactionIReceived: ReactionIReceivedEntity) => {
    const sender = userDB.find(
      (user) => user.uid === reactionIReceived.senderId
    );
    if (sender) {
      mutateProfileDetailDialog({
        isOpen: true,
        user: sender,
      });
    } else {
      alert("잘못된 접근입니다.");
    }
  };

  return {
    handleItemClick,
    handleProfileClick,
  };
};

const ReactionIReceivedPage = ({
  reactionIReceivedList,
}: {
  reactionIReceivedList: Array<ReactionIReceivedEntity>;
}) => {
  const { handleItemClick, handleProfileClick } =
    useReactionIReceivedPage();

    const [reactions, setReactions] = useState<Array<ReactionIReceivedEntity>>(reactionIReceivedList);

    const handleSwipeLeft = (reactionIReceived: ReactionIReceivedEntity) => {
      if (window.confirm("이 반응을 삭제할까요?")) {
        deleteReaction(reactionIReceived.senderId, reactionIReceived.targetReview).then((response) => {
          setReactions(reactionIReceivedList.filter(it => it.id !== reactionIReceived.regDate+reactionIReceived.senderId))
        });
      }
    };

    // useEffect(()=>{
    //   const copiedData = reactionIReceivedList ? [...reactionIReceivedList] : [];
    //   const sortedData = copiedData.sort((a, b) => {
    //     return new Date(b.regDate).getTime() - new Date(a.regDate).getTime();
    //   });
    // }, [reactionIReceivedList])

    

  //

  const renderReactionIReceivedItems = reactions.sort((a, b) => {
    return new Date(b.regDate).getTime() - new Date(a.regDate).getTime();
  }).map((it) => {
    return (
      <Stack key={generateUniqueKey()}>
        <SwipeableListItem
          swipeLeft={{
            content: <RightSwipeHiddenComponent />,
            action: () => handleSwipeLeft(it),
          }}
        >
          <Stack width={"100%"} bgcolor={"#f9f9f9"}>
            <Button
              sx={{ padding: "0.7rem" }}
              onClick={() => handleItemClick(it)}
              children={
                <ReactionIReceivedItem
                  onProfileClick={handleProfileClick}
                  reactionIReceived={it}
                />
              }
            />
          </Stack>
        </SwipeableListItem>

        <Divider sx={{ mx: "1rem" }} />
      </Stack>
    );
  });

  return (
    <>
      <SwipeableList>
        <>
          <VerticalScrollableStack height={"100%"}>
            {reactionIReceivedList.length !== 0 ? (
              renderReactionIReceivedItems
            ) : (
              <>
          <Spacer h={"120px"}/>
          <Stack textAlign={"center"} alignItems={"center"}>
            <img src={"/assets/img/onboarding_reaction.svg"} alt={"onboarding_reaction"} width={"15%"}/>
            <Spacer h={"16px"}/>
            <Typography
                fontSize={"16px"}
                color={textColorDeepGray}
                children={"아직 공감이 없어요"}
                letterSpacing={"-5%"}
              />
              <Spacer h={"8px"}/>
              <Typography
                fontSize={"14px"}
                color={textColorLightGray}
                letterSpacing={"-5%"}>
                <span>다른 사람들이 보낸 공감이</span><br/>
                <span>천천히 쌓입니다</span>
              </Typography>
            </Stack>
            </>
            )}
          </VerticalScrollableStack>
        </>
      </SwipeableList>
    </>
  );
};

export default ReactionIReceivedPage;
