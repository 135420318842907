import { initialUserEntity } from "./../../../../domain/entities/user/UserEntity";
import { db, storage } from "./../../../../firebase-config";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { UserEntity } from "../../../../domain/entities/user/UserEntity";
import { ref, uploadString } from "firebase/storage";

export const fetchUser = async (userId: string): Promise<UserEntity> => {
  const docSnap = await getDoc(doc(db, "users", userId));
  // console.log("get from fetchUser");

  if (docSnap.exists()) {
    const response: UserEntity = JSON.parse(JSON.stringify(docSnap.data()));
    return response;
  } else {
    const response = initialUserEntity;
    return response;
  }
};

export const fetchUsersDB = async () => {
  const users: Array<UserEntity> = [];
  const querySnapshot = await getDocs(collection(db, "users"));
  // console.log("get from fetchUserDB");

  querySnapshot.forEach((doc) => {
    const userData: UserEntity = JSON.parse(JSON.stringify(doc.data()));
    users.push(userData);
  });

  return users;
};

export const SaveUserProfile = async (user: UserEntity) => {
  await updateDoc(doc(db, "users", user.uid), {
    nickname: user.nickname,
    selfIntroduction: user.selfIntroduction,
    interests: user.interests,
    profileImages: user.profileImages,
    address: user.address,
    isFaceAuth: user.isFaceAuth,
  });

  const response = true;
  return response;
};

export const PicturesUpdate = async (
  isChangePictures: boolean,
  user: UserEntity
) => {
  if (!isChangePictures) return user;

  const uid = user.uid;
  const profileImages = user.profileImages;
  const picturesUrl = profileImages.map((it) => {
    return it.url;
  });
  
  let i: number;
  for (i = 0; i < profileImages.length; i++) {
    if (picturesUrl[i].search("base64") !== -1) {
      const storageRef = ref(storage, "profiles/" + uid + profileImages[i].uuid); //파일명 : 유저id+uuid
      await uploadString(storageRef, picturesUrl[i], "data_url").then(
        (snapshot) => {
          // console.log("Uploaded a data_url string!");
        }
      );
    }
  }

  if (i >= profileImages.length) {
    const pictures = profileImages.map((it, index) => {
      return {
        ...it,
        url:
          `https://firebasestorage.googleapis.com/v0/b/qihano-e2ada.appspot.com/o/profiles%2F${uid+it.uuid}?alt=media&token=`,
      };
    });
    const response: UserEntity = {
      ...user,
      profileImages: pictures,
      isFaceAuth: false,
    };
    return response;
  }

  return false;
};


export const addToBlacklist = async (myInfo: UserEntity, blacklistId: string) => {
  let newBlacklist:string[] = [];
  if(myInfo.blacklist){
    newBlacklist = [...myInfo.blacklist, blacklistId]
  } else {
    newBlacklist.push(blacklistId)
  }
  await updateDoc(doc(db, "users", myInfo.uid), {
    blacklist: newBlacklist
  });

  return newBlacklist
}



// export const textToDefaultImage = async (base64: string) => {
//   const uids:string[] = ["CgPevNhYBgPAX3QwZNQ1Ses09Hi26f80085d-8096-4bb3-9298-7b8c428ea28e",
// "Ma5dtLohBRcrW1KFv5gvVzx6Bkc2685306e7-6b63-4a39-a8cb-ad4941359659",
// "QlpYbdVr98fw50vd2woYs76F5mm2287ce87e-1d1c-4fe5-ab37-b97ffa336df6",
// "6gVJB83N68hUFIFv4lbdE1ZKYqv27b414e4e-3f4e-4000-bdb7-63bb2e817ff9",
// "J6QRKeseXWcUHSoLco0a0BPWSZj248e44136-e708-4a0e-92da-0322d5a63550",
// "ShdJ06idtzTycHmSFG0f1EpVlcD28c32b6c6-2d78-4712-a16a-3c342632f819",
// "lFwTvOUA7ddQQrrCncsIFACGcO72436fe661-0dda-429d-bd6c-2995c2e1ac00"]

//   for(let i = 0; i<uids.length; i++){
//     const storageRef = ref(storage, "profiles/" + uids[i]); //파일명 : 유저id+uuid
//     await uploadString(storageRef, base64, "data_url").then(
//       (snapshot) => {
//         console.log("Uploaded a data_url string!", uids[i]);
//       }
//     );
//   }
// }