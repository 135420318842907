import {Stack} from "@mui/material";
import {VerticalScrollableStack} from "../../../../../../components/abs/base/Scrollable";
import React, {useState} from "react";
import {QuestionHeader} from "./sections/header/QuestionHeader";
import {QuestionBottom} from "./sections/bottom/QuestionBottom";
import {QuestionGuideSection} from "./sections/guide/QuestionGuideSection";
import Spacer from "../../../../../../components/abs/atoms/Spacer";
import {QuestionBodyItem} from "../../../../../../components/context/molecules/QuestionBodyItem";
// import {bookMock1} from "../../../../../../../domain/mocks/book/BookDB";
import {QuestionSelectModal} from "./QuestionSelectModal";
import {BookEntity} from "../../../../../../../domain/entities/book/BookEntity"

export const QuestionSection = (
    {onBack, onSubmit, defaultQuestion, targetBook}: {
        onBack: () => void,
        onSubmit: (question: string) => void,
        defaultQuestion: string,
        targetBook: BookEntity
    }
) => {
    const [content, setContent] = useState(defaultQuestion)
    const disableSubmitButton = (content.length < 10)

    const handleContentChange = (value: string) => {
        if (value.length >= 50) {
            setContent(value.slice(0, 50))
            return
        }

        setContent(value)
    }

    const [openQuestionSelect, setOpenQuestionSelect] = useState(false)

    const handleQuestionBottomOptionClick = () => {
        setOpenQuestionSelect(true)
    }

    const handleContentClick = (content: string) => {
        setContent(content)
        setOpenQuestionSelect(false)
    }

    const handleSubmit = () => {
        onSubmit(content)
    }

    //

    return <>
        <Stack height={"100%"}>
            <VerticalScrollableStack width={"100%"} height={"100%"}>
                <QuestionHeader
                    disableSubmitButton={disableSubmitButton}
                    onBack={onBack}
                    onSubmit={handleSubmit}
                />
                <Spacer h={"32px"}/>

                <QuestionGuideSection/>
                <Spacer h={"32px"}/>

                <QuestionBodyItem
                    book={targetBook}
                    content={content}
                    setContent={handleContentChange}
                />
            </VerticalScrollableStack>

            <QuestionBottom
                value={`${content.length}`}
                onOptionClick={handleQuestionBottomOptionClick}
            />
        </Stack>

        <QuestionSelectModal
            isOpen={openQuestionSelect}
            setIsOpen={setOpenQuestionSelect}
            onContentClick={handleContentClick}
        />

    </>
}
