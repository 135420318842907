import { initialUserEntity } from './../../../../../domain/entities/user/UserEntity';
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {UserEntity} from "../../../../../domain/entities/user/UserEntity";
import { RootState } from "../../../../store/QihanoStore";

/**
 * 내 정보
 * */
const initialState = initialUserEntity

const myInfoSlice = createSlice({
    name: 'myInfo', // action
    initialState: initialState, // default value
    reducers: {
        pullMyInfo: (state, action: PayloadAction<UserEntity>) =>
            action.payload,
    }
})

const {pullMyInfo} = myInfoSlice.actions

export const useMyInfo = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.myInfo, shallowEqual)

    const mutate = (state: UserEntity) => {
        dispatch(pullMyInfo(state))
    }


    return {
        mutate,
        data
    }
}

export default myInfoSlice.reducer