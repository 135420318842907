import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReactionISendEntity} from "../../../../domain/entities/reaction/ReactionISendEntity";

/**
 * 보낸 리액션
 * */
const initialState = null as Array<ReactionISendEntity> | null

const reactionISendSlice = createSlice({
    name: 'reactionISend', // action
    initialState: initialState, // default value
    reducers: {
        pullReactionToList: (state, action: PayloadAction<Array<ReactionISendEntity>>) =>
            action.payload,
    }
})

export const {pullReactionToList} = reactionISendSlice.actions
export default reactionISendSlice.reducer
