import {Stack} from "@mui/material";
import Spacer from "../../abs/atoms/Spacer";
import React, {ReactNode} from "react";

const BookWithInfoLayout = (
    {bookItem, infoItem}: {
        bookItem: ReactNode,
        infoItem: ReactNode
    }
) => {

    return <>
        <Stack direction={"row"} width={"100%"}>
            {bookItem}
            <Spacer w={"1rem"}/>

            {infoItem}
        </Stack>
    </>
}

export default BookWithInfoLayout
