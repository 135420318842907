import { SlideUpTransition } from "../../../../animations/Transitions";
import QihanoDialog from "../../../../components/context/base/QihanoDialog";
import React, { ChangeEvent, useState } from "react";
import {
  AppBar,
  IconButton,
  Snackbar,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { VerticalScrollableStack } from "../../../../components/abs/base/Scrollable";
import { QuestionBodyItem } from "../../../../components/context/molecules/QuestionBodyItem";
import { AnswerEditInputCard } from "./AnswerEditInputCard";
import { ReviewEntity } from "../../../../../domain/entities/review/ReviewEntity";
import { useAnswerEditDialogSlice } from "../../../../../redux/features/local/dialogs/answer/edit/AnswerEditDialogSlice";
import { sendAnswer } from "../../../../../redux/features/remote/answer/AnswerApi";
import { SessionPropertyKey } from "../../../../../session/SessionPropertyKey";
import { useUsersState } from "../../../../../redux/features/remote/users/UsersSlice";
import { useMyInfo } from "../../../../../redux/features/remote/users/myInfo/MyInfoSlice";
import { deleteReaction } from "../../../../../redux/features/remote/reaction/ReactionApi";

const MAX_LENGTH = 80;

const Header = ({ onClose }: { onClose: () => void }) => {
  return (
    <>
      <AppBar position={"relative"} color={"transparent"} elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            onClick={onClose}
            children={<CloseRoundedIcon htmlColor={"black"} />}
          />
        </Toolbar>
      </AppBar>
    </>
  );
};

export type AnswerEditDialogProps = {
  isOpen: boolean;
  review: ReviewEntity;
};

export const AnswerEditDialog = () => {
  const myId = sessionStorage.getItem(SessionPropertyKey.USER_ID) ?? "-1";

  const { close, mutate, data } = useAnswerEditDialogSlice();
  const { isOpen, review } = data;

  const { data: userDB } = useUsersState();
  const { data: myInfo } = useMyInfo();
  const writer = userDB.find((user) => user.uid === review.writerId);

  const book = review.targetBook;

  const [openSb, setOpenSb] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");

  const [content, setContent] = useState("");
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  if (!writer) {
    return <></>;
  }

  const handleContentChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === "" || value.length < 10) {
      setDisableSubmitButton(true);
    } else {
      setDisableSubmitButton(false);
    }

    if (value.length > MAX_LENGTH) {
      setContent(value.slice(0, MAX_LENGTH));
      return;
    }

    setContent(e.target.value);
  };

  const handleClose = () => {
    mutate({
      ...data,
      isOpen: false,
    });
  };

  const handleSubmit = () => {
    if(window.confirm(
        `${writer.nickname}님의 질문에 답변하고 대화를 요청할까요?`
      )){
        setDisableSubmitButton(true);
          sendAnswer(myInfo, writer.uid, review, content).then(response => {
            if(response){
                deleteReaction(myInfo.uid, review);
                setContent("");
                setSnackBarMsg(`${writer.nickname}님에게 답변을 보냈습니다`);
                setOpenSb(true);
                close();
            }
            else {
                setSnackBarMsg("이미 대화를 요청한 상태입니다.");
                setOpenSb(true);
                setContent("");
                close();
            }
          })
      }
  };

  //

  return (
    <>
      <QihanoDialog
        fullScreen={true}
        open={isOpen}
        TransitionComponent={SlideUpTransition}
      >
        <VerticalScrollableStack height={"100%"} bgcolor={"#f2f2f2"}>
          <Header onClose={handleClose} />
          <QuestionBodyItem
            book={book}
            content={review.question}
            setContent={() => {}}
            readonly
          />

          <Stack
            position={"absolute"}
            bottom={0}
            height={"60vh"}
            width={"100%"}
            zIndex={9}
          >
            <AnswerEditInputCard
              disableSubmitButton={disableSubmitButton}
              content={content}
              onChangeContent={handleContentChange}
              onSubmit={handleSubmit}
              maxLength={MAX_LENGTH}
              placeholder={`${writer.nickname}님의 이 질문에 어떻게 답변하시겠어요?`}
            />
          </Stack>
        </VerticalScrollableStack>
      </QihanoDialog>

      <Snackbar
        open={openSb}
        autoHideDuration={3000}
        onClose={() => setOpenSb(false)}
        message={
          <Typography
            variant={"body2"}
            fontWeight={"bold"}
            children={snackBarMsg}
          />
        }
        sx={{ position: "absolute" }}
      />
    </>
  );
};
