import { RouteProps } from "react-router";
import { Redirect, Route } from "react-router-dom";
import { useEffect } from "react";
import { SessionPropertyKey } from "../session/SessionPropertyKey";
import RouterPath from "./RouterPath";

// restricted : 로그인한 상태에선 접근 불가능한 페이지라면 true
export type PublicRouteProps = {
  restricted?: boolean;
} & RouteProps;

const PublicRoute = ({ component: Component, restricted, ...rest }: PublicRouteProps) => {
  const isValidSession = sessionStorage.getItem(SessionPropertyKey.USER_ID);

  const isRestricted = !!isValidSession && restricted;
  
  if (!Component) return null;
  return (
    <>
      <Route
        {...rest} //path, component
        render={(
          props //props는 restricted를 포함한 모든 props를 가리킴.
        ) =>
        isRestricted
        ? <Redirect to={RouterPath.MAIN}/>
        : <Component {...props} />
        }
      />
    </>
  );
};

export default PublicRoute;
