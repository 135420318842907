import { useBookDetailDialogSlice } from "../../../../../redux/features/local/dialogs/book/detail/BookDetailDialogSlice";
import { VerticalScrollableStack } from "../../../../components/abs/base/Scrollable";
import {
  Card,
  Drawer,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { withStyles } from "@material-ui/styles";
import { MAX_WIDTH } from "../../../../themes/CustomThemes";
import Spacer from "../../../../components/abs/atoms/Spacer";
import { CloseRounded } from "@mui/icons-material";
import styled from "@emotion/styled";
import {
  BookDetailActionType,
  RenderCaseOfButtonActions,
} from "./ButtonActionsCase";
import React, { useEffect, useState } from "react";
import { BookDetailInfoSection } from "./sections/BookDetailInfoSection";
import { BookDetailImageSection } from "./sections/BookDetailImageSection";
import {
  AddBookmark,
  DeleteBookmark,
} from "../../../../../redux/features/remote/bookmark/BookmarkApi";
import { useReviewEditDialogSlice } from "../../../../../redux/features/local/dialogs/review/edit/ReviewEditDialogSlice";
import { initialReviewEntity } from "../../../../../domain/entities/review/ReviewEntity";
import { initialUserEntity } from "../../../../../domain/entities/user/UserEntity";
import { useMyInfo } from "../../../../../redux/features/remote/users/myInfo/MyInfoSlice";
import { useBooksState } from "../../../../../redux/features/remote/books/BooksSlice";
import { useReviewsState } from "../../../../../redux/features/remote/reviews/slice/ReviewsSlice";

const RootDrawer = withStyles({
  root: {
    zIndex: 9999,

    "&>.MuiPaper-root": {
      maxWidth: MAX_WIDTH,
      margin: "0 auto",
      height: "100%",
      maxHeight: "100%",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
})(Drawer);

const RootCard = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 60%;
  overflow: visible;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const BookDetailDialog = () => {
  const { close, mutate, data } = useBookDetailDialogSlice();
  const { isOpen, book, type, uid } = data;

  const targetBook = book;

  const { data: myInfo, mutate: mutateMyInfo } = useMyInfo();
  const { data: books, mutate: mutateBooks } = useBooksState();
  const { data: reviewDB } = useReviewsState()

  const { mutate: mutateReviewEditDialog } = useReviewEditDialogSlice();

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");

  const handleDelete = () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      DeleteBookmark(targetBook, myInfo)
        .then((response) => {
          if (response) {
            const newMyInfoState = { ...myInfo };
            newMyInfoState.bookmarks = response.bookmarks;
            mutateMyInfo(newMyInfoState);
            setSnackBarMsg("삭제되었습니다.");
            setOpenSnackBar(true);
            close();
          }
        })
        .catch((e) => alert(e.message));
    } else return;
  };

  const handlePositiveAction = (action: BookDetailActionType) => {
    if (
      action === BookDetailActionType.WISH_BOOK ||
      action === BookDetailActionType.FINISH_BOOK
    ) {
      //읽고 싶어요, 다 읽었어요
      AddBookmark(myInfo, books, targetBook, action).then((response) => {
        if (response) {
          const newMyInfoState = { ...myInfo };
          newMyInfoState.bookmarks = response.newMyInfo.bookmarks;
          mutateMyInfo(newMyInfoState);
          mutateBooks(response.newBookDB);
          setSnackBarMsg("등록되었습니다.");
          setOpenSnackBar(true);
          close();
          return;
        }
      });
    }

    if (action === BookDetailActionType.WRITE_REVIEW || action === BookDetailActionType.UPDATE_REVIEW) {
      const myReviews = reviewDB.filter(review => review.writerId === myInfo.uid);
      //생각 적기, 생각 다듬기
      mutateReviewEditDialog({
        isOpen: true,
        oldReview: myReviews.find(review => review.targetBook.bookId === targetBook.bookId) ?? { ...initialReviewEntity, targetBook: targetBook },
      });
      close();
      return;
    }
  };

  const closeDialog = () => {
    close();
  };

  if (!targetBook) {
    return <></>;
  }

  //

  return (
    <>
      <RootDrawer anchor={"bottom"} open={isOpen} onClose={closeDialog}>
        <Stack
          height={"100%"}
          alignItems="flex-end"
          justifyContent={"flex-end"}
        >
          <Stack flex={1} aria-label={"백드롭 영역"} onClick={closeDialog} />
          <RootCard>
            <BookDetailImageSection
              bookThumbnailUrl={targetBook.thumbnailUrl}
            />
            <Spacer h={"0.8rem"} />

            <VerticalScrollableStack
              height={"100%"}
              px={"1rem"}
              pb={"1rem"}
              justifyContent={"space-between"}
            >
              <BookDetailInfoSection book={targetBook} />
              {/* <Spacer h={"20px"}/> */}

              <RenderCaseOfButtonActions
                renderType={type}
                onDelete={handleDelete}
                onPositive={handlePositiveAction}
              />
            </VerticalScrollableStack>

            <Stack position={"absolute"} top={0} right={0}>
              <IconButton
                size={"large"}
                children={<CloseRounded />}
                onClick={closeDialog}
              />
            </Stack>
          </RootCard>
        </Stack>
      </RootDrawer>

      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
        message={
          <Typography
            variant={"body2"}
            fontWeight={"bold"}
            children={snackBarMsg}
          />
        }
        sx={{ position: "absolute" }}
      />
    </>
  );
};

export default BookDetailDialog;
