import {getCurrentDateTime} from "../../utils/datetime/DateTime";

export const validateBirthDate = async (formattedBirthDay: string) => {
    const [year, month, day] = formattedBirthDay.split(". ")
    const now = getCurrentDateTime()

    if (parseInt(year) >= now.getFullYear()) {
        throw Error("출생연도는 현재 연도를 넘어설 수 없습니다.")
    }

    if (parseInt(year) < 1900) {
        throw Error("출생연도가 비정상적입니다.")
    }

    if (parseInt(month) > 12) {
        throw Error("출생 월은 12를 넘을 수 없습니다.")
    }

    if (parseInt(month) < 1) {
        throw Error("출생 월은 1보다 작을 수 없습니다.")
    }

    if (parseInt(day) > 31) {
        throw Error("출생 일은 31을 넘을 수 없습니다.")
    }

    if (parseInt(day) < 1) {
        throw Error("출생 일은 1보다 작을 수 없습니다.")
    }
}