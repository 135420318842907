import {Button, Stack, Typography} from "@mui/material";
import TripleImagePicker from "../../../../../components/context/organisms/image_picker/TripleImagePicker";
import Spacer from "../../../../../components/abs/atoms/Spacer";
import {textColorGray} from "../../../../../themes/Color";
import React from "react";
import {ImageFilesState} from "../../../../../components/context/organisms/image_picker/ImagePickerHook";

export const ProfileImageSection = (
    {imageFiles, setImageFiles, faceAuthMsg, disableFaceAuthButton, onAuthClick}: {
        faceAuthMsg: string,
        disableFaceAuthButton: boolean,
        onAuthClick: () => void
    } & ImageFilesState
) => {
    return <>
        <Stack>
            <Stack paddingLeft={"1rem"} paddingRight={"0.2rem"}>
                <TripleImagePicker
                    imageFiles={imageFiles}
                    setImageFiles={setImageFiles}
                />
            </Stack>
            <Spacer h={"1rem"}/>

            <Stack direction={"row"} justifyContent={"space-between"} px={"1rem"} alignItems="center">
                <Typography
                    variant={"caption"}
                    color={textColorGray}
                    children={"얼굴을 인증하고, 다른 회원의 프로필 사진도 확인해보세요."}
                />
                <Spacer w={"2rem"}/>

                <Button
                    variant={"outlined"}
                    children={faceAuthMsg}
                    disabled={disableFaceAuthButton}
                    sx={{borderRadius: "36px", minWidth: "fit-content"}}
                    onClick={onAuthClick}
                />
            </Stack>
        </Stack>
    </>
}
