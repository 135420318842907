// export const primaryColor = "#A62447";
export const primaryColor = "#222";
export const primaryVariantColor = "#4137d1";
export const primaryVariantWeakColor = "#a19efa";

export const linkColor = "#32ade6"
export const errorColor = "#b00020"

export const disabledColor = "#ddd"

export const textColor1 = "#333"

export const textColorLightBlack = "#222"
export const textColorDeepGray = "#444"
export const textColorGray = "#666"
export const textColorLightGray = "#888"
export const textDisabledColor = "#999"

export const textColorHighLight = "#5195b2"