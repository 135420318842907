import { BookmarkTypes } from './../../../../domain/types/BookmarkTypes';
import { db } from "./../../../../firebase-config";
import { collection } from "firebase/firestore";
import { getDocs } from "firebase/firestore";
import { initialBookEntity } from "./../../../../domain/entities/book/BookEntity";
import { BookEntity } from "../../../../domain/entities/book/BookEntity";
import { bookSearch } from "../account/KakaoApi";
import { BookDetailActionType } from "../../../../ui/dialogs/childs/book/detail/ButtonActionsCase";
import { ReviewEntity } from "../../../../domain/entities/review/ReviewEntity";
import { UserEntity } from "../../../../domain/entities/user/UserEntity";

export const searchKakaoBook = async (
  bookTitle: string
): Promise<Array<BookEntity>> => {
  let BooksArray: Array<BookEntity> = [];

  const params = {
    query: bookTitle,
    sort: "accuracy", // accuracy | recency 정확도 or 최신
    page: 1, // 페이지번호
    size: 10, // 한 페이지에 보여 질 문서의 개수
  };

  const { data } = await bookSearch(params); // api 호출

  for (var i = 0; i < data.documents.length; i++) {
    BooksArray.push({
      bookId: data.documents[i].isbn,
      title: data.documents[i].title,
      authors: data.documents[i].authors,
      translators: data.documents[i].translators,
      publisher: data.documents[i].publisher,
      description: data.documents[i].contents,
      thumbnailUrl: data.documents[i].thumbnail,
      directUrl: data.documents[i].url
    });
  }

  // console.log(BooksArray)

  //   return booksDB.filter((it) => it.title.includes(bookTitle));
  return BooksArray.filter((it) => it.bookId !== "")
    .filter((it) => it.title !== "")
    .filter((it) => it.publisher !== "")
    .filter((it) => it.thumbnailUrl !== "");
};


export const fetchBooksDB = async () => {
  const books: Array<BookEntity> = [];
  const querySnapshot = await getDocs(collection(db, "books"));
  // console.log("get from fetchBooksDB")

  querySnapshot.forEach((doc) => {
    let bookData: BookEntity = initialBookEntity;
    bookData = JSON.parse(JSON.stringify(doc.data()));
    books.push(bookData);
  });

  return books
}


export const setBookDetailType = (user: UserEntity, book: BookEntity, reviews: Array<ReviewEntity>) => {
  let type: number = 0;

  if(reviews.some((review) => review.id === user.uid+book.bookId)){ //리뷰가 있는 책 클릭 시
    type = BookDetailActionType.UPDATE_REVIEW
  }
  else { //리뷰가 없는 책 클릭 시
    if (!user.bookmarks.some(bookmark => bookmark.book.bookId === book.bookId)){ //읽지도 않은 책 클릭 시
      type = BookDetailActionType.WISH_BOOK
    }
    if (user.bookmarks.some(bookmark => bookmark.book.bookId === book.bookId && bookmark.type === BookmarkTypes.WISH)){ //읽고 싶은 책 클릭 시
      type = BookDetailActionType.FINISH_BOOK
    }
    if (user.bookmarks.some(bookmark => bookmark.book.bookId === book.bookId && bookmark.type === BookmarkTypes.FINISHED)){
      type = BookDetailActionType.WRITE_REVIEW
    }
  }

  // if (!user.bookmarks.some(bookmark => bookmark.book.bookId === book.bookId)){ //아직 안 읽은 책 클릭 시
  //   type = BookDetailActionType.WISH_BOOK
  // }

  // if (user.bookmarks.some(bookmark => bookmark.book.bookId === book.bookId && bookmark.type === BookmarkTypes.WISH)){ //읽고 싶은 책 클릭 시
  //   type = BookDetailActionType.FINISH_BOOK
  // }
  
  // if (user.bookmarks.some(bookmark => bookmark.book.bookId === book.bookId && bookmark.type === BookmarkTypes.FINISHED)){
  //   if(reviews.some((review) => review.id === user.uid+book.bookId)){ //리뷰가 있는 읽은 책 클릭 시
  //     type = BookDetailActionType.UPDATE_REVIEW
  //   }
  //   else { //리뷰가 없는 읽은 책 클릭 시
  //     type = BookDetailActionType.WRITE_REVIEW
  //   }
  // }

  return type
}