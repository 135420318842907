import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../store/QihanoStore";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BooksThisMonthDialogProps} from "../../../../../../ui/dialogs/childs/book/this_month/BooksThisMonthDialog";


const initialState: BooksThisMonthDialogProps = {
    isOpen: false,
}

const BooksThisMonthDialogSlice = createSlice({
    name: 'booksThisMonthDialog',
    initialState: initialState,
    reducers: {
        setBooksThisMonthDialogState: (state, action: PayloadAction<BooksThisMonthDialogProps>) =>
            action.payload,
    }
})

export const useBooksThisMonthDialogSlice = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.booksThisMonthDialog)

    const mutate = (state: BooksThisMonthDialogProps) => {
        dispatch(setBooksThisMonthDialogState({
            isOpen: state.isOpen,
        }))
    }

    const close = () => {
        mutate({
            ...data,
            isOpen: false
        })
    }

    const open = () => {
        mutate({
            isOpen: true
        })
    }


    return {
        close,
        open,
        mutate,
        data
    }
}

export const {setBooksThisMonthDialogState} = BooksThisMonthDialogSlice.actions
export default BooksThisMonthDialogSlice.reducer
