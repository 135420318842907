import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../store/QihanoStore";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReviewDetailDialogProps} from "../../../../../../ui/dialogs/childs/review/detail/ReviewDetailDialog";
import {initialReviewEntity} from "../../../../../../domain/entities/review/ReviewEntity";


export const initialReviewDetailDialogState: ReviewDetailDialogProps = {
    review: initialReviewEntity,
    isMyReview: false,
    isOpen: false
}

const ReviewDetailDialogSlice = createSlice({
    name: 'ReviewDetailDialog',
    initialState: initialReviewDetailDialogState,
    reducers: {
        setReviewDetailDialogState: (state, action: PayloadAction<ReviewDetailDialogProps>) =>
            action.payload,
    }
})

export const useReviewDetailDialogSlice = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.reviewDetailDialog)

    const mutate = (state: ReviewDetailDialogProps) => {
        dispatch(setReviewDetailDialogState(state))
    }

    const close = () => {
        mutate({
            ...data,
            isOpen: false
        })
    }

    return {
        close,
        mutate,
        data
    }
}

export const {setReviewDetailDialogState} = ReviewDetailDialogSlice.actions
export default ReviewDetailDialogSlice.reducer
