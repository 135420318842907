import { Avatar, Stack } from "@mui/material";
import Spacer from "../../../../../components/abs/atoms/Spacer";
import React from "react";
import { UserEntity } from "../../../../../../domain/entities/user/UserEntity";
import styled from "@emotion/styled";
import { FullSizeImage } from "../../../../../components/abs/base/FullSizeImage";

const StyledAvatar = styled(Avatar)`
  width: 88px;
  height: 88px;
  border: 4px solid #fff;
`;

//

export type ThinkronizedAvatarsSectionProps = {
  me: UserEntity;
  partner: UserEntity;
};

export const ThinkronizedAvatarsSection = ({
  me,
  partner,
}: ThinkronizedAvatarsSectionProps) => {
  return (
    <>
      <Stack direction="row" justifyContent="center" alignItems="center" position={"relative"}>
        <img src={`/assets/img/particle_left.svg`} alt={"particle_left"} />
        <Stack
          alignItems={"center"}
          pr={"40px"}
          ml={"1rem"}
          mr={"1rem"}
        >
          <StyledAvatar alt={"me"} src={me.profileImages[0].url} />

          <StyledAvatar
            alt={"partner"}
            src={partner.profileImages[0].url}
            sx={{
              position: "absolute",
              marginLeft: "80px",
              marginTop: "40px",
            }}
          />
          <Spacer h={"40px"} />
        </Stack>
        <img src={`/assets/img/particle_right.svg`} alt={"particle_right"} />
      </Stack>
    </>
  );
};
