import {BrowserRouter, Switch} from "react-router-dom";
import PublicRoute from "./PublicRoute";
import {SplashPage} from "../ui/pages/splash";
import RouterPath, {AuthPath, SignupPath} from "./RouterPath";
import SocialLoginPage from "../ui/pages/auth/social";
import LoginPage from "../ui/pages/auth/login";
// import EmailInputPage from "../ui/pages/account/signup/email";
// import EmailAuthPage from "../ui/pages/account/signup/email-auth";
// import PasswordInputPage from "../ui/pages/account/signup/password";
// import PhoneNumberInputPage from "../ui/pages/account/signup/phone";
// import PhoneAuthPage from "../ui/pages/account/signup/phone/auth";
import UsingTermPage from "../ui/pages/account/signup/terms";
// import NoticeEmailAuthPage from "../ui/pages/account/signup/notice-email-auth";
import BirthDayPage from "../ui/pages/account/signup/birth";
import NamePage from "../ui/pages/account/signup/name";
import GenderPage from "../ui/pages/account/signup/gender";
import GPSPage from "../ui/pages/account/signup/gps";
import PicturePage from "../ui/pages/account/signup/pictures";
import InterestPage from "../ui/pages/account/signup/interest";
import RepresentBook from "../ui/pages/account/signup/represent-book";
import WelcomePage from "../ui/pages/account/signup/welcome";
import PrivateRoute from "./PrivateRoute";
import Kakao from "../redux/features/remote/account/KakaoApi";
import LoadingPage from "../ui/pages/account/signup/loading";
import GeneralPath from "./RouterPath";
import AdminFaceAuthPage from "../ui/pages/admin/faceAuth";

//exact: 주어진 경로와 정확히 맞아 떨어져야만 설정한 컴포넌트를 보여줌.
const DynamicRoutes = () =>
<BrowserRouter>
<Switch>
    <PublicRoute exact path={"/"} component={SplashPage}/>

    <PublicRoute restricted={false} exact path={RouterPath.SPLASH} component={SplashPage}/>
    <PublicRoute restricted={false} exact path={AuthPath.SOCIAL} component={SocialLoginPage}/>
    <PublicRoute restricted={true} exact path={"/oauth/callback/kakao"} component={Kakao}/>
    <PublicRoute exact path={AuthPath.LOGIN} component={LoginPage}/>
    <PublicRoute restricted={true} exact path={SignupPath.LOADING} component={LoadingPage}/>
    {/* <PublicRoute exact path={SignupPath.EMAIL} component={EmailInputPage}/> */}
    {/* <PublicRoute exact path={SignupPath.AUTH_EMAIL} component={EmailAuthPage}/> */}
    {/* <PublicRoute exact path={SignupPath.SET_PASSWORD} component={PasswordInputPage}/> */}
    {/* <PublicRoute exact path={SignupPath.PHONE} component={PhoneNumberInputPage}/> */}
    {/* <PublicRoute exact path={SignupPath.AUTH_PHONE} component={PhoneAuthPage}/> */}
    <PublicRoute restricted={true} exact path={SignupPath.TERMS} component={UsingTermPage}/>
    {/* <PublicRoute exact path={SignupPath.NOTICE_EMAIL_AUTH} component={NoticeEmailAuthPage}/> */}
    <PublicRoute restricted={true} exact path={SignupPath.BIRTH} component={BirthDayPage}/>
    <PublicRoute restricted={true} exact path={SignupPath.NAME} component={NamePage}/>
    <PublicRoute restricted={true} exact path={SignupPath.GENDER} component={GenderPage}/>
    <PublicRoute restricted={true} exact path={SignupPath.ALLOW_GPS} component={GPSPage}/>
    <PublicRoute restricted={true} exact path={SignupPath.PICTURES} component={PicturePage}/>
    <PublicRoute restricted={true} exact path={SignupPath.INTEREST} component={InterestPage}/>
    <PublicRoute restricted={true} exact path={SignupPath.REPRESENT_BOOK} component={RepresentBook}/>
    <PublicRoute restricted={true} exact path={SignupPath.WELCOME} component={WelcomePage}/>

    <PublicRoute restricted={false} exact path={GeneralPath.ADMIN_FACEAUTH} component={AdminFaceAuthPage}/>

    <PrivateRoute path="/"/>
</Switch>
</BrowserRouter>

export default DynamicRoutes