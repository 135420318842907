import {SlideUpTransition} from "../../../../animations/Transitions";
import QihanoDialog from "../../../../components/context/base/QihanoDialog";
import {ReviewDetailTemplate} from "./sub/others/ReviewDetailTemplate";
import {BookEntity} from "../../../../../domain/entities/book/BookEntity";
import React, {useState} from "react";
import {SnackbarWithAction} from "../../../../components/abs/molecules/SnackbarWithAction";
import {useReactionsBottomSheetSlice} from "../../../../../redux/features/local/dialogs/reaction/reaction_bs/ReactionBottomSheetSlice";
import {ReactionTypes} from "../../../../../domain/types/ReactionTypes";
import {MyReviewDetailTemplate} from "./sub/my/MyReviewDetailTemplate";
import {useReviewDetailDialogSlice} from "../../../../../redux/features/local/dialogs/review/detail/ReviewDetailDialogSlice";
import {ReviewEntity} from "../../../../../domain/entities/review/ReviewEntity";
import {useReviewEditDialogSlice} from "../../../../../redux/features/local/dialogs/review/edit/ReviewEditDialogSlice";
import {useBookDetailDialogSlice} from "../../../../../redux/features/local/dialogs/book/detail/BookDetailDialogSlice";
import {BookDetailActionType} from "../../book/detail/ButtonActionsCase";
import {useAnswerEditDialogSlice} from "../../../../../redux/features/local/dialogs/answer/edit/AnswerEditDialogSlice";
import { deleteReview } from "../../../../../redux/features/remote/reviews/ReviewsApi";
import { useReviewsState } from "../../../../../redux/features/remote/reviews/slice/ReviewsSlice";
import { useMyInfo } from "../../../../../redux/features/remote/users/myInfo/MyInfoSlice";
import { setBookDetailType } from "../../../../../redux/features/remote/books/BookApi";
import { AddBookmark } from "../../../../../redux/features/remote/bookmark/BookmarkApi";
import { useBooksState } from "../../../../../redux/features/remote/books/BooksSlice";
import { BookmarkTypes } from "../../../../../domain/types/BookmarkTypes";
import { useUsersState } from "../../../../../redux/features/remote/users/UsersSlice";
import { deleteReaction } from "../../../../../redux/features/remote/reaction/ReactionApi";
import { ReactionEntity } from "../../../../../domain/entities/reaction/ReactionEntity";
import { Snackbar, Typography } from "@mui/material";


export type ReviewDetailDialogProps = {
    review: ReviewEntity,
    isMyReview: boolean,
    isOpen: boolean,
    isLiked?: boolean,
    reaction?: ReactionEntity,
    isMyOldReview?: boolean
}

export const ReviewDetailDialog = () => {
    const {
        close: closeReviewDetailDialog,
        mutate: mutateReviewDetailDialog,
        data: ReviewDetailDialogData
    } = useReviewDetailDialogSlice()

    const {
        close: closeReactionBs,
        mutate: mutateReactionBs,
    } = useReactionsBottomSheetSlice()

    const {mutate: mutateBookDetailDialog} = useBookDetailDialogSlice()
    const {mutate: mutateReviewEditDialog} = useReviewEditDialogSlice()
    const {mutate: mutateAnswerEditDialog} = useAnswerEditDialogSlice()

    const {data:reviewDB, mutate: mutateReviews} = useReviewsState()
    const {data:myInfo, mutate: mutateMyInfo} = useMyInfo()
    const {data:bookDB, mutate: mutateBooks} = useBooksState()

    const [openBookMarkSnackbar, setOpenBookMarkSnackbar] = useState(false)

    const {review, isMyReview, isOpen, isLiked, reaction, isMyOldReview} = ReviewDetailDialogData

    const {data:userDB} = useUsersState()
    const writer = userDB.find(user => user.uid === review.writerId)

    const handleEdit = () => {
        mutateReviewEditDialog({
            isOpen: true,
            oldReview: review
        })
        closeReviewDetailDialog();
    }

    const handleDelete = () => {
        if (window.confirm("정말 이 리뷰를 삭제할까요?")) {
            deleteReview(reviewDB, review.id).then(response => {
                if(response){
                    mutateReviews(response)
                    closeReviewDetailDialog()
                    alert("정상적으로 삭제되었습니다.")
                }
            })
        }
    }

    const handleBookClick = (book: BookEntity) => {
        const type = setBookDetailType(myInfo, book, reviewDB)
        mutateBookDetailDialog({isOpen: true, book: book, type: type, uid: myInfo.uid})
    }

    const handleBookMark = (book: BookEntity) => {
        AddBookmark(myInfo, bookDB, book, BookmarkTypes.WISH).then(response => {
            if(response) {
                const newMyInfoState = { ...myInfo };
                 newMyInfoState.bookmarks = response.newMyInfo.bookmarks;
                  mutateMyInfo(newMyInfoState);
                  mutateBooks(response.newBookDB);
                  setOpenBookMarkSnackbar(true)
            }
        })
        
    }

    const handleReaction = (reactionType: ReactionTypes) => {
        // console.log(reactionType)
        closeReactionBs()
    }

    const handleReactionFabClick = () => {
        if(isLiked && isLiked === true && writer && reaction){
            if(window.confirm(writer.nickname+"님에게 보낸 반응을 삭제할까요?")){
                deleteReaction(myInfo.uid, review).then(response => {
                    if(response){
                        // console.log(reaction.regDate, myInfo.uid)
                        mutateReviewDetailDialog({...ReviewDetailDialogData, isOpen:false, isLiked:false})
                    }
                });
            }
        }
        // mutateReactionBs({
        //     drawerProps: {
        //         open: true,
        //         onClose: closeReactionBs
        //     },
        //     onReaction: handleReaction
        // })
    }

    const handleAnswer = () => {
        mutateAnswerEditDialog({
            isOpen: true,
            review: review
        })
    }

    const handleClose = () => {
        closeReviewDetailDialog()
    }

    //

    const renderBody =
        isMyReview
            ? <MyReviewDetailTemplate
                review={review}
                isMyOldReview={isMyOldReview}
                onClose={handleClose}
                onEdit={handleEdit}
                onDelete={handleDelete}
            />
            : <>
                <ReviewDetailTemplate
                    review={review as ReviewEntity}
                    isLiked={isLiked}
                    onClose={handleClose}
                    onBookMark={handleBookClick}
                    onReaction={handleReactionFabClick}
                    onAnswer={handleAnswer}
                    onReadToBook={handleBookMark}/>
                <Snackbar
                    open={openBookMarkSnackbar}
                    autoHideDuration={3000}
                    onClose={() => setOpenBookMarkSnackbar(false)}
                    message={
                    <Typography
                    variant={"body2"}
                    fontWeight={"bold"}
                    children={"읽고 싶은 책으로 등록되었습니다."}
                  />}
                    sx={{position: "absolute"}}
                />
            </>

    return <>
        <QihanoDialog
            fullScreen={true}
            open={isOpen}
            TransitionComponent={SlideUpTransition}
        >
            {renderBody}
        </QihanoDialog>
    </>
}
