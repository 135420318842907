import {useDispatch, useSelector} from "react-redux";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReactionsBottomSheetProps} from "../../../../../../ui/dialogs/childs/review/detail/sub/others/sections/bottom/ReactionsBottomSheet";
import {RootState} from "../../../../../store/QihanoStore";

const initialState: ReactionsBottomSheetProps = {
    drawerProps: {
        open: false
    },
    onReaction: () => { //BS안에 onItemClick과 연동
    }
}

const ReactionsBottomSheetSlice = createSlice({
    name: 'reactionsBottomSheet',
    initialState: initialState,
    reducers: {
        setReactionsBottomSheetState: (state, action: PayloadAction<ReactionsBottomSheetProps>) =>
            action.payload,
    }
})

export const useReactionsBottomSheetSlice = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.reactionBS)

    const mutate = (state: ReactionsBottomSheetProps) => {
        dispatch(setReactionsBottomSheetState(state))
    }

    const close = () => {
        mutate({
            drawerProps: {
                open: false
            },
            onReaction: () => {
            }
        })
    }

    return {
        close,
        mutate,
        data
    }
}

export const {setReactionsBottomSheetState} = ReactionsBottomSheetSlice.actions
export default ReactionsBottomSheetSlice.reducer
