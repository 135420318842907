import React, {SyntheticEvent, useEffect, useState} from "react";
import {SlideUpTransition} from "../../../animations/Transitions";
import QihanoDialog from "../../../components/context/base/QihanoDialog";
import {SimpleDialogAppbar} from "../../../components/abs/molecules/SimpleDialogAppbar";
import {Stack, Tab, Tabs, Typography} from "@mui/material";
import {textColorDeepGray, textColorLightBlack} from "../../../themes/Color";
import Spacer from "../../../components/abs/atoms/Spacer";
import SwipeableViews from "react-swipeable-views";
import styled from "@emotion/styled";
import {MyNotificationSection} from "./sections/my_nofiti/MyNotificationSection";
import {NoticeSection} from "./sections/notice/NoticeSection";
import {useNoticeDialogSlice} from "../../../../redux/features/local/dialogs/notice/NoticeDialogSlice";
import { NoticeEntity } from "../../../../domain/entities/notice/NoticeEntity";
import { useReviewsState } from "../../../../redux/features/remote/reviews/slice/ReviewsSlice";
import { onValue, ref, remove } from "firebase/database";
import { database } from "../../../../firebase-config";
import { SessionPropertyKey } from "../../../../session/SessionPropertyKey";
import { NotificationEntity } from "../../../../domain/entities/notice/NotificationEntity";
import { useMainBottomNavState } from "../../../../redux/features/local/main/bottom_nav/MainBottomNavSlice";
import { MainBottomNavTypes } from "../../../../domain/types/MainBottomNavTypes";
import { useGreetingDialogSlice } from "../../../../redux/features/local/dialogs/answer/greeting/GreetingDialogSlice";

const StyledSwipeableViews = styled(SwipeableViews)`
  height: 100%;

  & .react-swipeable-view-container {
    height: 100%;
  }
`

//

export type NoticeDialogProps = {
    isOpen: boolean
}

export const NoticeDialog = () => {
    const myId = sessionStorage.getItem(SessionPropertyKey.USER_ID) ?? "-1"

    const {close, data} = useNoticeDialogSlice()
    const {isOpen} = data
    const {data:bottomNavState, mutate: mutateBottomNavState} = useMainBottomNavState()

    const [tabIndex, setTabIndex] = useState(0)
    const [notificationList, setNotificationList] = useState<Array<NotificationEntity>>([])
    const [noticeList, setNoticeList] = useState<Array<NoticeEntity>>([])

    const {data:reviewDB} = useReviewsState()

    useEffect(()=>{

    }, [])

    useEffect(()=>{
        if(isOpen){
            onValue(ref(database, myId+"/notifications"), (snapshot) => { //알림
                // console.log("notification onValue")
                const data = snapshot.val();
                if(data){
                    setNotificationList(Object.values(data))
                }
            })

            const notices = reviewDB.filter(it => it.id.includes("notice")) //공지
            for(let i=0; i<notices.length; i++){
                const noticeData:NoticeEntity = {
                    id: notices[i].id,
                    title: notices[i].title,
                    content: notices[i].content,
                    createDate: notices[i].createDate,
                    writerId: notices[i].writerId
                }
                setNoticeList([...noticeList, noticeData])
            }            
        }
    }, [isOpen])

    const handleTabsChange = (e: SyntheticEvent, newValue: any) => {
        setTabIndex(newValue)
    }

    const handleSwiperChange = (index: number) => {
        setTabIndex(index)
    }

    const handleClose = () => {
        setTabIndex(0)
        setNoticeList([])
        close()
    }

    const handleNotificationClick = (notification: NotificationEntity) => {
        remove(ref(database, myId + "/notifications/" + notification.id));
        close()
        if(notification.type === "reaction"){
            mutateBottomNavState(MainBottomNavTypes.REACTION)
            return
        }
        if(notification.type === "answer" || notification.type === "chat"){
            mutateBottomNavState(MainBottomNavTypes.MESSAGE)
            return
        }
        if(notification.type === "faceAuth"){
            mutateBottomNavState(MainBottomNavTypes.MY_PAGE)
        }
    }

    //

    return <>
        <QihanoDialog
            fullScreen={true}
            open={isOpen}
            TransitionComponent={SlideUpTransition}
        >
            <Stack height={"100%"} color={textColorLightBlack}>
                <SimpleDialogAppbar onClose={handleClose}/>
                <Stack px={"1rem"}>
                    <Typography
                        variant={"h5"}
                        color={textColorDeepGray}
                        children={"알림"}
                    />
                </Stack>
                <Spacer h={"0.8rem"}/>

                <Tabs
                    value={tabIndex}
                    onChange={handleTabsChange}
                    indicatorColor="primary"
                    sx={{borderBottom: "1px solid #eee", px: "1rem"}}
                >
                    <Tab label="나의 소식"/>
                    <Tab label="공지사항"/>
                </Tabs>

                <StyledSwipeableViews
                    index={tabIndex}
                    onChangeIndex={handleSwiperChange}
                >
                    <MyNotificationSection notificationList={notificationList} onItemClick={handleNotificationClick}/>
                    <NoticeSection noticeList={noticeList}/>
                </StyledSwipeableViews>

            </Stack>
        </QihanoDialog>
    </>
}
