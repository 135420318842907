// noinspection HtmlUnknownTarget

import React, { useState } from "react";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import {
  textColorDeepGray,
  textColorGray,
  textColorLightBlack,
} from "../../../themes/Color";
import Spacer from "../../../components/abs/atoms/Spacer";
import AspectRatioBox, {
  RatioType,
} from "../../../components/abs/molecules/AspectRatioBox";
import { FullSizeImage } from "../../../components/abs/base/FullSizeImage";
import { useHistory } from "react-router";
import RouterPath from "../../../../router/RouterPath";
import { useSignupState } from "../../../../redux/features/local/signup/SignupSlice";
import { initialSignupFormData } from "../../../../domain/vo/SignupFormData";
import { SimpleToolbar } from "../../../components/abs/molecules/SimpleToolbar";
import { SessionPropertyKey } from "../../../../session/SessionPropertyKey";
import { auth } from "../../../../firebase-config";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  registerBooksApi,
  PictureApi,
  SignupApi,
} from "../../../../redux/features/remote/account/AccountApi";

const Root = styled(Stack)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  background-color: #f9f9f9;
`;

// const MainImage = () => (
//   <AspectRatioBox ratio={RatioType["1/1"]}>
//     <FullSizeImage src="/assets/img/snobs_main_image.png" alt={"main image"} />
//   </AspectRatioBox>
// );

const useWelcomePage = () => {
  const history = useHistory();
  const { data, mutate } = useSignupState();
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);

  const handleSubmit = () => {
    const newSignupState = { ...data };
    // console.log(newSignupState);
    setDisabledSubmitButton(true);
    createUserWithEmailAndPassword(
      auth,
      newSignupState.email,
      newSignupState.kakaoId.toString()
    ) //계정 생성 (uid=pw)
      .then((userCredential) => {
        PictureApi(userCredential.user.uid, data.pictures)
          .then((___response) => {
            if (___response) {
              SignupApi(userCredential.user.uid, data) //firebase db에 회원정보(data) 추가
                .then((response) => {
                  if (response) {
                    registerBooksApi(data.representBooks)
                      .then((_response) => {
                        if (_response) {
                          mutate(initialSignupFormData);
                          sessionStorage.setItem(
                            SessionPropertyKey.USER_ID,
                            userCredential.user.uid
                          );
                          alert(
                            "회원가입이 완료되었습니다! 이제 내 생각을 남겨서 독서친구를 찾아볼까요?"
                          );
                          history.push(RouterPath.MAIN);
                        }
                      })
                      .catch((e) => console.log(e));
                  }
                })
                .catch((e) => console.log(e));
            }
          })
          .catch((e) => console.log(e));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBack = () => {
    history.goBack();
  };

  return {
    handleSubmit,
    handleBack,
    disabledSubmitButton,
  };
};

const WelcomePage = () => {
  const { handleSubmit, handleBack, disabledSubmitButton } = useWelcomePage();

  return (
    <>
      {disabledSubmitButton?
      <Stack justifyContent={"center"} alignItems={"center"} width={"100%"} height={"100%"} position={"absolute"}>
        <CircularProgress />
      </Stack>
      :<></>}
      <Root color={textColorLightBlack}>
        <Stack>
          <SimpleToolbar onClose={handleBack} />
          <Spacer h={"40px"} />

          <Stack px={"1rem"} direction={"row"} justifyContent={"space-between"}>
            <Stack>
              <Typography
                fontFamily={"Poppins"}
                fontWeight={"500"}
                fontSize={"2.3rem"}
                lineHeight={"39px"}
              >
                <span>Welcome to</span>
                <br />
                <span>Qihano!</span>
              </Typography>
              <Spacer h={"12px"} />

              <Typography variant={"caption"} color={textColorGray}>
                <span>책과 글로 인연을 맺는 곳,</span>
                <br />
                <span>키하노에 오신 것을 환영합니다!</span>
              </Typography>
            </Stack>
            <img src={"/assets/img/particle_left.svg"} alt={"particle_left"} width={"20%"} />
          </Stack>
          <Spacer h={"122px"} />
          <Stack px={"1rem"} direction={"row"}>
            <img src={"/assets/img/check.svg"} alt={"check"} />
            <Spacer w={"16px"} />
            <Stack>
              <Typography
                fontSize={"16px"}
                fontWeight={"500"}
                color={textColorDeepGray}
                children={"본연의 나를 드러내요"}
              />
              <Typography
                fontSize={"13px"}
                fontWeight={"500"}
                color={textColorGray}
                children={"정직한 글과 프로필로 진심을 전하세요"}
              />
            </Stack>
          </Stack>
          <Spacer h={"32px"} />
          <Stack px={"1rem"} direction={"row"}>
            <img src={"/assets/img/check.svg"} alt={"check"} />
            <Spacer w={"16px"} />
            <Stack>
              <Typography
                fontSize={"16px"}
                fontWeight={"500"}
                color={textColorDeepGray}
                children={"모든 글은 존중받아야 해요"}
              />
              <Typography
                fontSize={"13px"}
                fontWeight={"500"}
                color={textColorGray}
                children={"다른 사람의 글과 생각을 배려해주세요"}
              />
            </Stack>
          </Stack>
          <Spacer h={"32px"} />
          <Stack px={"1rem"} direction={"row"}>
            <img src={"/assets/img/check.svg"} alt={"check"} />
            <Spacer w={"16px"} />
            <Stack>
              <Typography
                fontSize={"16px"}
                fontWeight={"500"}
                color={textColorDeepGray}
                children={"소통은 젠틀하게"}
              />
              <Typography
                fontSize={"13px"}
                fontWeight={"500"}
                color={textColorGray}
                children={"친절하고 매너있는 대화를 이어가주세요"}
              />
            </Stack>
          </Stack>
        </Stack>

        <Stack>
          <Stack padding={"1rem"}>
            <Button
              variant={"contained"}
              size={"large"}
              children={"확인"}
              onClick={handleSubmit}
              disabled={disabledSubmitButton}
            />
          </Stack>
        </Stack>
      </Root>
    </>
  );
};

export default WelcomePage;
