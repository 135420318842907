import styled from "@emotion/styled";
import { InputBase, Stack } from "@mui/material";
import { AnswerEntity } from "../../../../domain/entities/message/AnswerEntity";
import { textColorGray } from "../../../themes/Color";

const AnswerInput = styled(InputBase)`
  font-size: 14px;
  color: ${textColorGray};
  height: 100%;
  text-align: start;
  align-items: flex-start;
`

export const AnswerBodySection = ({answer}:{answer: AnswerEntity}) => {
    return <>
    <Stack position={"relative"} height={"100%"}>
        <Stack height={"100%"}>
            <AnswerInput
                multiline={true}
                value={answer.message}
            />
        </Stack>
    </Stack>
</>
}