import {Card, Stack, Typography} from "@mui/material";
import React from "react";
import {textColorDeepGray, textDisabledColor} from "../../../../../../../../themes/Color";
import BookWithInfoLayout from "../../../../../../../../components/context/book/BookWithInfoLayout";
import {BookItem} from "../../../../../../../../components/context/book/BookItem";
import {BookEntity} from "../../../../../../../../../domain/entities/book/BookEntity";

export const MyReviewDetailTopSection = (
    {book}: { book: BookEntity }
) => {

    const bookItem =
        <BookItem
            width={"16%"}
            imageUrl={book.thumbnailUrl}
        />

    const infoItem =
        <Stack overflow={"hidden"} whiteSpace={"nowrap"}>
            <Typography
                variant={"subtitle2"}
                color={textColorDeepGray}
                children={book.title}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
            />

            <Stack direction={"row"}>
                <Typography
                    variant={"caption"}
                    color={textDisabledColor}
                    children={book.authors.join(', ')}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                />
            </Stack>
        </Stack>

    //

    return <>
        <Stack>
            <Card sx={{borderRadius: "8px"}}>
                <Stack
                    direction={"row"}
                    width={"100%"}
                    padding={"0.8rem"}
                    justifyContent={"space-between"}
                >
                    <Stack px={"4px"} overflow={"hidden"}>
                        <BookWithInfoLayout bookItem={bookItem} infoItem={infoItem}/>
                    </Stack>
                </Stack>
            </Card>
        </Stack>
    </>
}
