import React from "react";
import { useHistory } from "react-router";
import { VerticalScrollableStack } from "../../../components/abs/base/Scrollable";
import { Button, Stack, Typography } from "@mui/material";
import Spacer from "../../../components/abs/atoms/Spacer";
import { textColorGray } from "../../../themes/Color";
import { SignupPath } from "../../../../router/RouterPath";
import { useSignupState } from "../../../../redux/features/local/signup/SignupSlice";
import { SimpleToolbar } from "../../../components/abs/molecules/SimpleToolbar";

// type AddrData = {
//     region_1depth: string,
//     region_2depth: string
// }

const useGPSPage = () => {
  const history = useHistory();
  const { data, mutate } = useSignupState();

  const locatingSuccess = (position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords;
    const geocoder = new window.kakao.maps.services.Geocoder();

    const coord = new window.kakao.maps.LatLng(latitude, longitude);
    const callback = function (result, status) {
      if (status === window.kakao.maps.services.Status.OK) {
        const newSignupState = { ...data };
        newSignupState.address = {
          profile:
            result[0].address.region_1depth_name +
            " " +
            result[0].address.region_2depth_name,
          latitude: latitude,
          longitude: longitude,
        };
        mutate(newSignupState);
        alert(`위치 정보를 활성화하였습니다.`);
        // console.log(newSignupState);
        history.push(SignupPath.PICTURES);
      }
    };
    geocoder.coord2Address(coord.getLng(), coord.getLat(), callback);
  };

  const locatingError = (error: GeolocationPositionError) => {
    alert(error.message);
  };

  const handleSubmit = () => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
      return;
    }

    navigator.geolocation.getCurrentPosition(locatingSuccess, locatingError);

    const newSignupState = { ...data };
    newSignupState.isAgreedLocationService = true;
    mutate(newSignupState);
  };

  const handleBack = () => {
    history.goBack();
  };

  return {
    handleBack,
    handleSubmit,
  };
};

const GPSPage = () => {
  const { handleBack, handleSubmit } = useGPSPage();

  return (
    <>
      <VerticalScrollableStack height={"100%"}>
        <SimpleToolbar onClose={handleBack} />
        <Spacer h={"24px"} />

        <Stack
          justifyContent={"space-between"}
          height={"100%"}
          padding={"1rem"}
        >
          <Stack alignItems={"center"} textAlign={"center"}>
            <img src={"/assets/img/illust_map.svg"} alt={"map"}/>
            <Spacer h={"24px"} />

            <Typography variant={"h5"}>
              <span>위치 서비스를</span>
              <br />
              <span>활성화해주세요</span>
            </Typography>
            <Spacer h={"16px"} />

            <Typography variant={"caption"} color={textColorGray}>
              <span>위치 서비스를 활성화해서</span>
              <br />
              <span>내 주변에 독서친구를 만들어보세요</span>
            </Typography>
          </Stack>
          <Spacer h={"2rem"} />

          <Button
            size={"large"}
            variant="contained"
            fullWidth
            children={"위치 허용"}
            onClick={handleSubmit}
          />
        </Stack>
      </VerticalScrollableStack>
    </>
  );
};

export default GPSPage;
