import {Stack, Typography} from "@mui/material";
import {textColorDeepGray} from "../../../themes/Color";
import Spacer from "../atoms/Spacer";
import React, {ReactNode} from "react";

export const UserInterestSection = (
    {interests}: { interests: ReactNode[] }
) => {
    return <>
        <Stack>
            <Typography
                variant={"caption"}
                fontWeight={500}
                color={textColorDeepGray}
                children={"내 책 취향은"}/>
            <Spacer h={"0.5rem"}/>

            <div>
                {interests}
            </div>
        </Stack>
    </>
}
