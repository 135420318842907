// import {myInfoMock, userMock3} from "../user/UsersDB";
import { toStringByFormatting } from "../../../utils/datetime/DateTime";
import {MessageEntity} from "../../entities/message/MessageEntity";

export const messageMock1: MessageEntity =
    {
        id: "1",
        senderId: "userMock3",
        message: "안녕하세요",
        regDate: toStringByFormatting(new Date(2021, 10 - 1, 5, 10, 10, 10)),
        userIdWhoRead: ['1', '3']
    }

export const messageMock2: MessageEntity =
    {
        id: "2",
        senderId: "userMock3",
        message: "안녕하세요!! 2트",
        regDate: toStringByFormatting(new Date(2021, 10 - 1, 5, 10, 10, 11)),
        userIdWhoRead: ['1', '3']
    }

export const messageMock3: MessageEntity =
    {
        id: "3",
        senderId: "userMock3",
        message: "안녕하세요!! 3트",
        regDate: toStringByFormatting(new Date(2021, 10 - 1, 5, 10, 10, 12)),
        userIdWhoRead: ['1', '3']
    }

export const messageMock4: MessageEntity =
    {
        id: "4",
        senderId: "myInfoMock",
        message: "안녕하세요",
        regDate: toStringByFormatting(new Date(2021, 10 - 1, 5, 10, 10, 40)),
        userIdWhoRead: ['1', '3']
    }

// export const messageMock5: MessageEntity =
//     {
//         idx: 5,
//         sender: userMock3,
//         message: "응답 확인",
//         regDate: new Date(2021, 10 - 1, 5, 11, 10, 40),
//         userIdWhoRead: ['1', '3']
//     }

// export const messageMock6: MessageEntity =
//     {
//         idx: 6,
//         sender: userMock3,
//         message: "오징어 게임 보셨어요?",
//         regDate: new Date(2021, 10 - 1, 6, 11, 10, 40),
//         userIdWhoRead: ['1', '3']
//     }

// export const messageMock7: MessageEntity =
//     {
//         idx: 7,
//         sender: myInfoMock,
//         message: "그럼 자네가 날 속이고 내 구슬을 모두 가져간 건 말이 되고?",
//         regDate: new Date(2021, 10 - 1, 6, 11, 15, 40),
//         userIdWhoRead: ['1', '3']
//     }

// export const messageMock8: MessageEntity =
//     {
//         idx: 8,
//         sender: userMock3,
//         message: "응답 확인",
//         regDate: new Date(2021, 10 - 1, 6, 14, 10, 40),
//         userIdWhoRead: ['1', '3']
//     }

// export const messageMock9: MessageEntity =
//     {
//         idx: 9,
//         sender: myInfoMock,
//         message: "응답 확인",
//         regDate: new Date(2021, 10 - 1, 6, 14, 10, 40),
//         userIdWhoRead: ['1', '3']
//     }

// export const messageMock10: MessageEntity =
//     {
//         idx: 10,
//         sender: userMock3,
//         message: "여자친구 있어요?",
//         regDate: new Date(2021, 10 - 1, 7, 14, 10, 40),
//         userIdWhoRead: ['1', '3']
//     }

// export const messageMock11: MessageEntity =
//     {
//         idx: 11,
//         sender: myInfoMock,
//         message: "지금은 없어요",
//         regDate: new Date(2021, 10 - 1, 7, 20, 10, 40),
//         userIdWhoRead: ['1', '3']
//     }

// export const messageMock12: MessageEntity =
//     {
//         idx: 12,
//         sender: myInfoMock,
//         message: "(...)",
//         regDate: new Date(2021, 10 - 1, 7, 20, 10, 50),
//         userIdWhoRead: ['1', '3']
//     }

// export const messageMock13: MessageEntity =
//     {
//         idx: 13,
//         sender: userMock3,
//         message: "만나볼래요?",
//         regDate: new Date(2021, 10 - 1, 8, 9, 10, 40),
//         userIdWhoRead: ['1', '3']
//     }

// export const messageMock14: MessageEntity =
//     {
//         idx: 14,
//         sender: myInfoMock,
//         message: "응답 확인",
//         regDate: new Date(2021, 10 - 1, 8, 9, 10, 50),
//         userIdWhoRead: ['1']
//     }

// export const messageMock15: MessageEntity =
//     {
//         idx: 15,
//         sender: userMock3,
//         message: "안 읽은 최근 메시지 테스트 123123123123123123123123123123123123",
//         regDate: new Date(2021, 10 - 1, 8, 10, 10, 50),
//         userIdWhoRead: ['3']
//     }

export const messagesMock =
    [messageMock1, messageMock2, messageMock3, messageMock1, messageMock2, messageMock3, messageMock1, messageMock2, messageMock3]
