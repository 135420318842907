import {Stack, Typography} from "@mui/material";
import Spacer from "../../../../../components/abs/atoms/Spacer";
import {primaryVariantColor, textColorDeepGray} from "../../../../../themes/Color";
import React from "react";
import {UserEntity} from "../../../../../../domain/entities/user/UserEntity";

export type ThinkronizedTextSectionProps = {
    partner: UserEntity
}

export const ThinkronizedTextSection = (
    {partner}: ThinkronizedTextSectionProps
) => {
    return <>
        <Stack alignItems={"center"} position={"relative"}>
            <Typography
                variant={"h4"}
                fontFamily={"Poppins"}
                fontWeight={"bold"}
            >
                <span style={{color: primaryVariantColor}}>Think</span><span>ronized!</span>
            </Typography>
            <Spacer h={"1rem"}/>

            <Typography
                variant={"subtitle2"}
                color={textColorDeepGray}
                textAlign={"center"}
            >
                <span>축하해요!</span><br/>
                <span style={{color: primaryVariantColor}}>{partner.nickname}</span>
                <span>님이 회원님의 생각을 읽고</span><br/>
                <span>대화를 시작했어요</span>
            </Typography>
        </Stack>
    </>
}
