import { SlideUpTransition } from "../../../animations/Transitions";
import QihanoDialog from "../../../components/context/base/QihanoDialog";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Button, Snackbar, Stack, Typography } from "@mui/material";
import { VerticalScrollableStack } from "../../../components/abs/base/Scrollable";
import Spacer from "../../../components/abs/atoms/Spacer";
import {
  primaryVariantColor,
  textColorLightBlack,
} from "../../../themes/Color";
import { ChatHeader } from "./sections/header/ChatHeader";
import { ChatSharedBookSection } from "./sections/shared_book/ChatSharedBookSection";
import { ChatMessagesSection } from "./sections/messages/ChatMessagesSection";
import { MessageInputBox } from "../../../components/abs/molecules/MessageInputBox";
import { useChatDialogSlice } from "../../../../redux/features/local/dialogs/chat/ChatDialogSlice";
import BottomSheet from "../../../components/abs/atoms/BottomSheet";
import { ChatAppbar } from "./sections/header/ChatAppbar";
import { useProfileDetailDialogSlice } from "../../../../redux/features/local/dialogs/profile/ProfileDetailDialogSlice";
import { useBookDetailDialogSlice } from "../../../../redux/features/local/dialogs/book/detail/BookDetailDialogSlice";
import { BookEntity } from "../../../../domain/entities/book/BookEntity";
import { useUsersState } from "../../../../redux/features/remote/users/UsersSlice";
import { setBookDetailType } from "../../../../redux/features/remote/books/BookApi";
import { useMyInfo } from "../../../../redux/features/remote/users/myInfo/MyInfoSlice";
import { useReviewsState } from "../../../../redux/features/remote/reviews/slice/ReviewsSlice";
import { ChatRoom } from "../../../../domain/entities/room/ChatRoom";
import {
  readAllMessages,
  removeMyIdFromChatRoom,
  reportChatUser,
  sendMessage,
} from "../../../../redux/features/remote/chat/ChatApi";
import { useChatRoomsState } from "../../../../redux/features/remote/chat/ChatRoomsSlice";
import { deleteChatRoom } from "../../../../redux/features/remote/chat/ChatApi";
import { onValue, ref } from "firebase/database";
import { database } from "../../../../firebase-config";
import { addToBlacklist } from "../../../../redux/features/remote/users/UsersApi";

export type ChatDialogProps = {
  isOpen: boolean;
  chatRoom: ChatRoom;
};

export const ChatDialog = () => {
  const messagesEndRef = useRef<HTMLInputElement>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const { close, data } = useChatDialogSlice();
  const { isOpen, chatRoom: oldChatRoom } = data;
  const { data: userDB } = useUsersState();
  const { data: myInfo, mutate: mutateMyInfo } = useMyInfo();
  const { data: reviewDB } = useReviewsState();
  const { data: chatRooms, mutate: mutateChatRooms } = useChatRoomsState();

  const partnerId = oldChatRoom.usersId.find((it) => it !== myInfo.uid);
  const partner = userDB.find((user) => user.uid === partnerId);
  const { sharedBook: book } = oldChatRoom;

  const [message, setMessage] = useState("");
  const idx = chatRooms.findIndex((it) => it.id === oldChatRoom.id);

  const [openBs, setOpenBs] = useState(false);
  const [openSb, setOpenSb] = useState(false);

  const { mutate: mutateProfileDetailDialog } = useProfileDetailDialogSlice();
  const { mutate: mutateBookDetailDialog } = useBookDetailDialogSlice();

  useEffect(() => {
    if (isOpen === true) {
      // 안읽은메시지 -> 읽은 메시지로
      readAllMessages(oldChatRoom, myInfo.uid);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen === true) {
      //채팅 실시간 업데이트
      onValue(
        ref(
          database,
          "chatRooms/" + [myInfo.uid, partnerId].sort().join("_") + "/"
        ),
        (snapshot) => {
          const data: ChatRoom = snapshot.val();
          // console.log("onValue", data);
          if (data) {
            mutateChatRooms(
            chatRooms.map((it, idx) => {
                //redux에 해당 대화방의 data 업데이트
                if (it.id === oldChatRoom.id) {
                return data;
                } else {
                return chatRooms[idx];
                }
            })
            );
          }
        }
      );
    }
  }, [oldChatRoom]);

  useEffect(()=>{
    if(isOpen === true && message === ""){
      scrollToBottom()
    }
  },[isOpen, message]);

  if (!partner) {
    return <></>;
  }

  const handleMessageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const handleMessageSubmit = (
    chatRoom: ChatRoom,
    userId: string,
    message: string
  ) => {
    if(chatRoom.usersId.length === 1 && chatRoom.usersId.some((it) => it === myInfo.uid)){
        alert("상대방이 퇴장하였습니다. 대화를 종료합니다.");
        deleteChatRoom(chatRoom)
        mutateChatRooms(chatRooms.filter(it => it.id !== chatRoom.id))
        close()
    } else {
        sendMessage(chatRoom, userId, message);
        setMessage("");
    }
  };

  const handleOptionClick = () => {
    setOpenBs(true);
  };

  const handleReport = () => {
    setOpenBs(false);
    if (
      window.confirm(
        "신고하시면 채팅 내역을 확인한 후 신속하게 조치하겠습니다. 신고할까요?"
      )
    ) {
        reportChatUser(myInfo.uid, partner.uid, chatRooms[idx]).then(response => {
            if(response){
                window.open(
                    `https://docs.google.com/forms/d/e/1FAIpQLSeCxo5x_T0HJ2gHRPCKgr2J-sS5W5WNY5wz2sSwK0SAESXylg/viewform?entry.2054425289=${
                      myInfo.email
                    }&entry.1260556263=${
                      myInfo.nickname
                    }&entry.1523641100=${"비매너 신고"}&entry.534926551=${partner.nickname}`
                  );
                  close();
                  removeMyIdFromChatRoom(myInfo.uid, chatRooms[idx]);
                  mutateChatRooms(chatRooms.filter((it) => it.id !== chatRooms[idx].id));
                  setOpenSb(true);
            }
        })
    }
  };

  const handleBlock = () => {
      if(window.confirm(`${partner.nickname}님을 차단할까요?`)){
        addToBlacklist(myInfo, partner.uid).then((response) => {
            if (response) {
                alert("차단하였습니다.")
              mutateMyInfo({ ...myInfo, blacklist: response });
              close();
              removeMyIdFromChatRoom(myInfo.uid, chatRooms[idx]);
              mutateChatRooms(chatRooms.filter((it) => it.id !== chatRooms[idx].id));
              setOpenSb(true);
            }
          });
      }
    setOpenBs(false);
  };

  const handleRemoveChatRoom = () => {
    setOpenBs(false);
    if (
      window.confirm("이 대화방을 나갈까요? 나가기를 하면 목록에도 삭제됩니다.")
    ) {
      close();
      removeMyIdFromChatRoom(myInfo.uid, chatRooms[idx]);
      mutateChatRooms(chatRooms.filter((it) => it.id !== chatRooms[idx].id));
      setOpenSb(true);
    }
  };

  const handleProfileDetail = () => {
    if (userDB.some((it) => it.uid === partner.uid)) {
      mutateProfileDetailDialog({
        isOpen: true,
        user: partner,
      });
    } else {
      alert("탈퇴한 회원입니다.");
    }
  };

  const handleBookClick = (book: BookEntity) => {
    const type = setBookDetailType(myInfo, book, reviewDB);
    mutateBookDetailDialog({
      isOpen: true,
      book: book,
      type: type,
      uid: myInfo.uid,
    });
  };

  const handleClose = () => {
    readAllMessages(chatRooms[idx], myInfo.uid);
    close();
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if(message !== ""){
        handleMessageSubmit(chatRooms[idx], myInfo.uid, message);
      }
    }
  };

  //

  return (
    <>
      <QihanoDialog
        fullScreen={true}
        open={isOpen}
        TransitionComponent={SlideUpTransition}
      >
        <Stack height={"100%"} color={textColorLightBlack}>
          <ChatAppbar
            partner={partner}
            onClose={handleClose}
            onOption={handleOptionClick}
          />

          <VerticalScrollableStack height={"100%"}>
            <ChatHeader partner={partner} onProfile={handleProfileDetail} />
            <Spacer h={"24px"} />

            <Stack ml={"1rem"}>
              <ChatSharedBookSection
                book={book}
                onBookClick={handleBookClick}
              />
            </Stack>

              {chatRooms[idx] ? (<>
                <Stack px={"1rem"}>
                  <ChatMessagesSection
                    partner={partner}
                    messages={chatRooms[idx].messages}
                    onProfileClick={handleProfileDetail}
                  />
                </Stack>
                <div ref={messagesEndRef} />
                </>
              ) : (
                <></>
              )}
          </VerticalScrollableStack>

          <Stack px={"1rem"} py={"1rem"}>
            <MessageInputBox
              inputBaseProps={{
                value: message,
                onChange: handleMessageChange,
                onKeyUp: handleKeyUp,
              }}
              iconButtonProps={{
                disabled: message === "",
                onClick: () =>
                  handleMessageSubmit(chatRooms[idx], myInfo.uid, message),
              }}
            />
          </Stack>
        </Stack>
      </QihanoDialog>

      <BottomSheet
        anchor={"bottom"}
        open={openBs}
        onClose={() => setOpenBs(false)}
      >
        <Stack padding={"1rem"}>
          <Stack gap={"0.5rem"}>
            <Button
              sx={{ justifyContent: "flex-start" }}
              onClick={handleReport}
              children={"신고하기"}
            />
            <Button
              sx={{ justifyContent: "flex-start" }}
              onClick={handleBlock}
              children={"차단하기"}
            />
            <Button
              sx={{ justifyContent: "flex-start" }}
              onClick={handleRemoveChatRoom}
              children={"나가기"}
            />
          </Stack>
        </Stack>
      </BottomSheet>

      <Snackbar
        open={openSb}
        autoHideDuration={3000}
        onClose={() => setOpenSb(false)}
        message={
          <Typography
            variant={"body2"}
            fontWeight={"bold"}
            children={"대화방이 삭제되었습니다."}
          />
        }
        sx={{ position: "absolute" }}
      />
    </>
  );
};
