import { NotificationEntity } from './../../../../domain/entities/notice/NotificationEntity';
import { getCurrentDateTime, toStringByFormatting } from "./../../../../utils/datetime/DateTime";
import { query, where } from "firebase/firestore";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { ReactionEntity } from "../../../../domain/entities/reaction/ReactionEntity";
import { ReactionIReceivedEntity } from "../../../../domain/entities/reaction/ReactionIReceivedEntity";
import { ReviewEntity } from "../../../../domain/entities/review/ReviewEntity";
import { ReactionTypes } from "../../../../domain/types/ReactionTypes";
import { database, db } from "../../../../firebase-config";
import {
  ReactionISendEntity,
  computeRemainingDay,
} from "./../../../../domain/entities/reaction/ReactionISendEntity";
import { computeDiffDay } from "../../../../utils/datetime/DateTime";
import { child, push, ref, update, remove } from "firebase/database";
import { UserEntity } from '../../../../domain/entities/user/UserEntity';


export const fetchReactionsDB = async (userId: string) => {
  const reactionISendList: Array<ReactionISendEntity> = [];
  const reactionIReceivedList: Array<ReactionIReceivedEntity> = [];
  const querySnapshot = await getDocs(collection(db, "reactions"));
  // console.log("get from fetchReactionsDB");

  querySnapshot.forEach((doc) => {
    const data: ReactionEntity = JSON.parse(JSON.stringify(doc.data()));
    if(computeDiffDay(getCurrentDateTime(), new Date(data.regDate))>5){
      deleteReaction(data.senderId, data.targetReview);
    } else{
      if (data.senderId === userId) {
        const reactionISend: ReactionISendEntity = data;
        reactionISendList.push(reactionISend);
      }
      if (data.receiverId === userId) {
        const reactionIReceived: ReactionIReceivedEntity = data;
        reactionIReceivedList.push(reactionIReceived);
      }
    }
  });

  return { reactionISendList, reactionIReceivedList };
};

export const addReaction = async (
  sender: UserEntity, //me
  receiverId: string,
  review: ReviewEntity,
  type: ReactionTypes
) => {
  const reactionList: Array<ReactionEntity> = [];

  const querySnapshot = await getDocs(collection(db, "reactions"));
  // console.log("get from addReaction");

  querySnapshot.forEach((doc) => {
    const data: ReactionEntity = JSON.parse(JSON.stringify(doc.data()));
    reactionList.push(data);
  });

  if(reactionList.some(it => it.senderId === sender.uid && it.receiverId === receiverId && it.targetReview.id === review.id && computeDiffDay(getCurrentDateTime(), new Date(it.regDate))<5)){
    alert("이미 반응을 한 글입니다.");
    return false;
  }
  else {
    const regDate = toStringByFormatting(new Date());
    const reactionId = sender.uid+review.id;
    const reactionData: ReactionEntity = {
        id: reactionId,
        senderId: sender.uid,
        receiverId: receiverId,
        targetReview: review,
        regDate: regDate,
        type: type,
      };
    
      const reactionRef = doc(db, "reactions", reactionId);
      await setDoc(reactionRef, reactionData);

      //알림보내기
      // const newKey = push(child(ref(database), receiverId + "/notifications")).key ?? "-1";
      const notificationData:NotificationEntity = {
        id: reactionId,
        type: "reaction",
        title: `${sender.nickname}님이 좋아요를 눌렀어요`,
        subTitle: review.title,
        date: regDate
      }
      const updates = {};
      updates[receiverId + "/notifications/" + reactionId] = notificationData;
      update(ref(database), updates);
  
      return true;
  }
};

export const deleteReaction = async (senderId: string, review: ReviewEntity) => {
  const reactionId = senderId + review.id;
  await deleteDoc(doc(db, "reactions", reactionId));
  remove(ref(database, review.writerId+"/notifications/"+ reactionId));
  return true;
};
