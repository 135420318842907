import {Stack} from "@mui/material";
import {BookItem} from "../../../../../components/context/book/BookItem";

export const BookDetailImageSection = (
    {bookThumbnailUrl}: { bookThumbnailUrl: string }
) => {
    return <>
        <Stack
            width={"25%"}
            alignItems={"center"}
            mt={"-15%"}
            mx={"auto"}
        >
            <BookItem imageUrl={bookThumbnailUrl}/>
        </Stack>
    </>
}
