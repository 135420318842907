import styled from "@emotion/styled";
import {Card, Stack, Typography} from "@mui/material";
import {VerticalScrollableStack} from "../../../../components/abs/base/Scrollable";
import {primaryVariantColor, textColorGray, textDisabledColor} from "../../../../themes/Color";
import AvatarWithCircularProgress from "../../../../components/abs/molecules/AvatarWithCircularProgress";
import React, {useState} from "react";
import {computeWhatPercentageOf24Hours, getCurrentDateTime, getDiffHour} from "../../../../../utils/datetime/DateTime";
import Spacer from "../../../../components/abs/atoms/Spacer";
import {MessageInputBox} from "../../../../components/abs/molecules/MessageInputBox";
import {AnswerEntity} from "../../../../../domain/entities/message/AnswerEntity";
import {ReviewBodySection} from "../../../../components/context/molecules/ReviewBodySection";
import {initialUserEntity, UserEntity} from "../../../../../domain/entities/user/UserEntity";
import { useUsersState } from "../../../../../redux/features/remote/users/UsersSlice";
import { AnswerBodySection } from "../../../../components/context/molecules/AnswerBodySection";

const RootCard = styled(Card)`
  height: 100%;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
`

//

export type GreetingBottomCardProps = {
    answer: AnswerEntity,
    onAvatarClick: (user: UserEntity) => void,
    onSubmit: (greeting: string) => void,
    disableTitle?: boolean
}

export const GreetingBottomCard = (
    {answer, onAvatarClick, onSubmit}: GreetingBottomCardProps
) => {

    const {data:userDB} = useUsersState()
    const sender = userDB.find(user => user.uid === answer.senderId);

    const diffTime = getDiffHour(getCurrentDateTime(), new Date(answer.expirationDate))
    const progressValue = computeWhatPercentageOf24Hours(diffTime)

    const [greetingMsg, setGreetingMsg] = useState("")

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
          onSubmit(greetingMsg);
        }
      };


    //

    return (typeof sender !== 'undefined')?<>
        <RootCard elevation={5}>
            <Stack height={"100%"}>
                <VerticalScrollableStack flex={1} padding={"1rem"} justifyContent={"space-between"}>
                    <Stack pb={"20rem"}>
                        <AnswerBodySection
                            answer={answer}
                        />
                    </Stack>

                    <Stack
                        position={"absolute"}
                        left={0}
                        bottom={0}
                        bgcolor={"#fff"}
                        width={"100%"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        p={"1rem"}
                    >
                        <AvatarWithCircularProgress
                            url={sender.profileImages[0].url}
                            circularProgressProps={{
                                variant: "determinate",
                                color: "secondary",
                                value: progressValue
                            }}
                            avatarProps={{
                                onClick: () => onAvatarClick(sender)
                            }}
                        />
                        <Spacer h={"1rem"}/>

                        <Stack textAlign={"center"}>
                            <Typography
                                variant={"caption"}
                                color={textDisabledColor}
                                fontFamily={"Poppins"}
                                fontSize={"12px"}
                                children={"From"}
                            />
                            <Typography
                                variant={"caption"}
                                color={textColorGray}
                                fontWeight={500}
                                children={sender.nickname}
                                mt={"-0.2rem"}
                            />
                        </Stack>
                        <Spacer h={"1.2rem"}/>

                        <MessageInputBox
                            inputBaseProps={{
                                placeholder: `${sender.nickname}님에게 메시지를 보내보세요.`,
                                value: greetingMsg,
                                onChange: (e) => setGreetingMsg(e.target.value),
                                onKeyUp: handleKeyUp
                            }}
                            iconButtonProps={{
                                onClick: () => onSubmit(greetingMsg),
                                disabled: (greetingMsg === '')
                            }}
                        />
                    </Stack>
                </VerticalScrollableStack>
                <Stack>

                </Stack>
            </Stack>
        </RootCard>
    </>:<></>
}
