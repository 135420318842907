import { Avatar, Stack, Typography } from "@mui/material";
import Spacer from "../../../../../../components/abs/atoms/Spacer";
import {
  textColorDeepGray,
  textColorGray,
} from "../../../../../../themes/Color";
import {
  getUserAge,
  UserEntity,
} from "../../../../../../../domain/entities/user/UserEntity";
import React, { useEffect, useState } from "react";
import { ReactComponent as CertificationIcon } from "../../../../../../../icons/icon_certification_small.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { v4 } from "uuid";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Picture } from "../../../../../../../domain/vo/Picture";
import { useMyInfo } from "../../../../../../../redux/features/remote/users/myInfo/MyInfoSlice";

export type ProfileDetailHeaderProps = {
  user: UserEntity;
};

const useStyles = makeStyles(() =>
  createStyles({
    styledSwiper: {
      width: "100%",
      marginBottom: "-6px",

      "& .swiper-wrapper": {
        display: "flex",
        alignItems: "flex-end",
      },
    },
    styledSwiperSlideItem: {
      width: "6rem",

      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
  })
);

export const ProfileDetailHeader = ({ user }: ProfileDetailHeaderProps) => {
  const classes = useStyles();
    const [pictures, setPictures] = useState<Array<Picture>>([])
    const {data:myInfo} = useMyInfo()

    useEffect(()=>{
        if(myInfo.isFaceAuth) {setPictures([...user.profileImages])}
        else { setPictures([user.profileImages[0]])}
    }, [])
  return (
    <>
      <Stack>
        <Swiper
          spaceBetween={15}
          slidesPerView={"auto"}
          className={classes.styledSwiper}
        >
          {pictures.map((image) => (
            <SwiperSlide key={v4()} className={classes.styledSwiperSlideItem}>
              <Avatar
                alt={"profile image"}
                src={image.url}
                sx={{
                  width: "100px",
                  height: "100px",
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* <Avatar
          alt={"profile image"}
          src={user.profileImages[0].url}
          sx={{
            width: "100px",
            height: "100px",
          }}
        /> */}
        <Spacer h={"1rem"} />

        <Stack direction={"row"} alignItems={"center"}>
          <Typography variant={"subtitle1"} children={user.nickname} />

          <Spacer w={"0.2rem"} />
          {user.isFaceAuth ? (
            <CertificationIcon fill={textColorDeepGray} />
          ) : (
            <></>
          )}
        </Stack>
        <Spacer h={"12px"} />

        <Typography
          variant={"caption"}
          color={textColorGray}
          lineHeight={"20px"}
          children={user.selfIntroduction}
        />
        <Spacer h={"0.5rem"} />

        <Typography
          variant={"caption"}
          color={textColorDeepGray}
          fontWeight={500}
          children={`${getUserAge(user.birthDate)}세 ･ ${user.gender} ･ ${
            user.address.profile
          }`}
        />
      </Stack>
    </>
  );
};
