import {VerticalScrollableStack} from "../../../../../../components/abs/base/Scrollable";
import {MyReviewDetailHeader, MyReviewDetailHeaderProps} from "./sections/header/MyReviewDetailHeader";
import Spacer from "../../../../../../components/abs/atoms/Spacer";
import {Stack, Typography} from "@mui/material";
import {MyReviewDetailTopSection} from "./sections/top/MyReviewDetailTopSection";
import {ReviewBodySection} from "../../../../../../components/context/molecules/ReviewBodySection";
import {ReviewEntity} from "../../../../../../../domain/entities/review/ReviewEntity";
import {textColorDeepGray} from "../../../../../../themes/Color";
import {CheckRounded} from "@mui/icons-material";
import { useReviewsState } from "../../../../../../../redux/features/remote/reviews/slice/ReviewsSlice";

export const MyReviewDetailTemplate = (
    p: {
        review: ReviewEntity
    } & MyReviewDetailHeaderProps
) => {
    const {review} = p
    const isReviewPublic = !!(review as ReviewEntity).isPublic

    const {data: reviewDB} = useReviewsState()

    //

    const renderPublicReviewCaption =
        isReviewPublic &&
        <Stack direction={"row"} alignItems={"center"}>
            <CheckRounded fontSize={"small"} sx={{padding: "2px"}}/>
            <Typography
                variant={"caption"}
                color={textColorDeepGray}
                fontSize={"12px"}
                fontWeight={"bold"}
                children={"다른 회원이 볼 수 있어요"}
            />
        </Stack>

    return <>
        <VerticalScrollableStack height={"100%"}>
            <MyReviewDetailHeader {...p}/>
            <Spacer h={"1rem"}/>

            <Stack px={"1rem"}>
                <MyReviewDetailTopSection
                    book={review.targetBook}
                />
            </Stack>
            <Spacer h={"1.5rem"}/>

            <Stack px={"1rem"} pb={"6rem"}>
                {renderPublicReviewCaption}
                <ReviewBodySection
                    title={review.title}
                    content={review.content}
                    readonly
                />
            </Stack>
        </VerticalScrollableStack>
    </>
}
