import {useDispatch, useSelector} from "react-redux";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../../../store/QihanoStore";
import {ThinkronizedDialogProps} from "../../../../../ui/dialogs/childs/thinkronized/ThinkronizedDialog";


const initialState: ThinkronizedDialogProps = {
    isOpen: false,
    partnerId: ''
}

const ThinkronizedDialogSlice = createSlice({
    name: 'thinkronizedDialog',
    initialState: initialState,
    reducers: {
        setThinkronizedDialogState: (state, action: PayloadAction<ThinkronizedDialogProps>) =>
            action.payload,
    }
})

export const useThinkronizedDialogSlice = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.thinkronizedDialog)

    const mutate = (state: ThinkronizedDialogProps) => {
        dispatch(setThinkronizedDialogState(state))
    }

    const close = () => {
        mutate({
            ...data,
            isOpen: false
        })
    }


    return {
        close,
        mutate,
        data
    }
}

export const {setThinkronizedDialogState} = ThinkronizedDialogSlice.actions
export default ThinkronizedDialogSlice.reducer
