import {Stack} from "@mui/material";
import BookDetailDialog from "./childs/book/detail/BookDetailDialog";
import ProfileEditDialog from "./childs/profile/edit/ProfileEditDialog";
import {ReactionsBottomSheet} from "./childs/review/detail/sub/others/sections/bottom/ReactionsBottomSheet";
import ReviewEditDialog from "./childs/review/edit/ReviewEditDialog";
import React from "react";
import {AnswerEditDialog} from "./childs/answer/edit/AnswerEditDialog";
import {GreetingDialog} from "./childs/answer/greeting/GreetingDialog";
import {ChatDialog} from "./childs/chat/ChatDialog";
import {ThinkronizedDialog} from "./childs/thinkronized/ThinkronizedDialog";
import {NoticeDialog} from "./childs/notice/NoticeDialog";
import {ProfileDetailDialog} from "./childs/profile/detail/ProfileDetailDialog";
import {BooksThisMonthDialog} from "./childs/book/this_month/BooksThisMonthDialog";
// import {BooksNextMonthDialog} from "./childs/book/next_month/BooksNextMonthDialog";
import {ReviewDetailDialog} from "./childs/review/detail/ReviewDetailDialog";

const GlobalDialogContainer = () => {

    return <>
        <Stack>
            <BookDetailDialog/>
            <BooksThisMonthDialog/>
            {/* <BooksNextMonthDialog/> */}
            <ReactionsBottomSheet/>
            <ReviewEditDialog/>
            <ReviewDetailDialog/>
            <AnswerEditDialog/>
            <GreetingDialog/>
            <ChatDialog/>
            <ThinkronizedDialog/>
            <NoticeDialog/>
            <ProfileDetailDialog/>
            <ProfileEditDialog/>
        </Stack>
    </>
}

export default GlobalDialogContainer
