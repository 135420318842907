import React, {ReactNode} from "react";
import {Stack} from "@mui/material";
import {ImageAddItem, ImagePickerItemCheckable} from "./ImagePickerItem";
import {v4} from "uuid";
import {ImageFilesState, useImagePicker} from "./ImagePickerHook";

import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/swiper.scss';
import {createStyles, makeStyles} from "@material-ui/styles";
import AspectRatioBox, {RatioType} from "../../../abs/molecules/AspectRatioBox";
import {Picture} from "../../../../../domain/vo/Picture";


const useStyles = makeStyles(() => createStyles({
    styledSwiper: {
        width: "100%",
        padding: "0 25%"
    }
}))


const ImagePickerSwiper = (
    p: {
        imageAddItemProps?: {
            contentItem?: ReactNode
        }
        swiperProps?: Swiper
        checkedPicture?: Picture
        setCheckedPicture?: (picture: Picture) => void
        disableDelete?: boolean
    } & ImageFilesState
) => {
    const styles = useStyles()

    const {
        inputFileRef,
        handleInputFileSelect,
        handleDeleteClick,
    } = useImagePicker(p)

    const handlePictureClick = (picture: Picture) => {
        p.setCheckedPicture && p.setCheckedPicture(picture)
    }

    const handleDeletePicture = (picture: Picture) => {
        if (p.checkedPicture === picture) {
            handlePictureClick(picture)
        }

        handleDeleteClick(picture)
    }

    const renderImageFile =
        p.imageFiles.map(it =>
            <SwiperSlide key={v4()}>
                <AspectRatioBox ratio={RatioType["1/1"]}>
                    <ImagePickerItemCheckable
                        picture={it}
                        onClick={() => handlePictureClick(it)}
                        onDeleteClick={() => handleDeletePicture(it)}
                        isChecked={it === p.checkedPicture}
                        disableDelete={p.disableDelete}
                    />
                </AspectRatioBox>
            </SwiperSlide>
        )

    const conditionalRenderImageAddItem =
        p.imageAddItemProps?.contentItem &&
        <SwiperSlide>
            <ImageAddItem
                contentItem={p.imageAddItemProps.contentItem}
                onClick={() => inputFileRef.current?.click()}
            />
        </SwiperSlide>


    return <>
        <Stack width={"100%"}>
            <Swiper
                className={styles.styledSwiper}
                slidesPerView={"auto"}
                {...p.swiperProps}
            >
                {renderImageFile}
                {conditionalRenderImageAddItem}
            </Swiper>

            <input
                type={"file"}
                ref={inputFileRef}
                hidden
                onChange={handleInputFileSelect}
            />

        </Stack>
    </>
}

export default ImagePickerSwiper
