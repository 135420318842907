import { UserEntity } from './../../../../domain/entities/user/UserEntity';
import { NotificationEntity } from './../../../../domain/entities/notice/NotificationEntity';
import { child, get, push, ref, remove, set, update } from "firebase/database";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { AnswerEntity } from "../../../../domain/entities/message/AnswerEntity";
import { ReviewEntity } from "../../../../domain/entities/review/ReviewEntity";
import { database, db } from "../../../../firebase-config";
import {
  getCurrentDateTime,
  getDiffHour,
  toStringByFormatting,
} from "../../../../utils/datetime/DateTime";


export const sendAnswer = async (
//realtime database
  sender: UserEntity, //me
  receiverId: string,
  review: ReviewEntity,
  answer: string
) => {
  let answers: Array<AnswerEntity> = [];
  let response = false;

  //이미 대화요청을 했는지 확인
  await get(child(ref(database), `${receiverId}/answers`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        answers = Object.values(snapshot.val());
        if (!answers.some(
          (it) => it.senderId === sender.uid && it.targetReview.id === review.id
        )) {
          response = true;
        }
      } else {
        response = true;
      }
    })
    .catch((error) => {
      console.log(error);
    });

  if(response){
    const newKey =
    push(child(ref(database), receiverId + "/answers")).key ?? "-1";
    const tomorrow = new Date(new Date().setDate(new Date().getDate()+1));

  const answerData: AnswerEntity = {
    isExpiration: false,
    expirationDate: toStringByFormatting(tomorrow),
    id: newKey,
    senderId: sender.uid,
    receiverId: receiverId,
    targetReview: review,
    message: answer,
    regDate: toStringByFormatting(new Date()),
    userIdWhoRead: [receiverId],
    question: review.question
  };

  const notificationData: NotificationEntity = {
    id: newKey,
    type: "answer",
    title: `${sender.nickname}님이 대화를 요청했어요`,
    subTitle: review.title,
    date: toStringByFormatting(new Date())
  }

  const updates = {};
  updates[receiverId + "/answers/" + newKey] = answerData;
  updates[receiverId + "/notifications/" + newKey] = notificationData;

  update(ref(database), updates);
  }

  return response
};


export const deleteAnswer = (answer: AnswerEntity) => {
  remove(ref(database, answer.receiverId+"/answers/"+answer.id))
  remove(ref(database, answer.receiverId+"/notifications/"+answer.id))
}


export const reportAnswerUser = async (myId: string, answer: AnswerEntity) => {
  const regDate = toStringByFormatting(new Date())
  await setDoc(doc(db, "admin", "report_" + regDate), {
    date: regDate,
    type: "reportAnswerUser",
    uid: myId,
    senderId: answer.senderId,
    answerMessage: answer.message
  })

  return true;
}