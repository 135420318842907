import { SlideUpTransition } from "../../../../animations/Transitions";
import QihanoDialog from "../../../../components/context/base/QihanoDialog";
import { useBooksThisMonthDialogSlice } from "../../../../../redux/features/local/dialogs/book/this_month/BookThisMonthDialogSlice";
import { VerticalScrollableStack } from "../../../../components/abs/base/Scrollable";
import { SimpleDialogAppbar } from "../../../../components/abs/molecules/SimpleDialogAppbar";
import BooksThisMonthHeader from "./sections/header/BooksThisMonthHeader";
import { Snackbar, Stack, Typography } from "@mui/material";
import { textColorLightBlack } from "../../../../themes/Color";
import { BookCardItem } from "../../../../components/context/molecules/BookCardItem";
import Spacer from "../../../../components/abs/atoms/Spacer";
import { v4 } from "uuid";
import { BookEntity } from "../../../../../domain/entities/book/BookEntity";
import { useBookDetailDialogSlice } from "../../../../../redux/features/local/dialogs/book/detail/BookDetailDialogSlice";
// import {useBooksNextMonthDialogSlice} from "../../../../../redux/features/local/dialogs/book/next_month/BookNextMonthDialogSlice";
import { BookDetailActionType } from "../detail/ButtonActionsCase";
import React, { useState } from "react";
import { useMyInfo } from "../../../../../redux/features/remote/users/myInfo/MyInfoSlice";
import { useReviewsState } from "../../../../../redux/features/remote/reviews/slice/ReviewsSlice";
import { useBooksState } from "../../../../../redux/features/remote/books/BooksSlice";
import { setBookDetailType } from "../../../../../redux/features/remote/books/BookApi";
import { AddBookmark } from "../../../../../redux/features/remote/bookmark/BookmarkApi";

export type BooksThisMonthDialogProps = {
  isOpen: boolean;
};

export const BooksThisMonthDialog = () => {
  const { data: myInfo, mutate: mutateMyInfo } = useMyInfo();
  const { data: reviewDB } = useReviewsState();
  const { data: bookDB, mutate: mutateBooks } = useBooksState();
  
  const booksId:string[] = ["8901219948 9788901219943", "8947546720 9788947546720", "8937460386 9788937460388", "8956056536 9788956056531", "8932023158 9788932023151"]
  const booksThisMonth = bookDB.filter(it => booksId.includes(it.bookId))

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");

  const { close, data } = useBooksThisMonthDialogSlice();
  const { isOpen } = data;

  const { mutate: mutateBookDetailDialog } = useBookDetailDialogSlice();
  const handleBookClick = (book: BookEntity) => {
    const type = setBookDetailType(myInfo, book, reviewDB);
    mutateBookDetailDialog({
      isOpen: true,
      book: book,
      type: type,
      uid: myInfo.uid,
    });
  };

  const handleBookmark = (book: BookEntity) => {
    const type = setBookDetailType(myInfo, book, reviewDB);
    if (type === BookDetailActionType.WISH_BOOK) {
      AddBookmark(myInfo, bookDB, book, type)
        .then((response) => {
          if (response) {
            const newMyInfoState = { ...myInfo };
            newMyInfoState.bookmarks = response.newMyInfo.bookmarks;
            mutateMyInfo(newMyInfoState);
            mutateBooks(response.newBookDB);

            setSnackbarMsg("책이 등록되었습니다.");
            setOpenSnackbar(true);
          }
        })
        .catch((e) => alert(e.message));
    }
    if (
      type === BookDetailActionType.FINISH_BOOK ||
      type === BookDetailActionType.WRITE_REVIEW
    ) {
      setSnackbarMsg("이미 등록한 책입니다.");
      setOpenSnackbar(true);
    }
    if (type === BookDetailActionType.UPDATE_REVIEW) {
      setSnackbarMsg("이미 생각을 작성한 책입니다.");
      setOpenSnackbar(true);
    }
  };

  // const {mutate: mutateBooksNextMonthDialog} = useBooksNextMonthDialogSlice()
  const handleBookNextMonth = () => {
    // mutateBooksNextMonthDialog({
    //     isOpen: true
    // })
  };

  const handleClose = () => {
    close();
  };

  //

  const renderBookCards = booksThisMonth.map((it) => (
    <Stack key={v4()} px={"1rem"} pb={"1rem"}>
      <BookCardItem
        book={it}
        onBookClick={handleBookClick}
        onBookmark={handleBookmark}
      />
    </Stack>
  ));

  return (
    <>
      <QihanoDialog
        fullScreen={true}
        open={isOpen}
        TransitionComponent={SlideUpTransition}
      >
        <VerticalScrollableStack
          height={"100%"}
          color={textColorLightBlack}
          bgcolor={"#f9f9f9"}
        >
          <SimpleDialogAppbar onClose={handleClose} />
          <Spacer h={"1rem"} />

          <Stack px={"1rem"}>
            <BooksThisMonthHeader onBookNextMonth={handleBookNextMonth} />
          </Stack>
          <Spacer h={"1rem"} />

          <Stack>{renderBookCards}</Stack>
        </VerticalScrollableStack>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
          message={
            <Typography
              variant={"body2"}
              fontWeight={"bold"}
              children={snackbarMsg}
            />
          }
          sx={{ position: "absolute" }}
        />
      </QihanoDialog>
    </>
  );
};
