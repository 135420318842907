// noinspection HtmlUnknownTarget

import {Button, Divider, Stack, Typography} from "@mui/material";
import {FullSizeImage} from "../../../../../../../../components/abs/base/FullSizeImage";
import {textColorDeepGray, textDisabledColor} from "../../../../../../../../themes/Color";
import Spacer from "../../../../../../../../components/abs/atoms/Spacer";
import {BookItem} from "../../../../../../../../components/context/book/BookItem";
import React from "react";
import BookWithInfoLayout from "../../../../../../../../components/context/book/BookWithInfoLayout";
import {BookEntity} from "../../../../../../../../../domain/entities/book/BookEntity";
import { ReviewEntity } from "../../../../../../../../../domain/entities/review/ReviewEntity";
import plusBook from "../../../../../../../../../icons/icon_plusbook.svg";

const RenderCaseSelected = (
    {book, onChangeBook, oldReview}: {
        book: BookEntity,
        onChangeBook: () => void,
        oldReview?: ReviewEntity
    }
) => {
    const bookItem =
        <BookItem
            width={"16%"}
            imageUrl={book.thumbnailUrl}
        />

    const infoItem =
        <Stack>
            <Typography
                variant={"caption"}
                color={textColorDeepGray}
                children={book.title}
            />

            <Stack direction={"row"}>
                <Typography
                    variant={"caption"}
                    color={textDisabledColor}
                    children={book.authors.join(', ')}
                />
            </Stack>
            <Spacer h={"1rem"}/>
        </Stack>


    return <>
        <Stack>
            <Stack
                direction={"row"}
                width={"100%"}
                padding={"1rem"}
                justifyContent={"space-between"}
            >
                <Stack pl={"4px"} width={"100%"}>
                    <BookWithInfoLayout bookItem={bookItem} infoItem={infoItem}/>
                </Stack>
                {oldReview? <></> : <Button
                    sx={{
                        width: "fit-content",
                        height: "fit-content"
                    }}
                    onClick={onChangeBook}
                >
                    <Typography
                        variant={"caption"}
                        fontWeight={500}
                        children={"변경"}
                    />
                </Button>}
            </Stack>

            <Divider sx={{marginX: "1rem"}}/>
        </Stack>
    </>
}


const RenderCaseNotSelected = (
    {onClick}: { onClick: () => void }
) => {
    return <>
        <Stack>
            <Button
                sx={{
                    paddingX: "1rem",
                    paddingY: 0
                }}
                onClick={onClick}
            >
                <Stack py={"1rem"} direction={"row"} width={"100%"}>
                    <Stack width={"14%"}>
                        <img src={plusBook} alt="add book" width={"70%"}/>
                    </Stack>
                    <Spacer w={"0.3rem"}/>

                    <Typography
                        variant={"caption"}
                        color={textDisabledColor}
                        children={"어떤 책에 대한 생각인가요?"}
                        pt={"0.5rem"}
                        fontSize={"14px"}
                    />
                </Stack>
            </Button>

            <Divider sx={{marginX: "1rem"}}/>
        </Stack>
    </>
}


export const AddReviewTargetBookSection = (
    {book, onAddBook, onChangeBook, oldReview}: {
        book?: BookEntity,
        onAddBook: () => void,
        onChangeBook: () => void,
        oldReview?: ReviewEntity
    }
) => {
    const renderSection =
        !book
            ? <RenderCaseNotSelected onClick={onAddBook}/>
            : <RenderCaseSelected book={book} onChangeBook={onChangeBook} oldReview={oldReview}/>


    return <>
        {renderSection}
    </>
}
