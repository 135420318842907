// import {myInfoMock, userMock3} from "./user/UsersDB";
import {ChatRoom} from "../entities/room/ChatRoom";
import {
    messageMock1,
    messageMock2,
    messageMock3,
    messageMock4,
} from "./message/Message.mock";
// import {bookMock1} from "./book/BookDB";
import { initialBookEntity } from "../entities/book/BookEntity";
import { toStringByFormatting } from "../../utils/datetime/DateTime";


export const chatRoom1: ChatRoom = {
    id: "1",
    usersId: ["w4Ik5LawHtbVBDwcaTwGmoFuIoy1", "GnqnIxtLw0XaFhOKKLEHWkdEfjo1"],
    messages: [
        {
            date: "2021-10-05",
            messagesByTime: [
                {
                    time: "10:10",
                    messagesByUserId: [
                        {
                            userId: 'GnqnIxtLw0XaFhOKKLEHWkdEfjo1',
                            messages: [messageMock1, messageMock2, messageMock3],
                        },
                        {
                            userId: 'w4Ik5LawHtbVBDwcaTwGmoFuIoy1',
                            messages: [messageMock4]
                        }
                    ]
                },
                {
                    time: "11:10",
                    messagesByUserId: [
                        {
                            userId: 'GnqnIxtLw0XaFhOKKLEHWkdEfjo1',
                            messages: [messageMock1]
                        }
                    ]
                }
            ]
        },
        {
            date: "2021-10-06",
            messagesByTime: [
                {
                    time: "11:10",
                    messagesByUserId: [
                        {
                            userId: 'GnqnIxtLw0XaFhOKKLEHWkdEfjo1',
                            messages: [messageMock2]
                        }
                    ]
                },
                {
                    time: "11:15",
                    messagesByUserId: [
                        {
                            userId: 'w4Ik5LawHtbVBDwcaTwGmoFuIoy1',
                            messages: [messageMock3]
                        }
                    ]

                },
                {
                    time: "14:10",
                    messagesByUserId: [
                        {
                            userId: 'GnqnIxtLw0XaFhOKKLEHWkdEfjo1',
                            messages: [messageMock4]
                        },
                        {
                            userId: 'w4Ik5LawHtbVBDwcaTwGmoFuIoy1',
                            messages: [messageMock1]
                        }
                    ]

                }
            ]
        },
        {
            date: "2021-10-07",
            messagesByTime: [
                {
                    time: "14:10",
                    messagesByUserId: [
                        {
                            userId: 'GnqnIxtLw0XaFhOKKLEHWkdEfjo1',
                            messages: [messageMock1]
                        }
                    ]
                },
                {
                    time: "20:10",
                    messagesByUserId: [
                        {
                            userId: 'w4Ik5LawHtbVBDwcaTwGmoFuIoy1',
                            messages: [messageMock1, messageMock2]
                        }
                    ]
                }
            ]
        },
        {
            date: "2021-10.08",
            messagesByTime: [
                {
                    time: "09:10",
                    messagesByUserId: [
                        {
                            userId: 'GnqnIxtLw0XaFhOKKLEHWkdEfjo1',
                            messages: [messageMock3]
                        },
                        {
                            userId: 'w4Ik5LawHtbVBDwcaTwGmoFuIoy1',
                            messages: [messageMock1]
                        }
                    ]
                },
                {
                    time: "10:10",
                    messagesByUserId: [
                        {
                            userId: 'GnqnIxtLw0XaFhOKKLEHWkdEfjo1',
                            messages: [messageMock1]
                        }
                    ]
                }
            ]
        }
    ],
    sharedBook: initialBookEntity,
    regDate: toStringByFormatting(new Date(2021, 10, 5, 10, 10, 10))
}