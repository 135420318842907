import {NAME_REGEX} from "../../domain/policy/name.policy";

export const validatePhoneNumber = (phoneNumber: string) =>
    (/^010 ?([0-9]{3,4}) ?([0-9]{4})$/).test(phoneNumber)


export const validateEmail = (email: string) =>
    (/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/).test(email)


export const validatePassword = (pw: string) =>
    (/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d!@#$%^&*()]{8,20}$/).test(pw)

// qihano dependency
export const validateName = (name: string) =>
    (NAME_REGEX).test(name)