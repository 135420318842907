import {AppBar, IconButton, Toolbar} from "@mui/material";
import {CloseRounded} from "@mui/icons-material";
import React from "react";

export const SimpleDialogAppbar = (
    {onClose}: { onClose: () => void }
) => {
    return <>
        <AppBar position={"relative"} color={"transparent"} elevation={0}>
            <Toolbar sx={{justifyContent: "flex-end"}}>
                <IconButton
                    edge="end"
                    onClick={onClose}
                    children={
                        <CloseRounded/>
                    }
                />
            </Toolbar>
        </AppBar>
    </>
}
