import {Button, Card, InputBase, Stack, Typography} from "@mui/material";
import {VerticalScrollableStack} from "../../../../components/abs/base/Scrollable";
import {textColorGray, textColorLightGray} from "../../../../themes/Color";
import Spacer from "../../../../components/abs/atoms/Spacer";
import React, {ChangeEvent} from "react";
import {createStyles, makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(() => createStyles({
    inputCard: {
        height: "100%",
        borderTopRightRadius: "16px",
        borderTopLeftRadius: "16px"
    },
    inputBase: {
        maxHeight: "100%",
        alignItems: "flex-start",

        fontSize: "14px",
        lineHeight: "22px",
        letterSpacing: "-0.5px",
        color: textColorLightGray
    },
    bottomContainer: {
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0.5rem 1rem",
        backgroundColor: "#fafafa",
        borderTop: "1px solid #eee"
    }
}))

export type AnswerEditInputCardProps = {
    content: string,
    onChangeContent: (e: ChangeEvent<HTMLInputElement>) => void,
    onSubmit: () => void,
    maxLength: number,
    placeholder?: string,
    disableSubmitButton: boolean
}

export const AnswerEditInputCard = (
    {
        content,
        onChangeContent,
        onSubmit,
        maxLength,
        placeholder = "",
        disableSubmitButton
    }: AnswerEditInputCardProps
) => {
    const styles = useStyles()
    // disableSubmitButton = (content === "" || content.length < 10)

    //

    return <>
        <Card elevation={5} className={styles.inputCard}>
            <Stack height={"100%"}>
                <VerticalScrollableStack flex={1} padding={"1rem"}>
                    <InputBase
                        placeholder={placeholder}
                        multiline={true}
                        value={content}
                        onChange={onChangeContent}
                        className={styles.inputBase}
                    />
                </VerticalScrollableStack>

                <Stack className={styles.bottomContainer}>
                    <Stack direction={"row"}>
                        <Typography
                            variant={"caption"}
                            color={textColorGray}
                            children={`${content.length} / ${maxLength}`}
                        />
                    </Stack>
                    <Spacer w={"1rem"}/>

                    <Button
                        size={"small"}
                        variant={"contained"}
                        sx={{borderRadius: "36px"}}
                        disabled={disableSubmitButton}
                        onClick={onSubmit}
                        children={
                            <Typography
                                variant={"caption"}
                                fontSize={"12px"}
                                fontWeight={500}
                                children={"보내기"}
                            />
                        }
                    />
                </Stack>
            </Stack>
        </Card>
    </>
}
