import {ReviewDetailHeader} from "./sections/header/ReviewDetailHeader";
import {ReviewDetailTopSection} from "./sections/top/ReviewDetailTopSection";
import {Button, Stack} from "@mui/material";
import {ReviewBodySection} from "../../../../../../components/context/molecules/ReviewBodySection";
import {ReviewEntity} from "../../../../../../../domain/entities/review/ReviewEntity";
import {VerticalScrollableStack} from "../../../../../../components/abs/base/Scrollable";
import {ReviewDetailActionSection, ReviewDetailActionSectionProps} from "./sections/bottom/ReviewDetailActionSection";
import Spacer from "../../../../../../components/abs/atoms/Spacer";
import {BookEntity} from "../../../../../../../domain/entities/book/BookEntity";
import { useUsersState } from "../../../../../../../redux/features/remote/users/UsersSlice";

export const ReviewDetailTemplate = (
    p: {
        review: ReviewEntity,
        onClose: () => void,
        onBookMark: (book: BookEntity) => void,
        onReadToBook: (book: BookEntity) => void,
        isLiked?: boolean
    } & ReviewDetailActionSectionProps
) => {
    const {review} = p
    const {data:userDB} = useUsersState()
    const writer = userDB.find(user => user.uid === review.writerId)

    const handleBookClick = () => {
        p.onBookMark(review.targetBook)
    }

    const handleOptionClick = () => {
        p.onReadToBook(review.targetBook)
    }

    //

    return (typeof writer !== 'undefined')?<>
        <VerticalScrollableStack height={"100%"}>
            <ReviewDetailHeader
                user={writer}
                onClose={p.onClose}
            />
            <Spacer h={"1rem"}/>

            <Stack px={"1rem"} width={"100%"}>
                <Button
                    disableRipple
                    sx={{
                        width: "auto",
                        p: 0,
                        textAlign: "start"
                    }}
                    onClick={handleBookClick}
                >
                    <ReviewDetailTopSection
                        book={review.targetBook}
                        onOptionClick={handleOptionClick}
                    />
                </Button>
            </Stack>
            <Spacer h={"1.5rem"}/>

            <Stack px={"1rem"} pb={"6rem"}>
                <ReviewBodySection
                    title={review.title}
                    content={review.content}
                    readonly
                />
            </Stack>
        </VerticalScrollableStack>

        <ReviewDetailActionSection {...p}/>
    </> : <></>
}
