export enum Interest {
    GENRE_1 = "소설",
    GENRE_2 = "에세이",
    GENRE_3 = "시",
    GENRE_4 = "자기계발",
    GENRE_5 = "사회과학",
    GENRE_6 = "과학/기술",
    GENRE_7 = "경제/경영",
    GENRE_8 = "인문/심리/철학",
    GENRE_9 = "역사/전기",
    GENRE_10 = "SF/판타지",
    GENRE_11 = "추리",
    GENRE_12 = "재테크/금융",
    GENRE_13 = "로맨스",
    GENRE_14 = "건강/생활",
    GENRE_15 = "국내문학",
    GENRE_16 = "국외문학",
    GENRE_17 = "고전문학",
    GENRE_18 = "예술",
    GENRE_19 = "여행/취미",
    GENRE_20 = "그림책",
    HABIT_1 = "책벌레",
    HABIT_2 = "기록은 꼭",
    HABIT_3 = "베스트 셀러",
    HABIT_4 = "얇은 책",
    HABIT_5 = "두꺼운 책",
    HABIT_6 = "표지에 진심인",
    HABIT_7 = "킬링타임",
    HABIT_8 = "얼리어답터",
    EMOTION_1 = "따뜻한",
    EMOTION_2 = "이성적인",
    EMOTION_3 = "감성적인",
    EMOTION_4 = "트렌디한",
    EMOTION_5 = "자극적인",
    EMOTION_6 = "마이너한",
    EMOTION_7 = "우울한",
    EMOTION_8 = "현실적인",
    ABOUT_1 = "잦은 사색",
    ABOUT_2 = "잡식성",
    ABOUT_3 = "향학열",
    ABOUT_4 = "진지한 대화",
}

// export enum Habit {
//     HABIT_1 = "책벌레",
//     HABIT_2 = "기록은 꼭",
//     HABIT_3 = "베스트 셀러",
//     HABIT_4 = "얇은 책",
//     HABIT_5 = "두꺼운 책",
//     HABIT_6 = "표지에 진심인",
//     HABIT_7 = "킬링타임",
//     HABIT_8 = "얼리어답터",
// }

// export enum Emotion {
//     EMOTION_1 = "따뜻한",
//     EMOTION_2 = "이성적인",
//     EMOTION_3 = "감성적인",
//     EMOTION_4 = "트렌디한",
//     EMOTION_5 = "자극적인",
//     EMOTION_6 = "마이너한",
//     EMOTION_7 = "우울한",
//     EMOTION_8 = "현실적인",
// }

// export enum ThinkingAboutBooks {
//     ABOUT_1 = "잦은 사색",
//     ABOUT_2 = "잡식성",
//     ABOUT_3 = "향학열",
//     ABOUT_4 = "진지한 대화",
// }