import {Button, Chip, Stack, Typography} from "@mui/material";
import {SlideUpTransition} from "../../../../../animations/Transitions";
import React, {useState} from "react";
import {VerticalScrollableStack} from "../../../../../components/abs/base/Scrollable";
import QihanoDialog from "../../../../../components/context/base/QihanoDialog";
import Spacer from "../../../../../components/abs/atoms/Spacer";
import SimpleHeaderSection from "../../../../../components/abs/organisms/SimpleHeaderSection";
import {textColorGray} from "../../../../../themes/Color";
import {v4} from "uuid";
import {Interest} from "../../../../../../domain/types/Interests";
import {MAX_INTEREST_COUNT} from "../../../../../../domain/policy/interests.policy";


export type UpdateInterestsDialogProps = {
    isOpen: boolean,
    setOpen: (isOpen: boolean) => void,
    defaultValue: Array<Interest>,
    onSubmit: (value: Array<Interest>) => void
}

const UpdateInterestsDialog = (
    {isOpen, setOpen, defaultValue, onSubmit}: UpdateInterestsDialogProps
) => {
    const [selectedInterest, setSelectedInterest] = useState<Array<Interest>>(defaultValue)

    const isEmptySelectedChips = selectedInterest.length <= 0

    const isSelectedChip = (interest: Interest) =>
        selectedInterest.some((it) => it === interest)

    const handleClose = () => {
        setOpen(false)
    }

    const handleChipClick = (interest: Interest) => {
        const oldTarget =
            selectedInterest.find((it) => it === interest)

        if (oldTarget) {
            setSelectedInterest(
                selectedInterest.filter((it) => it !== oldTarget)
            )
            return
        }

        if (selectedInterest.length >= MAX_INTEREST_COUNT) {
            return
        }

        setSelectedInterest(
            [...selectedInterest, interest]
        )
    }

    const handleSubmit = () => {
        onSubmit(selectedInterest)
    }

    //

    const renderInterests =
        Object.values(Interest).map((it) =>
            <Chip
                key={v4()}
                clickable
                label={it}
                variant={isSelectedChip(it) ? "filled" : "outlined"}
                color={isSelectedChip(it) ? "secondary" : "default"}
                onClick={() => handleChipClick(it)}
            />
        )


    return (
        <QihanoDialog
            fullScreen={true}
            open={isOpen}
            TransitionComponent={SlideUpTransition}
        >
            <VerticalScrollableStack height={"100%"}>
                <Stack>
                    <SimpleHeaderSection
                        titleTextComponent={"내 책 취향은"}
                        onClose={handleClose}
                    />
                    <Spacer h={"8px"}/>

                    <Stack paddingX={"1rem"}>
                        <Typography
                            variant={"caption"}
                            color={textColorGray}
                            children="회원님의 관심사와 맞는 사람의 글을 소개해드려요"
                        />
                    </Stack>
                </Stack>
                <Spacer h={"34px"}/>


                <Stack justifyContent={"space-between"} height={"100%"} padding={"1rem"}>
                    <Stack>
                        <Typography
                            variant={"caption"}
                            color={textColorGray}
                            fontWeight={500}
                            children="선호하는 키워드를 선택해주세요"
                        />
                        <Spacer h={"12px"}/>

                        <Stack direction={"row"} gap={"0.5rem"} flexWrap={"wrap"}>
                            {renderInterests}
                        </Stack>
                    </Stack>
                    <Spacer h={"2rem"}/>

                    <Button
                        variant="contained"
                        color="primary"
                        size={"large"}
                        fullWidth
                        disabled={isEmptySelectedChips}
                        children={`저장 (${selectedInterest.length}/${MAX_INTEREST_COUNT})`}
                        onClick={handleSubmit}
                    />
                </Stack>
            </VerticalScrollableStack>
        </QihanoDialog>
    )
}

export default UpdateInterestsDialog
