import {Avatar, AvatarProps, Box, CircularProgress, CircularProgressProps} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";

const RootBox = styled(Box)`
  position: relative;
  display: inline-flex;
  width: fit-content;
  height: fit-content;
`

const AvatarWrapper = styled(Box)`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  
`


const AvatarWithCircularProgress = (
    {url, circularProgressProps, avatarProps}: {
        url: string,
        circularProgressProps: CircularProgressProps,
        avatarProps?: AvatarProps
    },
) => {

    return (
        <RootBox>
            <CircularProgress
                size={65}
                thickness={2}
                variant="determinate"
                {...circularProgressProps}
            />

            <AvatarWrapper>
                <Avatar
                    {...avatarProps}
                    alt="profile image"
                    src={url}
                    sx={{width: 56, height: 56}}
                />
            </AvatarWrapper>
        </RootBox>
    )
}

export default AvatarWithCircularProgress
