import {Button, Stack, TextField} from "@mui/material";
import {SlideUpTransition} from "../../../../../animations/Transitions";
import React, {ChangeEvent, useState} from "react";
import {VerticalScrollableStack} from "../../../../../components/abs/base/Scrollable";
import QihanoDialog from "../../../../../components/context/base/QihanoDialog";
import Spacer from "../../../../../components/abs/atoms/Spacer";
import SimpleHeaderSection from "../../../../../components/abs/organisms/SimpleHeaderSection";
import {getByteSizeOfText} from "../../../../../../utils/string/String";
import {NAME_MAX_BYTE_SIZE, NAME_MIN_BYTE_SIZE} from "../../../../../../domain/policy/name.policy";
import {validateName} from "../../../../../../utils/regex/Regex";
import { doubleCheckName } from "../../../../../../redux/features/remote/account/AccountApi";

export type UpdateNicknameDialogProps = {
    isOpen: boolean,
    setOpen: (isOpen: boolean) => void,
    defaultValue: string,
    onSubmitClick: (value: string) => void
}

const UpdateNicknameDialog = (
    {isOpen, setOpen, defaultValue, onSubmitClick}: UpdateNicknameDialogProps
) => {
    const [username, setUsername] = useState(defaultValue)
    const [error, setError] = useState("")

    const handleBack = () => {
        setOpen(false)
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const isValueOverMaxByte = getByteSizeOfText(value) > NAME_MAX_BYTE_SIZE

        if (isValueOverMaxByte) {
            return
        }

        setUsername(value)
    }

    const handleSubmit = () => {
        if (!validateName(username)) {
            setError("이름은 한글 또는 영문으로만 입력해주세요.")
            return
        }

        doubleCheckName(username).then(response => {
            if(!!response) {
              setError(response)
            } else {
                onSubmitClick(username)
            }
          });
    }

    const nameByteSize = getByteSizeOfText(username)
    const isNameUnderMinByte = nameByteSize < NAME_MIN_BYTE_SIZE

    const submitBtnDisable = isNameUnderMinByte

    //

    return (
        <QihanoDialog
            fullScreen={true}
            open={isOpen}
            TransitionComponent={SlideUpTransition}
        >
            <VerticalScrollableStack height={"100%"}>

                <SimpleHeaderSection
                    titleTextComponent={"내 이름은"}
                    onClose={handleBack}
                />

                <Spacer h={"94px"}/>

                <Stack justifyContent={"space-between"} height={"100%"} padding={"1rem"}>

                    <TextField
                        type="text"
                        required
                        variant="standard"
                        autoComplete="off"
                        placeholder="본명 혹은 닉네임을 입력하세요"
                        error={!!error}
                        helperText={error && error}
                        value={username}
                        onChange={handleChange}
                    />

                    <Button
                        size={"large"}
                        variant="contained"
                        fullWidth
                        disabled={submitBtnDisable}
                        children={"저장"}
                        onClick={handleSubmit}/>
                </Stack>

            </VerticalScrollableStack>
        </QihanoDialog>
    )
}

export default UpdateNicknameDialog
