import Gender from "../../types/Gender";
import {Picture} from "../../vo/Picture";
import {Interest} from "../../types/Interests";
import {Address} from "../../vo/Address";
import { Bookmark } from "../../vo/Bookmarks";
import { DailyReview } from "../../vo/DailyReview";
import { AgreedTermEntity } from "../terms/AgreedTermEntity";
import { toStringByFormatting } from "../../../utils/datetime/DateTime";

// User
export type UserEntity = {
    readonly uid: string, //firebase에서 할당해주는 uid
    readonly email: string,
    // readonly phone: string,
    // readonly password: string,
    // readonly role: Role,
    readonly nickname: string,
    readonly birthDate: string, //YYYY-MM-DD(ISO8601)
    readonly gender: Gender,
    readonly address: Address,
    readonly selfIntroduction: string,
    readonly interests: Array<Interest>,
    readonly profileImages: Array<Picture>,
    readonly isFaceAuth: boolean,
    readonly bookmarks: Array<Bookmark>,
    readonly agreedTerms: Array<AgreedTermEntity>,
    readonly updateDate: string,
    readonly dailyReviewsList: Array<DailyReview>,
    readonly blacklist?: string[]
}

export const getUserAge = (birthDate: string) => (new Date().getFullYear()) - (new Date(birthDate).getFullYear()) + 1
export const isMatchedInterests = (interest: Interest, interests: Array<Interest>) => interests.some(it => it === interest)

export const initialUserEntity: UserEntity = {
    uid: "",
    email: "",
    // phone: "",
    // password: "",
    // role: Role.USER,
    nickname: "",
    birthDate: toStringByFormatting(new Date()),
    gender: Gender.MALE,
    address: {
        profile: "",
        latitude: 0,
        longitude: 0
    },
    selfIntroduction: "",
    interests: [],
    profileImages: [],
    isFaceAuth: false,
    bookmarks: [],
    agreedTerms: [],
    updateDate: toStringByFormatting(new Date()),
    dailyReviewsList: []
}

export const removedUserEntity: UserEntity = {
    uid: "",
    email: "",
    // phone: "",
    // password: "",
    // role: Role.USER,
    nickname: "(알 수 없음)",
    birthDate: toStringByFormatting(new Date()),
    gender: Gender.MALE,
    address: {
        profile: "",
        latitude: 0,
        longitude: 0
    },
    selfIntroduction: "",
    interests: [],
    profileImages: [{uuid: "", url: "https://firebasestorage.googleapis.com/v0/b/qihano-e2ada.appspot.com/o/default_profile.png?alt=media&token="}],
    isFaceAuth: false,
    bookmarks: [],
    agreedTerms: [],
    updateDate: toStringByFormatting(new Date()),
    dailyReviewsList: []
}