import {SlideUpTransition} from "../../../../animations/Transitions";
import {textColorDeepGray, textColorGray, textColorLightBlack} from "../../../../themes/Color";
import {VerticalScrollableStack} from "../../../../components/abs/base/Scrollable";
import QihanoDialog from "../../../../components/context/base/QihanoDialog";
import React from "react";
import {SimpleDialogAppbar} from "../../../../components/abs/molecules/SimpleDialogAppbar";
import {Chip, Divider, Stack} from "@mui/material";
import {ProfileDetailHeader} from "./sections/header/ProfileDetailHeader";
import {UserInterestSection} from "../../../../components/abs/organisms/UserInterestSection";
import Spacer from "../../../../components/abs/atoms/Spacer";
import {useProfileDetailDialogSlice} from "../../../../../redux/features/local/dialogs/profile/ProfileDetailDialogSlice";
import {ProfileReviewsSection} from "./sections/reviews/ProfileReviewsSection";
import {BookEntity} from "../../../../../domain/entities/book/BookEntity";
import {ReviewEntity} from "../../../../../domain/entities/review/ReviewEntity";
import {useBookDetailDialogSlice} from "../../../../../redux/features/local/dialogs/book/detail/BookDetailDialogSlice";
import {useReviewDetailDialogSlice} from "../../../../../redux/features/local/dialogs/review/detail/ReviewDetailDialogSlice";
import {ProfileDetailBooksTabsSection} from "./sections/books_tabs/ProfileDetailBooksTabsSection";
import {isMatchedInterests, UserEntity} from "../../../../../domain/entities/user/UserEntity";
import {useMyBooks} from "../../../../../redux/features/remote/books/BookHooks";
import {BookDetailActionType} from "../../book/detail/ButtonActionsCase";
import {v4} from "uuid";
import { SessionPropertyKey } from "../../../../../session/SessionPropertyKey";
import { useReviewsState } from "../../../../../redux/features/remote/reviews/slice/ReviewsSlice";
import { useBooksState } from "../../../../../redux/features/remote/books/BooksSlice";
import { setBookDetailType } from "../../../../../redux/features/remote/books/BookApi";
import { useMyInfo } from "../../../../../redux/features/remote/users/myInfo/MyInfoSlice";

export type ProfileDetailDialogProps = {
    isOpen: boolean,
    user: UserEntity
}


export const useProfileDetailDialog = () => {
    const {data: myInfo} = useMyInfo()

    const {close, data} = useProfileDetailDialogSlice()
    const {isOpen, user} = data

    const {data: reviews} = useReviewsState()
    // const userReviews = reviews.filter(it=>it.writerId === user.uid)

    const {data: books} = useBooksState()
    const {finishedBooks, wishBooks} = useMyBooks(user)

    const {mutate: mutateBookDetailDialog} = useBookDetailDialogSlice()
    const handleBookClick = (book: BookEntity) => {
        const type = setBookDetailType(myInfo, book, reviews)
        mutateBookDetailDialog({isOpen: true, book: book, type: type, uid: myInfo.uid})
    }

    // const {mutate: mutateReviewDetailDialog} = useReviewDetailDialogSlice()
    // const handleReviewClick = (review: ReviewEntity) => {
    //     mutateReviewDetailDialog({
    //         isOpen: true,
    //         review,
    //         isMyReview: false
    //     })
    // }

    const handleClose = () => {
        close()
    }

    return {
        isOpen,
        myInfo,
        user,
        handleClose,
        // userReviews,
        // handleReviewClick,
        wishBooks,
        finishedBooks,
        handleBookClick
    }
}


export const ProfileDetailDialog = () => {
    const {
        isOpen,
        myInfo,
        user,
        handleClose,
        // userReviews,
        // handleReviewClick,
        wishBooks,
        finishedBooks,
        handleBookClick
    } = useProfileDetailDialog()

    const renderInterests =
        user.interests.map(it => {
            const isMatchedInterest = isMatchedInterests(it, myInfo.interests)
            const border = isMatchedInterest ? `1px solid ${textColorDeepGray}` : ""
            const textColor = isMatchedInterest ? textColorDeepGray : textColorGray

            return (
                <Chip
                    key={v4()}
                    label={it}
                    variant={"outlined"}
                    sx={{
                        mb: "6px",
                        mr: "6px",
                        border: border,
                        color: textColor
                    }}
                />
            )
        })

    return <>
        <QihanoDialog
            fullScreen={true}
            open={isOpen}
            TransitionComponent={SlideUpTransition}
        >
            <VerticalScrollableStack height={"100%"} color={textColorLightBlack} bgcolor={"#fcfcfc"}>
                <SimpleDialogAppbar onClose={handleClose}/>

                <Stack>
                    <Stack px={"1rem"}>
                        <ProfileDetailHeader user={user}/>
                    </Stack>
                    <Divider sx={{mx: "1rem", mt: "20px", mb: "24px"}}/>

                    <Stack px={"1rem"}>
                        <UserInterestSection interests={renderInterests}/>
                    </Stack>
                    <Spacer h={"40px"}/>

                    {/* <Stack px={"1rem"}>
                        <ProfileReviewsSection
                            reviews={userReviews}
                            onReviewClick={handleReviewClick}
                        />
                    </Stack>
                    <Spacer h={"40px"}/> */}

                    <Stack>
                        <ProfileDetailBooksTabsSection
                            wishBooks={wishBooks}
                            finishedBooks={finishedBooks}
                            onBookClick={handleBookClick}
                        />
                    </Stack>
                    <Spacer h={"40px"}/>
                </Stack>

            </VerticalScrollableStack>
        </QihanoDialog>
    </>
}
