import QihanoDialog from "../../../../components/context/base/QihanoDialog";
import {SlideUpTransition} from "../../../../animations/Transitions";
import React, {useState} from "react";
import {Snackbar, Stack, Typography} from "@mui/material";
import {WriteReviewData, WriteReviewSection} from "./sub/write_review/WriteReviewSection";
import {QuestionSection} from "./sub/question/QuestionSection";
import {ReviewEntity} from "../../../../../domain/entities/review/ReviewEntity";
import {useReviewEditDialogSlice} from "../../../../../redux/features/local/dialogs/review/edit/ReviewEditDialogSlice";
import {createReview, updateReview} from "../../../../../redux/features/remote/reviews/ReviewsApi";
import {SessionPropertyKey} from "../../../../../session/SessionPropertyKey";
import { initialBookEntity } from "../../../../../domain/entities/book/BookEntity";
import { initialUserEntity } from "../../../../../domain/entities/user/UserEntity";
import { useReviewsState } from "../../../../../redux/features/remote/reviews/slice/ReviewsSlice";

export type ReviewEditDialogProps = {
    isOpen: boolean,
    oldReview?: ReviewEntity
}

const ReviewEditDialog = () => {
    const {close, data} = useReviewEditDialogSlice()
    const {isOpen, oldReview} = data
    const {data: reviewDB, mutate} = useReviewsState()

    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [pageIndex, setPageIndex] = useState(0)

    const [writeReviewSectionData, setWriteReviewSectionData] = useState<WriteReviewData>()

    const handleClose = () => {
        close()
    }

    const handleWriteReviewSubmit = (data: WriteReviewData) => { //1차적으로 쓴 데이터가 data에 저장됨.
        if(data.isPublic){
            setWriteReviewSectionData(data)
            setPageIndex(1)
        }
        else {
            registerReview(data, "")
        }
    }

    const handleQuestionBack = () => {
        setPageIndex(0)
    }

    const handleQuestionSubmit = (question: string) => {
        if (!writeReviewSectionData) {
            alert('다시 시도해주세요.')
            return
        }
        registerReview(writeReviewSectionData, question);
    }

    const registerReview = (data: WriteReviewData, question: string) => {
        const myId = sessionStorage.getItem(SessionPropertyKey.USER_ID) ?? "-1"

        const {book, title, content, isPublic} = data

        if (!oldReview || oldReview.id === "") { //oldReview가 없거나 읽은 책에서 '생각 적기'를 누를 때
            createReview(
                reviewDB,
                book,
                myId,
                title,
                content,
                question,
                isPublic
            ).then(response => {
                if (response) {
                    mutate(response)
                    setPageIndex(0)
                    setOpenSnackbar(true)
                    close()
                }
            }).catch(e => alert(e.message))
        } else {
            updateReview(
                reviewDB,
                oldReview,
                myId,
                title,
                content,
                question,
                isPublic
            ).then(response => {
                if (response) {
                    mutate(response)
                    setPageIndex(0)
                    setOpenSnackbar(true)
                    close()
                }
            }).catch(e => alert(e.message))
        }
    }

    //

    return <>
        <QihanoDialog
            fullScreen={true}
            open={isOpen}
            TransitionComponent={SlideUpTransition}
        >
            <Stack height={"100%"}>
                <Stack
                    height={"100%"}
                    display={(pageIndex === 0) ? "" : "none"}
                >
                    <WriteReviewSection
                        onClose={handleClose}
                        onSubmit={handleWriteReviewSubmit}
                        oldReview={oldReview}
                    />
                </Stack>

                <Stack
                    height={"100%"}
                    display={(pageIndex === 1) ? "" : "none"}
                >
                    <QuestionSection
                        onBack={handleQuestionBack}
                        onSubmit={handleQuestionSubmit}
                        targetBook={writeReviewSectionData?.book ?? initialBookEntity}
                        defaultQuestion={oldReview?.question ?? ""}
                    />
                </Stack>

            </Stack>
        </QihanoDialog>

        <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={() => setOpenSnackbar(false)}
            message={
                <Typography
                    variant={"body2"}
                    fontWeight={"bold"}
                    children={"정상적으로 등록되었습니다"}
                />
            }
            sx={{position: "absolute"}}
        />
    </>
}

export default ReviewEditDialog
