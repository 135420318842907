import {combineReducers, configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import ReviewsSlice from "../features/remote/reviews/slice/ReviewsSlice"
import {persistReducer, persistStore} from "redux-persist";
import DailyReviewSlice from "../features/remote/reviews/daily/DailyReviewsSlice"
import MyPageBooksSlice from "../features/remote/books/MyPageBooksSlice";
import MyInfoSlice from "../features/remote/users/myInfo/MyInfoSlice";
// import TalkRoomSlice from "../features/remote/talk/room/TalkRoomSlice";
import ReactionToSlice from "../features/remote/reaction/ReactionISendSlice";
import ReactionFromSlice from "../features/remote/reaction/ReactionIReceivedSlice";
import SignupSlice from "../features/local/signup/SignupSlice";
import MainBottomNavSlice from "../features/local/main/bottom_nav/MainBottomNavSlice";
// // import HomeTabsSlice from "../features/local/home/tabs/HomeTabsSlice";
import BookDetailDialogSlice from "../features/local/dialogs/book/detail/BookDetailDialogSlice";
import BooksThisMonthDialogSlice from "../features/local/dialogs/book/this_month/BookThisMonthDialogSlice";
// import BooksNextMonthDialogSlice from "../features/local/dialogs/book/next_month/BookNextMonthDialogSlice";
import UpdateProfileDialogSlice from "../features/local/dialogs/profile/ProfileEditDialogSlice";
import ProfileDetailDialogSlice from "../features/local/dialogs/profile/ProfileDetailDialogSlice";
import ReactionsBottomSheetSlice from "../features/local/dialogs/reaction/reaction_bs/ReactionBottomSheetSlice";
import ReviewDetailDialogSlice from "../features/local/dialogs/review/detail/ReviewDetailDialogSlice";
import ReviewEditDialogSlice from "../features/local/dialogs/review/edit/ReviewEditDialogSlice";
import AnswerEditDialogSlice from "../features/local/dialogs/answer/edit/AnswerEditDialogSlice";
import GreetingDialogSlice from "../features/local/dialogs/answer/greeting/GreetingDialogSlice";
import ChatDialogSlice from "../features/local/dialogs/chat/ChatDialogSlice";
import ThinkronizedDialogSlice from "../features/local/dialogs/thinkronized/ThinkronizedDialogSlice";
import NoticeDialogSlice from "../features/local/dialogs/notice/NoticeDialogSlice";
import sessionStorage from "redux-persist/es/storage/session";
import UsersSlice from "../features/remote/users/UsersSlice";
import BooksSlice from "../features/remote/books/BooksSlice";
import ChatRoomsSlice from "../features/remote/chat/ChatRoomsSlice";


//외부 저장소 캐싱 옵션 (객체)
const persistConfig = {
    key: "root",
    storage: sessionStorage,
    whitelist: ["signupState"]
}

//리듀서 병합
export const rootReducer = combineReducers({
    //remote
    users: UsersSlice,
    reviews: ReviewsSlice,
    dailyReviews: DailyReviewSlice,
    myPageBooks: MyPageBooksSlice,
    books: BooksSlice,
    myInfo: MyInfoSlice,
    // talkRoom: TalkRoomSlice,
    reactionISend: ReactionToSlice,
    reactionIReceived: ReactionFromSlice,
    chatRooms: ChatRoomsSlice,
    


    // local
    signupState: SignupSlice,
    mainBottomNavState: MainBottomNavSlice,
    // homeTabsState: HomeTabsSlice,

    bookDetailDialog: BookDetailDialogSlice,
    booksThisMonthDialog: BooksThisMonthDialogSlice,
    // booksNextMonthDialog: BooksNextMonthDialogSlice,
    profileEditDialog: UpdateProfileDialogSlice,
    profileDetailDialog: ProfileDetailDialogSlice,
    reactionBS: ReactionsBottomSheetSlice,
    reviewDetailDialog: ReviewDetailDialogSlice,
    reviewEditDialog: ReviewEditDialogSlice,
    answerEditDialog: AnswerEditDialogSlice,
    greetingDialog: GreetingDialogSlice,
    chatDialog: ChatDialogSlice,
    thinkronizedDialog: ThinkronizedDialogSlice,
    noticeDialog: NoticeDialogSlice,
})

// useSelector 에서 타입 추론을 위해 필요함.
export type RootState = ReturnType<typeof rootReducer>;


// 스토어 생성
const qihanoStore = configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    middleware: getDefaultMiddleware({
        serializableCheck: false
    })
})

export const persistor = persistStore(qihanoStore)

export default qihanoStore
