import {SlideUpTransition} from "../../../../../../animations/Transitions";
import QihanoDialog from "../../../../../../components/context/base/QihanoDialog";
import styled from "@emotion/styled";
import {Button, Card, Fab, Stack, Typography} from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/swiper.scss';
import AspectRatioBox, {RatioType} from "../../../../../../components/abs/molecules/AspectRatioBox";
import {CloseRounded} from "@mui/icons-material";
import {v4} from "uuid";
import {QuestionTypes} from "../../../../../../../domain/types/QuestionTypes";
import {textColorGray} from "../../../../../../themes/Color";
import Spacer from "../../../../../../components/abs/atoms/Spacer";
import {VerticalScrollableStack} from "../../../../../../components/abs/base/Scrollable";

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
  padding: 0 20%;
`

const StyledDialog = styled(QihanoDialog)`
  & .MuiPaper-root {
    background-color: transparent;
  }
`

//

export const QuestionSelectModal = (
    {isOpen, setIsOpen, onContentClick}: {
        isOpen: boolean,
        setIsOpen: (isOpen: boolean) => void
        onContentClick: (content: string) => void
    }
) => {
    const questions = Object.values(QuestionTypes)
    const tempQuestions = questions.splice(0, questions.length - 1)

    //

    const renderSwiperSlide =
        tempQuestions.map(it =>
            <SwiperSlide key={v4()}>
                <Stack height={"100%"} justifyContent={"flex-end"} p={"2px"}>
                    <Card>
                        <Button
                            sx={{
                                width: "100%",
                                height: "100%",
                                padding: 0,
                                textAlign: "start"
                            }}
                            onClick={() => onContentClick(it)}
                        >
                            <AspectRatioBox ratio={RatioType["1/1.3"]}>
                                <Stack height={"100%"} padding={"1rem"} sx={{backgroundColor: "white"}}>
                                    <Typography
                                        variant={"subtitle1"}
                                        color={textColorGray}
                                        fontWeight={"400"}
                                        children={it}
                                    />
                                </Stack>
                            </AspectRatioBox>
                        </Button>
                    </Card>
                </Stack>
            </SwiperSlide>
        )

    return <>
        <StyledDialog
            fullScreen={true}
            open={isOpen}
            TransitionComponent={SlideUpTransition}
        >
            <VerticalScrollableStack width={"100%"} margin={"auto auto"}>
                <Spacer h={"2rem"}/>
                <Stack>
                    <StyledSwiper spaceBetween={25} slidesPerView={"auto"}>
                        {renderSwiperSlide}
                    </StyledSwiper>
                </Stack>

                <Spacer h={"2rem"}/>
                <Stack
                    width={"fit-content"}
                    margin={"0 auto"}
                >
                    <Fab
                        color={"primary"}
                        size={"small"}
                        onClick={() => setIsOpen(false)}
                        children={<CloseRounded/>}
                    />
                </Stack>
                <Spacer h={"1rem"}/>
            </VerticalScrollableStack>
        </StyledDialog>
    </>
}
