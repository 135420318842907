import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {initialSignupFormData, SignupFormData} from "../../../../domain/vo/SignupFormData";
import {RootState} from "../../../store/QihanoStore";


const SignupSlice = createSlice({
    name: 'signupState',
    initialState: initialSignupFormData,
    reducers: {
        //PayloadAction : action의 payload 필드의 타입을 직접 지정할 수 있게 해주는 제네릭
        setSignupState: (state, action: PayloadAction<SignupFormData>) =>
            action.payload,
    }
})

const {setSignupState} = SignupSlice.actions

export const useSignupState = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.signupState, shallowEqual)

    const mutate = (state: SignupFormData) => {
        dispatch(setSignupState(state))
    }


    return {
        mutate,
        data
    }
}

export default SignupSlice.reducer
