import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReactionIReceivedEntity} from "../../../../domain/entities/reaction/ReactionIReceivedEntity";

/**
 * 받은 리액션
 * */
const initialState = null as Array<ReactionIReceivedEntity> | null

const reactionIReceivedSlice = createSlice({
    name: 'reactionIReceived', // action
    initialState: initialState, // default value
    reducers: {
        pullReactionFromList: (state, action: PayloadAction<Array<ReactionIReceivedEntity>>) =>
            action.payload,
    }
})

export const {pullReactionFromList} = reactionIReceivedSlice.actions
export default reactionIReceivedSlice.reducer
