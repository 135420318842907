import {Stack, Typography} from "@mui/material";
import {textColorDeepGray, textDisabledColor} from "../../../../../../../../themes/Color";
import Spacer from "../../../../../../../../components/abs/atoms/Spacer";

export const QuestionGuideSection = () => {
    return <>
        <Stack alignItems={"center"} textAlign={"center"}>
            <Typography
                variant={"subtitle2"}
                color={textColorDeepGray}
                fontWeight={"bold"}
                children={"이 책에 대해 묻고 싶은 질문을 남겨보세요"}
            />
            <Spacer h={"0.5rem"}/>

            <Typography variant={"caption"} color={textDisabledColor}>
                <span>이 책에 대해 의견을 듣고 싶은 부분을 질문해주세요</span><br/>
                <span>다양한 대답이 도착할 거예요</span>
            </Typography>
        </Stack>
    </>
}
