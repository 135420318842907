import { toStringByFormatting } from "../../../utils/datetime/DateTime";
import {BookEntity, initialBookEntity} from "../book/BookEntity";

//독후감
export type ReviewEntity = {
    readonly id: string,
    readonly title: string,
    readonly content: string,
    readonly writerId: string,
    readonly createDate: string,
    readonly modifiedDate: string,
    readonly targetBook: BookEntity,
    readonly question: string,
    readonly isPublic: boolean
}

export const initialReviewEntity: ReviewEntity = {
    id: "",
    title: "",
    content: "",
    writerId: "",
    createDate: toStringByFormatting(new Date()),
    modifiedDate: toStringByFormatting(new Date()),
    targetBook: initialBookEntity,
    question: "",
    isPublic: true,
}

export type PublicReviewEntity = ReviewEntity & { isPublic: boolean };