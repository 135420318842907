import {SlideUpTransition} from "../../../../animations/Transitions";
import QihanoDialog from "../../../../components/context/base/QihanoDialog";
import React, { useState } from "react";
import {VerticalScrollableStack} from "../../../../components/abs/base/Scrollable";
import {QuestionBodyItem} from "../../../../components/context/molecules/QuestionBodyItem";
import {IconButton, Stack, Snackbar, Typography, Toolbar, Button} from "@mui/material";
import {GreetingBottomCard} from "./GreetingBottomCard";
import Spacer from "../../../../components/abs/atoms/Spacer";
import {ArrowBackRounded, CloseRounded} from "@mui/icons-material";
import {AnswerEntity} from "../../../../../domain/entities/message/AnswerEntity";
import {useGreetingDialogSlice} from "../../../../../redux/features/local/dialogs/answer/greeting/GreetingDialogSlice";
import {UserEntity} from "../../../../../domain/entities/user/UserEntity";
import {useProfileDetailDialogSlice} from "../../../../../redux/features/local/dialogs/profile/ProfileDetailDialogSlice";
import { useReviewDetailDialogSlice } from "../../../../../redux/features/local/dialogs/review/detail/ReviewDetailDialogSlice";
import { makeChatRoom } from "../../../../../redux/features/remote/chat/ChatApi";
import { SessionPropertyKey } from "../../../../../session/SessionPropertyKey";
import { useChatRoomsState } from "../../../../../redux/features/remote/chat/ChatRoomsSlice";
import { useChatDialogSlice } from "../../../../../redux/features/local/dialogs/chat/ChatDialogSlice";
import { useUsersState } from "../../../../../redux/features/remote/users/UsersSlice";
import {ReactComponent as MoreIcon} from "../../../../../icons/icon_more.svg";
import BottomSheet from "../../../../components/abs/atoms/BottomSheet";
import { deleteAnswer, reportAnswerUser } from "../../../../../redux/features/remote/answer/AnswerApi";
import { useMyInfo } from "../../../../../redux/features/remote/users/myInfo/MyInfoSlice";

//

export type GreetingDialogProps = {
    isOpen: boolean,
    answer: AnswerEntity,
    disableTitle?: boolean
}

export const GreetingDialog = () => {
    const {close, data} = useGreetingDialogSlice()
    const {answer, isOpen, disableTitle} = data
    const {data: chatRooms, mutate: mutateChatRooms} = useChatRoomsState()
    const {data: userDB} = useUsersState()
    const {data: myInfo} = useMyInfo()

    const myId = sessionStorage.getItem(SessionPropertyKey.USER_ID) ?? "-1"

    const [openBs, setOpenBs] = useState(false)
    const [snackBarMsg, setSnackBarMsg] = useState("")
    const [openSb, setOpenSb] = useState(false)

    const {mutate: mutateProfileDetailDialog} = useProfileDetailDialogSlice()
    const handleProfileClick = (user: UserEntity) => {
        if(userDB.some(it => it.uid === user.uid)){
            mutateProfileDetailDialog({
                isOpen: true,
                user: user
            })
        } else {
            alert("탈퇴한 회원입니다.")
        }
    }

    const handleClose = () => {
        close()
    }

    const handleGreetingSubmit = (greetingMsg: string) => {
        makeChatRoom(myInfo, answer, greetingMsg).then(response => {
            if(response){
                const newChatRoomsState = [...chatRooms, response];
                mutateChatRooms(newChatRoomsState);
                setSnackBarMsg("대화방이 생성되었습니다.");
                setOpenSb(true)
                close()
            }
        })
    }

    const handleOptionClick = () => {
        setOpenBs(true)
    }

    const {mutate: mutateReviewDetailDialog} = useReviewDetailDialogSlice()
    const handleTargetReviewClick = () => {
        setOpenBs(false)
        mutateReviewDetailDialog({
            review: answer.targetReview,
            isMyReview:true,
            isOpen:true,
            isMyOldReview:true
        })
    }

    const handleReport = () => {
        const answerSenderNickname = userDB.find(user => user.uid === answer.senderId)?.nickname
        setOpenBs(false)
        if(window.confirm("신고하시면 답변 내용을 확인한 후 신속하게 조치하겠습니다. 신고할까요?")){
            reportAnswerUser(myId, answer).then(response => {
                if(response){
                    window.open(`https://docs.google.com/forms/d/e/1FAIpQLSeCxo5x_T0HJ2gHRPCKgr2J-sS5W5WNY5wz2sSwK0SAESXylg/viewform?entry.2054425289=${myInfo.email}&entry.1260556263=${myInfo.nickname}&entry.1523641100=${"비매너 신고"}&entry.534926551=${answerSenderNickname}`)
                    deleteAnswer(answer);
                    setSnackBarMsg("답변이 삭제되었습니다.");
                    setOpenSb(true)
                    close()  
                }
            })
        }
    }

    const handleRemoveAnswer = () => {
        setOpenBs(false)
        if(window.confirm("이 답변을 삭제할까요?")){
            deleteAnswer(answer);
            setSnackBarMsg("답변이 삭제되었습니다.");
            setOpenSb(true)
            close()
        }
    }


    //

    const renderContent =
        <Stack
            position={"relative"}
            height={"100%"}
        >
            <Stack>
                <QuestionBodyItem
                    book={answer.targetReview.targetBook}
                    content={answer.targetReview.question}
                    setContent={() => {
                    }}
                    readonly
                    greeting={true}
                />
            </Stack>

            <Stack
                position={"absolute"}
                bottom={0}
                height={"60vh"}
                width={"100%"}
                zIndex={9}
            >
                <GreetingBottomCard
                    answer={answer}
                    onAvatarClick={handleProfileClick}
                    onSubmit={handleGreetingSubmit}
                    disableTitle={disableTitle}
                />
            </Stack>
        </Stack>

    return <>
        <QihanoDialog
            fullScreen={true}
            open={isOpen}
            TransitionComponent={SlideUpTransition}
        >
            <VerticalScrollableStack height={"100%"} bgcolor={"#f2f2f2"}>
            <Toolbar sx={{justifyContent: "space-between"}}>
                    <Stack>
                    <IconButton
                        edge="start"
                        onClick={handleClose}
                        children={
                            <ArrowBackRounded/>
                        }
                    />
                    </Stack>
                    <Stack>
                    <IconButton
                        edge="end"
                        onClick={handleOptionClick}
                        children={
                            <MoreIcon/>
                        }
                    />
                </Stack>
                </Toolbar>
                <Spacer h={"20px"}/>

                {renderContent}
            </VerticalScrollableStack>
        </QihanoDialog>

        <BottomSheet
            anchor={"bottom"}
            open={openBs}
            onClose={() => setOpenBs(false)}
        >
            <Stack padding={"1rem"}>
                <Stack gap={"0.5rem"}>
                    <Button
                        sx={{justifyContent: "flex-start"}}
                        onClick={handleRemoveAnswer}
                        children={"대화의사 없어요"}
                    />
                    <Button
                        sx={{justifyContent: "flex-start"}}
                        onClick={handleTargetReviewClick}
                        children={"당시 내 생각은?"}
                    />
                    <Button
                        sx={{justifyContent: "flex-start"}}
                        onClick={handleReport}
                        children={"신고하기"}
                    />
                </Stack>
            </Stack>
        </BottomSheet>

        <Snackbar
        open={openSb}
        autoHideDuration={3000}
        onClose={() => setOpenSb(false)}
        message={
          <Typography
            variant={"body2"}
            fontWeight={"bold"}
            children={snackBarMsg}
          />
        }
        sx={{ position: "absolute" }}
      />
    </>
}
