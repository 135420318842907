import {Picture} from "./data/Picture";
import {ChangeEvent, useRef} from "react";
import {inputFileToUrl, isFileTypeImage} from "../../../../../utils/file/FileManager";
import {v4} from "uuid";
import Compressor from "compressorjs";


export type ImageFilesState = {
    imageFiles: Array<Picture>,
    setImageFiles: (imageFiles: Array<Picture>) => void
}


export const useImagePicker = (
    {imageFiles, setImageFiles}: ImageFilesState
) => {
    const inputFileRef = useRef<HTMLInputElement>(null)

    const handleInputFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.item(0)
        e.target.value = ""

        if (!file) {
            alert("파일을 하나 이상 선택해주세요.")
            return
        }

        if (!isFileTypeImage(file)) {
            alert("이미지 파일만 업로드 가능 합니다.")
            return
        }

        const onUrlParseSuccess = (url: string) => {
            const newPicture: Picture = {
                uuid: v4(),
                url: url
            }

            setImageFiles([...imageFiles, newPicture])
        }

        new Compressor(file, {
            maxWidth: 1500,
            maxHeight: 1000,
            success(result: File) {
                if (result.size > 5*1024*1024) { // 리사이징 했는데도 용량이 큰 경우
                    alert("파일 용량이 초과되어 업로드가 불가 합니다.");
                    return;
                }
                // console.log(new File([result], result.name, { type: result.type }));
                inputFileToUrl(result, onUrlParseSuccess)
            },
            error(err) {
              console.log(err.message);
            },
          });
        // inputFileToUrl(file, onUrlParseSuccess)
    }


    const handleDeleteClick = (picture: Picture) => {
        setImageFiles(
            imageFiles.filter(it => it.uuid !== picture.uuid)
        )
    }


    return {
        inputFileRef,
        handleInputFileSelect,
        handleDeleteClick,
    }
}
