import {Stack, Typography} from "@mui/material";
import {primaryVariantColor, textDisabledColor} from "../../../../themes/Color";
import Spacer from "../../../../components/abs/atoms/Spacer";

export type NotificationItemProps = {
    title: string,
    dateString: string
    subtitle?: string,
    tag?: string,
}

export const NotificationItem = (
    {tag, title, subtitle, dateString}: NotificationItemProps
) => {

    const renderSubtitle =
        subtitle
            ? <Typography
                variant={"caption"}
                color={textDisabledColor}
                children={subtitle}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
            />
            : <Spacer h={"13px"}/>


    const renderTag =
        tag &&
        <Stack mr={"0.5rem"}>
            <Typography
                variant={"subtitle2"}
                color={primaryVariantColor}
                children={"공지"}
                whiteSpace={"nowrap"}
            />
        </Stack>

    return <>
        <Stack width={"100%"}>
            <Stack direction={"row"}>
                {renderTag}

                <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
                    <Typography
                        variant={"subtitle2"}
                        children={title}
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                    />
                    <Spacer w={"0.5rem"}/>

                    <Typography
                        variant={"caption"}
                        fontSize={"12px"}
                        color={textDisabledColor}
                        children={dateString}
                        whiteSpace={"nowrap"}
                    />
                </Stack>
            </Stack>
            <Stack width={"100%"} textAlign={"start"}>
                {renderSubtitle}
            </Stack>
        </Stack>
    </>
}
