import {Fab, Stack} from "@mui/material";
import {ChatBubbleOutlineRounded, FavoriteBorderRounded, FavoriteRounded} from "@mui/icons-material";
import styled from "@emotion/styled";

const Root = styled(Stack)`
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 50%);

  position: absolute;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 2rem;
`

export type ReviewDetailActionSectionProps = {
    onReaction: () => void,
    onAnswer: () => void
}

export const ReviewDetailActionSection = (
    p: {isLiked?: boolean
    } & ReviewDetailActionSectionProps
) => {
    return <>
        <Root bottom={1} gap={"5%"}>
            <Fab
                color={"primary"}
                size={"medium"}
                children={p.isLiked?<FavoriteRounded/>:<FavoriteBorderRounded/>}
                onClick={p.onReaction}
            />
            <Fab
                color={"primary"}
                size={"medium"}
                children={<ChatBubbleOutlineRounded/>}
                onClick={p.onAnswer}
            />
        </Root>
    </>
}
