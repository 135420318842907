import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../store/QihanoStore";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GreetingDialogProps} from "../../../../../../ui/dialogs/childs/answer/greeting/GreetingDialog";
import {initialAnswerEntity} from "../../../../../../domain/entities/message/AnswerEntity";


const initialState: GreetingDialogProps = {
    isOpen: false,
    answer: initialAnswerEntity
}

const GreetingDialogSlice = createSlice({
    name: 'greetingDialog',
    initialState: initialState,
    reducers: {
        setGreetingDialogState: (state, action: PayloadAction<GreetingDialogProps>) =>
            action.payload,
    }
})

export const useGreetingDialogSlice = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.greetingDialog)

    const mutate = (state: GreetingDialogProps) => {
        dispatch(setGreetingDialogState(state))
    }

    const close = () => {
        mutate({
            ...data,
            isOpen: false
        })
    }


    return {
        close,
        mutate,
        data
    }
}

export const {setGreetingDialogState} = GreetingDialogSlice.actions
export default GreetingDialogSlice.reducer
