import { UserEntity } from "./../../../../domain/entities/user/UserEntity";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { BookEntity } from "../../../../domain/entities/book/BookEntity";
import { db } from "../../../../firebase-config";
import {
  ReviewEntity,
  initialReviewEntity,
} from "./../../../../domain/entities/review/ReviewEntity";
import { Address } from "../../../../domain/vo/Address";
import { rand } from "../../../../utils/etc";
import { toStringByFormatting } from "../../../../utils/datetime/DateTime";
import _ from "lodash";
// import {now} from "lodash";

export const fetchReviewsDB = async () => {
  const reviews: Array<ReviewEntity> = [];
  const querySnapshot = await getDocs(collection(db, "reviews"));
  // console.log("get from fetchReviewsDB")

  querySnapshot.forEach((doc) => {
    // console.log(doc.id, " => ", doc.data());
    let reviewData: ReviewEntity = initialReviewEntity;
    reviewData = JSON.parse(JSON.stringify(doc.data()));
    reviews.push(reviewData);
  });

  return reviews;
};


export const createReview = async (
  reviewDB: Array<ReviewEntity>,
  book: BookEntity,
  userId: string,
  title: string,
  content: string,
  question: string,
  isPublic: boolean
) => {
  const publicReviewsCount = reviewDB.filter(
    (it) => it.writerId === userId && it.isPublic === true
  ).length;

  if (isPublic && publicReviewsCount >= 3) {
    alert(
      "공개 생각은 총 3개만 가능합니다. 우선 비공개로 등록 후 조정해주세요."
    );
    return false;
  }

  const targetBook = book;
  const reviewId = userId + book.bookId;

  const review: ReviewEntity = {
    id: reviewId,
    title: title,
    content: content,
    writerId: userId,
    createDate: toStringByFormatting(new Date()),
    modifiedDate: toStringByFormatting(new Date()),
    targetBook: targetBook,
    question: question,
    isPublic: isPublic,
  };

  const newReviewDB = [...reviewDB, review];
  await setDoc(doc(db, "reviews", reviewId), review);

  return newReviewDB;
};

export const updateReview = async (
  reviewDB: Array<ReviewEntity>,
  oldReview: ReviewEntity,
  userId: string,
  title: string,
  content: string,
  question: string,
  isPublic: boolean
) => {
  const publicReviewsCount = reviewDB.filter(
    (it) => it.writerId === userId && it.isPublic === true
  ).length;
  const isRegisteredReview = reviewDB.some((it) => it.id === oldReview.id);

  if (!isRegisteredReview && isPublic && publicReviewsCount >= 3) {
    alert(
      "공개 생각은 총 3개만 가능합니다. 우선 비공개로 등록 후 조정해주세요."
    );
    return false;
  }

  const newReview = {
    ...oldReview,
    title: title,
    content: content,
    modifiedDate: toStringByFormatting(new Date()),
    question: question,
    isPublic: isPublic,
  };

  const newReviewDB = [
    ...reviewDB.filter((it) => it.id !== oldReview.id),
    newReview,
  ];
  await updateDoc(doc(db, "reviews", oldReview.id), newReview);

  return newReviewDB;
};

export const deleteReview = async (
  reviewDB: Array<ReviewEntity>,
  reviewId: string
) => {
  const newReviewDB = reviewDB.filter((it) => it.id !== reviewId);
  await deleteDoc(doc(db, "reviews", reviewId));

  return newReviewDB;
};
