import {IconButton, IconButtonProps, InputBase, Stack} from "@mui/material";
import {textColorGray} from "../../../themes/Color";
import Spacer from "../atoms/Spacer";
import React from "react";
import styled from "@emotion/styled";
import {InputBaseProps} from "@mui/material/InputBase/InputBase";
import {ReactComponent as SendIcon} from "../../../../icons/icon_send.svg";
import {primaryVariantColor} from "../../../../ui/themes/Color"

const Root = styled(Stack)`
  border-radius: 36px;
  background-color: #ececec;
  padding: 0 0 0 16px;

  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`

const StyledInputBase = styled(InputBase)`
  width: 100%;
  color: ${textColorGray};
  font-size: 13px;
  letter-spacing: -0.65px;
`

//

export type MessageInputBoxProps = {
    inputBaseProps: InputBaseProps,
    iconButtonProps: IconButtonProps
}

export const MessageInputBox = (
    {inputBaseProps, iconButtonProps}: &MessageInputBoxProps
) => {
    return <>
        <Root>
            <StyledInputBase {...inputBaseProps}/>
            <Spacer w={"0.5rem"}/>

            <IconButton
                {...iconButtonProps}
                children={
                    <SendIcon fill={iconButtonProps.disabled?"#999":primaryVariantColor} />
                }
            />
        </Root>
    </>
}
