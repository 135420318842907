import {AppBar, Avatar, IconButton, Stack, Toolbar, Typography} from "@mui/material";
import {CloseRounded} from "@mui/icons-material";
import React from "react";
import {textColorDeepGray} from "../../../../../../../../themes/Color";
import Spacer from "../../../../../../../../components/abs/atoms/Spacer";
import {UserEntity} from "../../../../../../../../../domain/entities/user/UserEntity";

const AvatarWithUserName = (
    {user}: { user: UserEntity }
) => {
    return <>
        <Stack direction={"row"}>
            <Avatar
                src={user.profileImages[0].url}
                sx={{width: "24px", height: "24px"}}
            />
            <Spacer w={"0.5rem"}/>

            <Typography
                variant={"caption"}
                color={textColorDeepGray}
                fontWeight={500}
                children={user.nickname}
            />
        </Stack>
    </>
}


const Left = (
    {user, onClose}: {
        user: UserEntity,
        onClose: () => void,
    }
) => {
    return <>
        <Stack direction={"row"} alignItems={"center"}>
            <IconButton
                edge="start"
                onClick={onClose}
                children={
                    <CloseRounded/>
                }
            />
            <Spacer w={"0.2rem"}/>

            <AvatarWithUserName user={user}/>
        </Stack>
    </>
}


export const ReviewDetailHeader = (
    p: {
        user: UserEntity,
        onClose: () => void,
    }
) => {

    return <>
        <AppBar position={"relative"} color={"transparent"} elevation={0}>
            <Toolbar sx={{justifyContent: "space-between"}}>
                <Left {...p}/>
            </Toolbar>
        </AppBar>
    </>
}
    