import React, { useEffect } from "react";
import { Button, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { primaryVariantColor, textColorLightBlack } from "../../../themes/Color";
import Spacer from "../../../components/abs/atoms/Spacer";
import { FullSizeImage } from "../../../components/abs/base/FullSizeImage";
import { useHistory } from "react-router";
import GeneralPath from "../../../../router/RouterPath";
import { KakaoLoginButton } from "../../../components/context/atoms/SocialLoginButton";
import { SessionPropertyKey } from "../../../../session/SessionPropertyKey";
import { auth } from "../../../../firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import { checkResignup } from "../../../../redux/features/remote/account/AccountApi";
import { REDIRECT_URI } from "../../../../redux/features/remote/account/KakaoApi";
import { urlToDataUrl } from "../../../../utils/file/FileManager";


const Root = styled(Stack)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  padding: 48px 1rem 20px 1rem;

  background-color: #f9f9f9;
`;


const MainImage = () => (
  // <AspectRatioBox ratio={RatioType["1/1"]}>
    <FullSizeImage src="/assets/img/snobs_main_image.png" alt={"main image"} />
  // </AspectRatioBox>
);

//

const SocialLoginPage = () => {
  const history = useHistory();

  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;

  const handleKakaoLogin = () => {
    sessionStorage.clear();
    onAuthStateChanged(auth, (user) => {
      if (user && user.email) {
        //파이어베이스로 로그인되어있는 경우
        checkResignup(user.email).then(response=>{
          if(response){
            sessionStorage.setItem(SessionPropertyKey.USER_ID, user.uid);
            window.location.replace(GeneralPath.MAIN);
          }
        })
      } else {
        window.location.href =KAKAO_AUTH_URL;
      }
    });
  };

  const handleOtherLogin = () => {
    //다른 방법으로 시작하기 클릭 시
    // history.push(AuthPath.LOGIN);
    // alert("준비중입니다.")
    window.open("https://www.mhsj.kr/qihano/guide")
  };

  return (
    <>
      <Root color={textColorLightBlack}>
        <Stack>
        <img src={"/assets/img/qihano_logo.png"} alt={"logo"} width={"15%"} />
        <Spacer h={"133px"} />
          <Typography variant={"h4"}>
    <span>읽은 책이 같으면</span><br/>
    <span style={{color: primaryVariantColor}}>대화</span><span>도</span><br/>
    <span>재밌어지니까</span>
  </Typography>
        </Stack>
        <Stack height={"100%"} justifyContent={"flex-end"}>
          <Stack>
            <KakaoLoginButton
              model={{
                icon: <img src="/assets/icons/logo-kakao.svg" alt="kakao" />,
                text: "카카오로 계속하기",
              }}
              onClick={handleKakaoLogin}
              variant={"contained"}
            />

            <Spacer h={"0.5rem"} />
            <Button
              variant={"contained"}
              size={"large"}
              children={"키하노 이용 가이드"}
              onClick={handleOtherLogin}
            />
          </Stack>
        </Stack>
      </Root>
    </>
  );
};

export default SocialLoginPage;
