// noinspection UnnecessaryLocalVariableJS

/**
 * 현재 디바이스 날짜 시간 가져오기
 * */
 export const getCurrentDateTime = (): Date => {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()

    return new Date(year, month, day, hours, minutes, seconds)
}

/**
 * 두 날짜 간의 일 차 (일[Day] 단위 기준)
 **/
export const computeDiffDay = (startDate: Date, endDate: Date): number => {
    const diffTimeMilli = Math.abs(startDate.getTime() - endDate.getTime())
    const forDay = Math.floor(diffTimeMilli / (1000 * 3600 * 24))

    return forDay
}

/**
 * 두 날짜 간의 시간차 (시[Hour] 단위 기준)
 **/
export const getDiffHour = (startTime: Date, endTime: Date): number => {
    const isNegative = startTime > endTime
    const diffTimeMilli = Math.abs(startTime.getTime() - endTime.getTime())
    const for24Hour = Math.floor(diffTimeMilli / (1000 * 3600))
    const result = isNegative ? -for24Hour : for24Hour

    return result
}

// 24시간 기준, 전달 값이 몇 퍼센트인지 계산
export const computeWhatPercentageOf24Hours = (value: number) => (value / 24) * 100


// YYYY. MM. DD. 형식
export const displayDate = (date: string) => (new Date(date).getFullYear()) + '. ' + (new Date(date).getMonth()+1) + '. ' + (new Date(date).getDate())

// YYYY-MM-DD HH:MM:SS 형식
export const toStringByFormatting = (date: Date) => {
    const year = date.getFullYear()
    const month = (date.getMonth()+1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')

    return year+'-'+month+'-'+day+' '+hours+':'+minutes+':'+seconds
}
