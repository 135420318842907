import {useDispatch, useSelector} from "react-redux";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ChatDialogProps} from "../../../../../ui/dialogs/childs/chat/ChatDialog";
import {RootState} from "../../../../store/QihanoStore";
import { chatRoom1 } from "../../../../../domain/mocks/ChatRoom.mock";


const initialState: ChatDialogProps = {
    isOpen: false,
    chatRoom: chatRoom1 //mock data
}

const ChatDialogSlice = createSlice({
    name: 'chatDialog',
    initialState: initialState,
    reducers: {
        setChatDialogState: (state, action: PayloadAction<ChatDialogProps>) =>
            action.payload,
    }
})

export const useChatDialogSlice = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.chatDialog)

    const mutate = (state: ChatDialogProps) => {
        dispatch(setChatDialogState(state))
    }

    const close = () => {
        mutate({
            ...data,
            isOpen: false
        })
    }


    return {
        close,
        mutate,
        data
    }
}

export const {setChatDialogState} = ChatDialogSlice.actions
export default ChatDialogSlice.reducer
