import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import GeneralPath, {
  AuthPath,
  SignupPath,
} from "../../../../router/RouterPath";
import { VerticalScrollableStack } from "../../../components/abs/base/Scrollable";
import { useSignupState } from "../../../../redux/features/local/signup/SignupSlice";
import { textColorGray } from "../../../themes/Color";
import { Picture } from "../../../components/context/organisms/image_picker/data/Picture";
import { v4 as generateUniqueKey } from "uuid";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "../../../../firebase-config";
import { SessionPropertyKey } from "../../../../session/SessionPropertyKey";
import { initialSignupFormData } from "../../../../domain/vo/SignupFormData";
import { checkResignup } from "../../../../redux/features/remote/account/AccountApi";

const LoadingPage = () => {
  const { data, mutate } = useSignupState();

  const getProfile = async () => {
    try {
      // Kakao SDK API를 이용해 사용자 정보 획득
      let _data = await window.Kakao.API.request({
        url: "/v2/user/me",
      });

      if (_data) {
        signInWithEmailAndPassword(
          auth,
          _data.kakao_account.email,
          _data.id.toString()
        )
          .then((userCredential) => {
            checkResignup(_data.email).then((response) => {
              if (response) {
                //로그인 또는 재가입
                sessionStorage.setItem(
                  SessionPropertyKey.USER_ID,
                  userCredential.user.uid
                );
                window.location.replace(GeneralPath.MAIN);
              } else {
                //재가입 X
                signOut(auth)
                  .then(() => {
                    window.location.href = AuthPath.SOCIAL;
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            });
          })
          .catch(() => {
            //회원가입
            mutate(initialSignupFormData);
            const newSignupState = { ...data };
            newSignupState.kakaoId = _data.id;
            newSignupState.email = _data.kakao_account.email;
            newSignupState.username = _data.properties.nickname;

            // if(_data.properties.profile_image){
            //   const profileImageUrlHttp: string = _data.properties.profile_image;
            //   const profileImageUrlHttps = profileImageUrlHttp.replace("http", "https");
            //   const newPicture: Picture = {
            //     uuid: generateUniqueKey(),
            //     url: profileImageUrlHttps,
            //   };
            //   newSignupState.pictures = [...newSignupState.pictures, newPicture];
            // }

            mutate(newSignupState);
            window.location.replace(SignupPath.TERMS);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        //로그인되어있는 경우
        sessionStorage.setItem(SessionPropertyKey.USER_ID, user.uid);
        window.location.replace(GeneralPath.MAIN);
      } else {
        getProfile();
      }
    });
  }, []);

  //

  return (
    <>
      <VerticalScrollableStack height={"100%"}>
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          height={"100%"}
          color={"white"}
        >
          <CircularProgress disableShrink />
          <Typography variant={"caption"} color={textColorGray}>
            <span>정보를 가져오는 중입니다...</span>
          </Typography>
        </Stack>
      </VerticalScrollableStack>
    </>
  );
};

export default LoadingPage;
