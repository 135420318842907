import React, {ReactNode} from "react";
import {Stack} from "@mui/material";
import ImagePickerItem, {ImageAddItem} from "./ImagePickerItem";
import {v4} from "uuid";
import {ImageFilesState, useImagePicker} from "./ImagePickerHook";
import {AddRounded} from "@mui/icons-material";


const TripleImagePicker = (
    p: {} & ImageFilesState) => {
    const {
        inputFileRef,
        handleInputFileSelect,
        handleDeleteClick,
    } = useImagePicker(p)

    //

    const renderImageFile =
        p.imageFiles.map(it =>
            <ImagePickerItem
                key={v4()}
                picture={it}
                onClick={() => {
                }}
                onDeleteClick={() => handleDeleteClick(it)}
            />
        )

    const imageFileIndexes = [1, 2, 3]
    const reversedImageFileIndexes = [...imageFileIndexes].reverse()

    let imageAddItems: ReactNode[] = []

    const renderImageAddItems = (fileIndex: number) =>
        Array.from(
            {
                length: reversedImageFileIndexes[fileIndex - 1]
            },
            () =>
                <ImageAddItem
                    key={v4()}
                    contentItem={
                        <AddRounded color={"secondary"} fontSize={"large"}/>
                    }
                    onClick={() => inputFileRef.current?.click()}
                />
        )

    imageFileIndexes.some(fileIndex => {
        if (p.imageFiles.length < fileIndex) {
            imageAddItems = renderImageAddItems(fileIndex)
            return true
        } else {
            return false
        }
    })

    return <>
        <Stack width={"100%"}>
            <Stack direction={"row"}>
                {renderImageFile}
                {imageAddItems}
            </Stack>

            <input
                type={"file"}
                ref={inputFileRef}
                hidden
                onChange={handleInputFileSelect}
            />

        </Stack>
    </>
}

export default TripleImagePicker
