import { initialBookEntity } from './../entities/book/BookEntity';
import { Picture } from './Picture';
import Gender from "../types/Gender";
import {AgreedTermEntity} from "../entities/terms/AgreedTermEntity";
import {Interest} from "../types/Interests";
import {Address} from './Address';
import { Bookmark } from './Bookmarks';

export type SignupFormData = {
    kakaoId: number,
    email: string,
    // isValidEmail: boolean,
    // pw: string,
    // pwConfirm: string,

    // phone: string,
    // isValidPhone: boolean,
    agreedTerms: Array<AgreedTermEntity>,
    birthDay: string,

    username: string,
    gender: Gender | null,
    isAgreedLocationService: boolean,
    address: Address | null,
    pictures: Array<Picture>,

    interests: Array<Interest>,
    representBooks: Array<Bookmark>,
}

export const initialSignupFormData: SignupFormData = {
    kakaoId: 0,
    email: "",
    // isValidEmail: false,
    // pw: "",
    // pwConfirm: "",

    // phone: "",
    // isValidPhone: false,
    agreedTerms: [],
    birthDay: "",

    username: "",
    gender: null,
    isAgreedLocationService: false,
    address: null,
    pictures: [],

    interests: [],
    representBooks: []
}