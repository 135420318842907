import {
  UserEntity,
  initialUserEntity,
} from "./../../../../domain/entities/user/UserEntity";
import { database, db, storage } from "./../../../../firebase-config";
import { SignupFormData } from "../../../../domain/vo/SignupFormData";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  BookEntity,
  initialBookEntity,
} from "../../../../domain/entities/book/BookEntity";
import { Picture } from "../../../../ui/components/context/organisms/image_picker/data/Picture";
import { ref, uploadString } from "firebase/storage";
import { urlToDataUrl } from "../../../../utils/file/FileManager";
import { Bookmark } from "../../../../domain/vo/Bookmarks";
import { remove, ref as refDB, get, child, push, update } from "firebase/database";
import { toStringByFormatting } from "../../../../utils/datetime/DateTime";
import { ChatRoom } from "../../../../domain/entities/room/ChatRoom";
import { FaceAuthEntity } from "../../../../domain/entities/admin/FaceAuthEntity";
import { NotificationEntity } from "../../../../domain/entities/notice/NotificationEntity";

export const doubleCheckName = async (username: string) => {
  const q = query(collection(db, "users"), where("nickname", "==", username));
  const querySnapshot = await getDocs(q);
  // console.log("get from doubleCheckName")

  if (querySnapshot.empty === false) {
    return "이미 등록된 이름입니다.";
  } else {
    return false;
  }
};

export const SignupApi = async (
  uid: string,
  data: SignupFormData
): Promise<boolean> => {
  const pictures = data.pictures.map((it, index) => {
    return {
      ...it,
      url:
        "https://firebasestorage.googleapis.com/v0/b/qihano-e2ada.appspot.com/o/profiles%2F" +
        uid +
        it.uuid +
        "?alt=media&token=",
    };
  });

  const userData = {
    uid: uid,
    email: data.email,
    nickname: data.username,
    birthDate: data.birthDay,
    gender: data.gender,
    address: data.address,
    interests: data.interests,
    profileImages: pictures,
    selfIntroduction: "",
    isFaceAuth: false,
    bookmarks: data.representBooks,
    dailyReviewsList: [],
    agreedTerms: data.agreedTerms,
    updateDate: toStringByFormatting(new Date()),
  };

  await setDoc(doc(db, "users", uid), userData);
  const response = true;
  return response;
};

export const registerBooksApi = async (
  data: Array<Bookmark>
): Promise<boolean> => {
  if (data === []) return true;

  const bookDB: Array<BookEntity> = [];
  const querySnapshot = await getDocs(collection(db, "books"));
  // console.log("get from registerBookApi")

  querySnapshot.forEach((doc) => {
    let bookData: BookEntity = initialBookEntity;
    bookData = JSON.parse(JSON.stringify(doc.data()));
    bookDB.push(bookData);
  });

  for (const bookmark of data) {
    if (!bookDB.some((book) => book.bookId === bookmark.book.bookId)) {
      const booksRef = doc(db, "books", bookmark.book.bookId);
      await setDoc(booksRef, bookmark.book);
    }
  }

  return true;
};

export const PictureApi = async (
  uid: string,
  pictures: Array<Picture>
): Promise<boolean> => {
  const picturesUrl = pictures.map((it) => {
    return it.url;
  });

  let i: number;
  for (i = 0; i < pictures.length; i++) {
    const storageRef = ref(storage, "profiles/" + uid + pictures[i].uuid); //파일명 : 유저id+uuid
    if (picturesUrl[i].includes("base64")) {
      //직접 올린 이미지파일
      uploadString(storageRef, picturesUrl[i], "data_url").then((snapshot) => {
        // console.log("Uploaded a data_url string!");
      });
    } else {
      picturesUrl[i] =
        "https://firebasestorage.googleapis.com/v0/b/qihano-e2ada.appspot.com/o/default_profile.png?alt=media&token=";
    }
  }

  if (i >= picturesUrl.length) {
    const response = true;
    return response;
  } else {
    const response = false;
    return response;
  }
};

export const unregisterApi = async (myInfo: UserEntity, reason: string) => {
  if (myInfo.uid === "") {
    //main을 거치지 않고 왔을 때
    alert("알 수 없는 오류입니다.");
    return false;
  }

  //유저 문서 updateDate 필드 갱신 후 컬렉션 이동 (users -> unregister_users)
  const unregisterDate = toStringByFormatting(new Date());
  const newMyInfo: UserEntity = { ...myInfo, updateDate: unregisterDate };
  await setDoc(doc(db, "unregister_users", unregisterDate), newMyInfo);
  await deleteDoc(doc(db, "users", myInfo.uid));

  //탈퇴이유 DB에 등록
  const dateAuthDelete = new Date(new Date().setDate(new Date().getDate() + 7)); //영구삭제 날짜
  await setDoc(doc(db, "unregister_reason", unregisterDate), {
    email: myInfo.email,
    reason: reason,
    dateAuthDelete: dateAuthDelete,
  });

  //리뷰 모두 비공개 처리
  const queryReviews = query(
    collection(db, "reviews"),
    where("writerId", "==", myInfo.uid)
  );
  const queryReviewSnapshot = await getDocs(queryReviews);
  queryReviewSnapshot.forEach(async (document) => {
    const reviewData = JSON.parse(JSON.stringify(document.data()));
    if (reviewData.isPublic === true) {
      reviewData.isPublic = false;
      await setDoc(doc(db, "reviews", reviewData.id), reviewData);
    }
  });

  //리액션 삭제
  const queryReactions = query(
    collection(db, "reactions"),
    where("senderId", "==", myInfo.uid)
  );
  const queryReactionSnapshot = await getDocs(queryReactions);
  queryReactionSnapshot.forEach(async (document) => {
    const reactionData = JSON.parse(JSON.stringify(document.data()));
    await deleteDoc(doc(db, "reactions", reactionData.id));
  });

  //realtime db 삭제 (answer, thinkronized, chatRooms)
  remove(refDB(database, myInfo.uid));
  get(child(refDB(database), "chatRooms"))
    .then((snapshot) => {
      // console.log("get realtime DB - chatRooms");
      if (snapshot.exists()) {
        const allChatRoomIds: string[] = Object.keys(snapshot.val());
        const myChatRoomIds: string[] = allChatRoomIds.filter((it) =>
          it.includes(myInfo.uid)
        );
        for (let i = 0; i < myChatRoomIds.length; i++) {
          remove(refDB(database, "chatRooms/" + myChatRoomIds[i]));
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });

  return true;
};

export const checkResignup = async (email: string) => {
  const unregisterUsers: Array<UserEntity> = [];
  const querySnapshot = await getDocs(collection(db, "unregister_users"));
  // console.log("get from checkResignup")

  querySnapshot.forEach((doc) => {
    let userData: UserEntity = initialUserEntity;
    userData = JSON.parse(JSON.stringify(doc.data()));
    unregisterUsers.push(userData);
  });

  const index = unregisterUsers.findIndex((user) => user.email === email);
  if (index !== -1) {
    //재가입해야 할 경우
    if (
      window.confirm(
        `${unregisterUsers[index].updateDate.slice(0, 4)}년 ${unregisterUsers[
          index
        ].updateDate.slice(5, 7)}월 ${unregisterUsers[index].updateDate.slice(
          8,
          10
        )}일에 탈퇴한 계정입니다. 다시 가입할까요?`
      )
    ) {
      await setDoc(
        doc(db, "users", unregisterUsers[index].uid),
        unregisterUsers[index]
      );
      await deleteDoc(
        doc(db, "unregister_users", unregisterUsers[index].updateDate)
      );
      return true;
    } else {
      //재가입 거절
      return false;
    }
  }
  return true; //로그인
};

export const checkFaceAuth = async (userId: string) => {
  const q = query(collection(db, "admin"), where("type", "==", "faceAuth"));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const data = JSON.parse(JSON.stringify(doc.data()));
    if (data.uid === userId) return false; //DB에 있음, 심사중
  });

  return true;
};


export const applyFaceAuth = async (myInfo: UserEntity, picture: Picture) => {
  const regDate = toStringByFormatting(new Date());
  await setDoc(doc(db, "admin", "faceAuth_" + regDate), {
    date: regDate,
    type: "faceAuth",
    picture: picture,
    uid: myInfo.uid,
    gender: myInfo.gender,
    nickname: myInfo.nickname,
  });

  return true;
};

export const fetchFaceAuth = async () => {
  const faceAuths: Array<FaceAuthEntity> = [];
  const q = query(collection(db, "admin"), where("type", "==", "faceAuth"));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const data:FaceAuthEntity = JSON.parse(JSON.stringify(doc.data()));
    faceAuths.push(data)
  });

  return faceAuths;
}


export const decideFaceAuth = async (faceAuth: FaceAuthEntity, isApprove: boolean) => {
  await deleteDoc(
    doc(db, "admin", "faceAuth_"+faceAuth.date)
  );

  if(isApprove){
    await updateDoc(doc(db, "users", faceAuth.uid), {
      isFaceAuth: true
    })

    const newKey =
    push(child(refDB(database), faceAuth.uid + "/notifications")).key ?? "-1";

    const notificationData: NotificationEntity = {
      id: newKey,
      type: "faceAuth",
      title: `얼굴인증이 승인되었어요.`,
      subTitle: "이제 다른 사람의 프로필도 자유롭게 확인하세요!",
      date: toStringByFormatting(new Date())
    }
    const updates = {};
    updates[faceAuth.uid + "/notifications/" + newKey] = notificationData;
    update(refDB(database), updates);

    return true;
  }
  else {
    const newKey =
    push(child(refDB(database), faceAuth.uid + "/notifications")).key ?? "-1";

    const notificationData: NotificationEntity = {
      id: newKey,
      type: "faceAuth",
      title: `얼굴인증이 반려되었어요.`,
      subTitle: "회원님 얼굴로 재신청해주세요.",
      date: toStringByFormatting(new Date())
    }
    const updates = {};
    updates[faceAuth.uid + "/notifications/" + newKey] = notificationData;
    update(refDB(database), updates);

    return false;
  }
}


export const addBlackListArray = async (myId: string) => {
  await updateDoc(doc(db, "users", myId), {
    blacklist: [],
  });
};