import {Button, IconButton, Stack} from "@mui/material";
import {EditRounded} from "@mui/icons-material";
import Spacer from "../../../../components/abs/atoms/Spacer";
import styled from "@emotion/styled";
import {ReactComponent as DeleteIcon} from "../../../../../icons/icon_delete.svg";


const StyledIconButton = styled(IconButton)`
  border-radius: 8px;
  border: 1px solid #eee;
`

const StyledButton = styled(Button)`
  border-radius: 8px;
`

export const ButtonActionsCaseWishBook = (
    {onWrite}: { onWrite: () => void }
) => {
    return <>
        <Stack direction={"row"}>
            <StyledButton
                fullWidth
                size={"large"}
                variant={"contained"}
                children={"읽고 싶어요"}
                onClick={onWrite}
            />
        </Stack>
    </>
}

export const ButtonActionsCaseWriteReview = (
    {onDelete, onWrite}: {
        onDelete: () => void,
        onWrite: () => void
    }
) => {
    return <>
        <Stack direction={"row"}>
            <StyledIconButton
                children={
                    <DeleteIcon/>
                }
                onClick={onDelete}
            />
            <Spacer w={"0.5rem"}/>

            <StyledButton
                fullWidth
                variant={"contained"}
                children={"생각 적기"}
                startIcon={
                    <EditRounded/>
                }
                onClick={onWrite}
            />
        </Stack>
    </>
}

export const ButtonActionsCaseUpdateReview = (
    {onDelete, onUpdate}: {
        onDelete: () => void,
        onUpdate: () => void
    }
) => {
    return <>
        <Stack direction={"row"}>
            <StyledIconButton
                children={
                    <DeleteIcon/>
                }
                onClick={onDelete}
            />
            <Spacer w={"0.5rem"}/>

            <StyledButton
                fullWidth
                variant={"outlined"}
                children={"생각 다듬기"}
                startIcon={
                    <EditRounded/>
                }
                onClick={onUpdate}
            />
        </Stack>
    </>
}

export const ButtonActionsCaseFinishBook = (
    {onDelete, onFinish}: {
        onDelete: () => void,
        onFinish: () => void
    }
) => {
    return <>
        <Stack direction={"row"}>
            <StyledIconButton
                children={
                    <DeleteIcon/>
                }
                onClick={onDelete}
            />
            <Spacer w={"0.5rem"}/>

            <StyledButton
                fullWidth
                variant={"contained"}
                children={"다 읽었어요!"}
                onClick={onFinish}
            />
        </Stack>
    </>
}


export enum BookDetailActionType {
    FINISH_BOOK,
    WISH_BOOK,
    WRITE_REVIEW,
    UPDATE_REVIEW
}

export type RenderCaseOfButtonActionsProps = {
    renderType: BookDetailActionType,
    onPositive: (action: BookDetailActionType) => void
    onDelete?: () => void,
}

export const RenderCaseOfButtonActions = (
    {renderType, onPositive, onDelete}: RenderCaseOfButtonActionsProps
) => {
    const handleDelete = () => {
        onDelete && onDelete()
    }

    return <>
        {renderType === BookDetailActionType.WISH_BOOK &&
        <ButtonActionsCaseWishBook
            onWrite={() => onPositive(BookDetailActionType.WISH_BOOK)}
        />
        }

        {renderType === BookDetailActionType.WRITE_REVIEW &&
        <ButtonActionsCaseWriteReview
            onDelete={handleDelete}
            onWrite={() => onPositive(BookDetailActionType.WRITE_REVIEW)}
        />
        }

        {renderType === BookDetailActionType.UPDATE_REVIEW &&
        <ButtonActionsCaseUpdateReview
            onDelete={handleDelete}
            onUpdate={() => onPositive(BookDetailActionType.UPDATE_REVIEW)}
        />
        }

        {renderType === BookDetailActionType.FINISH_BOOK &&
        <ButtonActionsCaseFinishBook
            onDelete={handleDelete}
            onFinish={() => onPositive(BookDetailActionType.FINISH_BOOK)}
        />
        }

    </>
}
