import { Snackbar, Typography } from "@mui/material";
import { SlideUpTransition } from "../../../../animations/Transitions";
import React, { useEffect, useState } from "react";
import { VerticalScrollableStack } from "../../../../components/abs/base/Scrollable";
import QihanoDialog from "../../../../components/context/base/QihanoDialog";
import { useProfileEditDialogSlice } from "../../../../../redux/features/local/dialogs/profile/ProfileEditDialogSlice";
import { Picture } from "../../../../components/context/organisms/image_picker/data/Picture";
import Spacer from "../../../../components/abs/atoms/Spacer";
import { ProfileImageSection } from "./sections/ProfileImageSection";
import UpdateSelfIntroDialog from "./sub/UpdateSelfIntroDialog";
import FaceAuthDialog from "./sub/FaceAuthDialog";
import UpdateInterestsDialog from "./sub/UpdateInterestsDialog";
import { Interest } from "../../../../../domain/types/Interests";
import { ProfileInputSection } from "./sections/ProfileInputSection";
import UpdateNicknameDialog from "./sub/UpdateNicknameDialog";
import { ProfileEditDialogHeader } from "./sections/ProfileEditDialogHeader";
import {
  PicturesUpdate,
  SaveUserProfile,
} from "../../../../../redux/features/remote/users/UsersApi";
import { useMyInfo } from "../../../../../redux/features/remote/users/myInfo/MyInfoSlice";
import { applyFaceAuth, checkFaceAuth, fetchFaceAuth } from "../../../../../redux/features/remote/account/AccountApi";
import { toStringByFormatting } from "../../../../../utils/datetime/DateTime";

const ProfileEditDialog = () => {
  const { mutate, data, close } = useProfileEditDialogSlice();
  const { data: myInfo, mutate: mutateMyInfo } = useMyInfo();

  const { isOpen, user } = data;
  const user_ = user;

  const [faceAuthMsg, setFaceAuthMsg] = useState("")
  const [disableFaceAuthButton, setDisableFaceAuthButton] = useState(false)

  const [isOpenUpdateNickname, setIsOpenUpdateNickname] = useState(false);
  const [isOpenUpdateSelfIntro, setIsOpenUpdateSelfIntro] = useState(false);
  const [isOpenUpdateInterests, setIsOpenUpdateInterests] = useState(false);
  const [isOpenFaceAuth, setIsOpenFaceAuth] = useState(false);

  useEffect(()=>{
    if(isOpen){
      if(user_.isFaceAuth) {
        setFaceAuthMsg("재인증")  
      } else {
        setFaceAuthMsg("인증하기")
      }
    }
  },[isOpen, isOpenFaceAuth])

  const [isChangePictures, setIsChangePictures] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  if (!user_) {
    return <></>;
  }

  const handleClose = () => {
    setIsChangePictures(false);
    setDisableSubmitButton(true);
    close();
  };

  const handleChangeProfile = (imageFiles: Array<Picture>) => {
    // if (imageFiles.length < 1) {
    //   alert("프로필 사진은 하나 이상 등록되어야 합니다.");
    //   return;
    // }
    setIsChangePictures(true);
    const state = { ...user_ };
    state.profileImages = imageFiles;
    setDisableSubmitButton(false);
    mutate({ isOpen: true, user: state });
  };

  const handleAuthClick = () => {
    fetchFaceAuth().then(response => {
      if(response){
        if(response.some(it => it.uid === myInfo.uid)){
          alert("심사중입니다. 심사결과는 알림을 통해 알려드릴게요!")
        }
        else {
          setIsOpenFaceAuth(true);  
        }
      }
    })
  };

  const handleUpdateNicknameClick = () => {
    setIsOpenUpdateNickname(true);
  };

  const handleUpdateSelfIntroClick = () => {
    setIsOpenUpdateSelfIntro(true);
  };

  const handleUpdateInterestsClick = () => {
    setIsOpenUpdateInterests(true);
  };

  const handleSubmitNickname = (newNickname: string) => {
    const state = { ...user_ };
    state.nickname = newNickname;
    mutate({ isOpen: true, user: state });

    setIsOpenUpdateNickname(false);
    setDisableSubmitButton(false);
  };

  const handleSubmitSelfIntro = (newSelfIntro: string) => {
    const state = { ...user_ };
    state.selfIntroduction = newSelfIntro;
    mutate({ isOpen: true, user: state });

    setIsOpenUpdateSelfIntro(false);
    setDisableSubmitButton(false);
  };

  const handleSubmitInterests = (interests: Array<Interest>) => {
    const state = { ...user_ };
    state.interests = interests;
    mutate({ isOpen: true, user: state });

    setIsOpenUpdateInterests(false);
    setDisableSubmitButton(false);
  };

  const handleSubmitFaceAuth = (picture: Picture) => {
    
    if(picture.url.includes("base64")){
      alert("이 사진으로 요청하시려면 우선 프로필을 업데이트해주세요.")
    } else {
      checkFaceAuth(user_.uid).then(response => { 
        if(response){
          applyFaceAuth(myInfo, picture).then(response_ => {
            if(response_) {
              setIsOpenFaceAuth(false);
              alert("인증 요청이 완료되었습니다.")
            }
          })
        } else { //심사중인 계정에서 다시 인증요청한 경우
          setIsOpenFaceAuth(false);
          alert("심사가 끝난 다음 다시 요청해주세요.")
        }
      }) 
    }
  };

  const locatingSuccess = (position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords;
    const geocoder = new window.kakao.maps.services.Geocoder();

    const coord = new window.kakao.maps.LatLng(latitude, longitude);
    const callback = function (result, status) {
      if (status === window.kakao.maps.services.Status.OK) {
        const state = { ...user_ };
        state.address = {
          profile:
            result[0].address.region_1depth_name +
            " " +
            result[0].address.region_2depth_name,
          latitude: latitude,
          longitude: longitude,
        };
        mutate({ isOpen: true, user: state });
        alert(`위치 정보를 활성화하였습니다.`);
        setDisableSubmitButton(false);
      }
    };
    geocoder.coord2Address(coord.getLng(), coord.getLat(), callback);
  };

  const locatingError = (error: GeolocationPositionError) => {
    alert(error.message);
  };

  const handleUpdateAddressClick = () => {
    if (window.confirm("현재 위치로 지역을 다시 설정할까요?")) {
      if (!navigator.geolocation) {
        alert("Geolocation is not supported by your browser");
        return;
      }
      navigator.geolocation.getCurrentPosition(locatingSuccess, locatingError);
    }
  };

  const handleSubmit = () => {
    if(user_.profileImages.length < 1 ) {
      alert("프로필 사진은 하나 이상 등록되어야 합니다.")
      return;
    }
    if(user_.isFaceAuth && isChangePictures){
      const confirm = window.confirm("프로필 사진을 변경하시면 얼굴인증 배지가 사라집니다. 계속할까요?")
      if (!confirm) return;
    }
    setDisableSubmitButton(true);
      PicturesUpdate(isChangePictures, user).then((response) => {
        if (response) {
          SaveUserProfile(response).then((response_) => {
            if (response_) {
              const updateMyInfo = {
                ...myInfo,
                nickname: response.nickname,
                selfIntroduction: response.selfIntroduction,
                interests: response.interests,
                profileImages: response.profileImages,
                address: response.address,
                isFaceAuth: response.isFaceAuth,
                updateDate: toStringByFormatting(new Date())
              };
              mutateMyInfo(updateMyInfo);
              close();
              setOpenSnackbar(true);
            }
          });
        }
      }).catch((e) => {
        console.log(e)
        alert("사진 용량이 크거나, 허용되지 않는 파일입니다.")
      });
  };

  //

  return (
    <>
      <QihanoDialog
        fullScreen={true}
        open={isOpen}
        TransitionComponent={SlideUpTransition}
      >
        <VerticalScrollableStack
          height={"100%"}
          sx={{ backgroundColor: "#f7f7f7" }}
        >
          {/* <Header onCloseButtonClick={handleClose}/> */}
          <ProfileEditDialogHeader
            disableSubmitButton={disableSubmitButton}
            onClose={handleClose}
            onSubmit={handleSubmit}
          />

          <ProfileImageSection
            imageFiles={user_.profileImages}
            setImageFiles={handleChangeProfile}
            faceAuthMsg={faceAuthMsg}
            disableFaceAuthButton={disableFaceAuthButton}
            onAuthClick={handleAuthClick}
          />
          <Spacer h={"56px"} />

          <ProfileInputSection
            user={user_}
            onUpdateNickname={handleUpdateNicknameClick}
            onUpdateSelfIntroClick={handleUpdateSelfIntroClick}
            onUpdateInterestsClick={handleUpdateInterestsClick}
            onUpdateAddressClick={handleUpdateAddressClick}
          />
        </VerticalScrollableStack>

        <>
          <UpdateNicknameDialog
            isOpen={isOpenUpdateNickname}
            setOpen={setIsOpenUpdateNickname}
            defaultValue={user_.nickname}
            onSubmitClick={handleSubmitNickname}
          />

          <UpdateSelfIntroDialog
            isOpen={isOpenUpdateSelfIntro}
            setOpen={setIsOpenUpdateSelfIntro}
            defaultValue={user_.selfIntroduction}
            onSubmitClick={handleSubmitSelfIntro}
          />

          <UpdateInterestsDialog
            isOpen={isOpenUpdateInterests}
            setOpen={setIsOpenUpdateInterests}
            defaultValue={user_.interests}
            onSubmit={handleSubmitInterests}
          />

          <FaceAuthDialog
            isOpen={isOpenFaceAuth}
            setIsOpen={setIsOpenFaceAuth}
            onSubmitAuth={handleSubmitFaceAuth}
            imageFiles={user_.profileImages}
            setImageFiles={handleChangeProfile}
          />
        </>
      </QihanoDialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message={
          <Typography
            variant={"body2"}
            fontWeight={"bold"}
            children={"프로필이 수정되었습니다"}
          />
        }
        sx={{ position: "absolute" }}
      />
    </>
  );
};

export default ProfileEditDialog;
