import { useState } from "react";
import "./App.css";
import styled from "@emotion/styled";
import { Stack, ThemeProvider } from "@mui/material";
import changeTheme, { MAX_WIDTH, themes } from "./ui/themes/CustomThemes";
import {Provider} from "react-redux";
import qihanoStore, {persistor} from "./redux/store/QihanoStore"
import { PersistGate } from "redux-persist/integration/react";
import DynamicRoutes from "./router/Routes";
import GlobalDialogContainer from "./ui/dialogs/GlobalDialogContainer";

const MainContainer = styled(Stack)`
  position: relative;

  max-width: ${MAX_WIDTH};
  max-height: 100%;
  height: 100vh;

  margin: 0 auto;
  overflow: hidden;
`;

const App = () => {
  return (
    <ThemeProvider theme={changeTheme(themes.DEFAULT)}>
      <Provider store={qihanoStore}>
        <PersistGate persistor={persistor}>
          <MainContainer>
            <DynamicRoutes />
            <GlobalDialogContainer />
          </MainContainer>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
