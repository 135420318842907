import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {UserEntity} from "../../../../domain/entities/user/UserEntity";
import { RootState } from "../../../store/QihanoStore";

/**
 * 유저 정보
 * */
const initialState: Array<UserEntity> = []

const UsersSlice = createSlice({
    name: 'users', // action
    initialState: initialState, // default value
    reducers: {
        pullUsers: (state, action: PayloadAction<Array<UserEntity>>) =>
            action.payload,
    }
})

const {pullUsers} = UsersSlice.actions

export const useUsersState = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.users, shallowEqual)

    const mutate = (state: Array<UserEntity>) => {
        dispatch(pullUsers(state))
    }


    return {
        mutate,
        data
    }
}

export default UsersSlice.reducer