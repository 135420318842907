import { UserEntity } from './../../../../domain/entities/user/UserEntity';
import { ReviewEntity } from './../../../../domain/entities/review/ReviewEntity';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/QihanoStore";
import { useEffect } from "react";
import { pullMyPageBooks } from "./MyPageBooksSlice";
import { fetchBooksDB } from "./BookApi";
import { useBooksState } from "./BooksSlice";
import { BookEntity } from "../../../../domain/entities/book/BookEntity";
import { useBookDetailDialogSlice } from "../../local/dialogs/book/detail/BookDetailDialogSlice";
import { BookDetailActionType } from "../../../../ui/dialogs/childs/book/detail/ButtonActionsCase";
import { useReviewsState } from "../reviews/slice/ReviewsSlice";
import { BookmarkTypes } from '../../../../domain/types/BookmarkTypes';


export const useFetchBooks = () => {
  const {data: books, mutate: pullBooks} = useBooksState()

  useEffect(() => {
      fetchBooksDB().then(response=>{
          pullBooks(response)
      })
  }, [])

  return {
      books
  }
}


export const useMyBooks = (user: UserEntity) => {
  const finishedBooks: Array<BookEntity> = [];
  const wishBooks: Array<BookEntity> = [];
  user.bookmarks.forEach(bookmark => {
    if(bookmark.type === BookmarkTypes.FINISHED) finishedBooks.push(bookmark.book)
    else wishBooks.push(bookmark.book)
  })

  return {finishedBooks, wishBooks}
};


