// 페이지
enum GeneralPath {
    SPLASH = "/splash",

    CURRENT_PASSWORD = "/account/password/current",
    UPDATE_PASSWORD = "/account/password/update",
    UNREGISTER = '/account/unregister', // 회원탈퇴

    MAIN = "/main",
    SETTING = "/setting",
    ADMIN_FACEAUTH = "/admin/faceauth"
}

// 인증 페이지
export enum AuthPath {
    SOCIAL = "/auth/social",
    LOGIN = "/auth/login",
}

// 회원가입 서브 페이지
export enum SignupPath {
    LOADING = "/loading",
    // EMAIL = "/account/signup/email",
    // AUTH_EMAIL = "/account/signup/email-auth",
    // SET_PASSWORD = "/account/signup/password",
    // PHONE = "/account/signup/phone",
    // AUTH_PHONE = "/account/signup/phone/auth",
    // NOTICE_EMAIL_AUTH = "/account/signup/email-auth",

    TERMS = "/account/signup/terms",
    BIRTH = "/account/signup/birth",
    NAME = "/account/signup/name",
    GENDER = "/account/signup/gender",

    ALLOW_GPS = "/account/signup/gps",
    PICTURES = "/account/signup/pictures",
    INTEREST = "/account/signup/interest",
    REPRESENT_BOOK = "/account/signup/represent-book",

    WELCOME = "/account/signup/welcome"
}

export default GeneralPath
