import {MessageEntity} from "../../../../../../domain/entities/message/MessageEntity";
import {Avatar, Stack, Typography} from "@mui/material";
import Spacer from "../../../../../components/abs/atoms/Spacer";
import {v4} from "uuid";
import React from "react";
import {textDisabledColor} from "../../../../../themes/Color";
import styled from "@emotion/styled";
import {initialUserEntity, UserEntity} from "../../../../../../domain/entities/user/UserEntity";

const Root = styled(Stack)`
  flex-direction: row;
  width: 100%;
`

const Container = styled(Stack)`
  width: 100%;
  margin-bottom: 4px;

`

const StyledAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`

const MessageContainer = styled(Stack)`
  flex-direction: row;
  min-width: 100%;
`

const Bubble = styled(Stack)`
  border: 1px solid #ececec;
  border-radius: 12px;
  padding: 10px 16px;
  width: fit-content;
  max-width: 80%;
`

const TimeCaption = ({time}: { time: string }) =>
    <Stack justifyContent={"flex-end"} px={"0.2rem"}>
        <Typography
            variant={"caption"}
            fontSize={"10px"}
            color={textDisabledColor}
            children={time}
        />
    </Stack>

//

export type ChatBubbleProps = {
    time: string,
    messages: Array<MessageEntity>,
    onProfileClick?: (user: UserEntity) => void,
    partner?: UserEntity
}

export const LeftChatBubble = (
    {time, messages, onProfileClick, partner}: ChatBubbleProps
) => {
    const mainMessage = messages[0]

    const handleProfileClick = () => {
        if(partner){
            onProfileClick && onProfileClick(partner)
        }
    }

    //

    const renderTime = (index: number) =>
        (index === messages.length - 1) && <TimeCaption time={time}/>

    return (typeof partner !== 'undefined') ? <>
        <Root justifyContent={"flex-start"}>
            <StyledAvatar
                alt={"profile image"}
                src={partner.profileImages[0].url}
                onClick={handleProfileClick}
            />
            <Spacer w={"0.5rem"}/>

            <Container gap={"4px"} alignItems={"flex-start"}>

                {messages.map((it, index) =>
                    <MessageContainer key={v4()} justifyContent={"flex-start"}>
                        <Bubble bgcolor={"#ececec"}>
                            <Typography
                                variant={"caption"}
                                children={it.message}
                                sx={{wordBreak: "break-word"}}
                            />
                        </Bubble>

                        {renderTime(index)}
                    </MessageContainer>
                )}

            </Container>
        </Root>
    </>:<></>
}

export const RightChatBubble = (
    {time, messages}: ChatBubbleProps
) => {

    const renderTime = (index: number) =>
        (index === messages.length - 1) && <TimeCaption time={time}/>

    return <>
        <Root justifyContent={"flex-end"}>
            <Container gap={"4px"} alignItems={"flex-end"}>

                {messages.map((it, index) =>
                    <MessageContainer key={v4()} justifyContent={"flex-end"}>
                        {renderTime(index)}

                        <Bubble bgcolor={"#fff"}>
                            <Typography
                                variant={"caption"}
                                lineHeight={"1rem"}
                                children={it.message}
                                sx={{wordBreak: "break-word"}}
                            />
                        </Bubble>
                    </MessageContainer>
                )}

            </Container>
        </Root>
    </>
}
