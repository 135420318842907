import {Dialog} from "@mui/material";
import styled from "@emotion/styled";
import {MAX_WIDTH} from "../../../themes/CustomThemes";
import {isMobile} from "../../../../utils/responsive/CheckResponsive";

// 모바일이 아닌 경우 width 제한
const QihanoDialog = styled(Dialog)`
  max-width: ${!isMobile() ? MAX_WIDTH : "auto"};
  margin: ${!isMobile() ? "0 auto" : "auto"};

  // 백그라운드 쉐도우
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.2);
  }

  // 카드 radius
  & .MuiDialog-container > .MuiPaper-root {
    border-radius: ${!isMobile() ? "8px" : "0"}
  }
`

export default QihanoDialog
