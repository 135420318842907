import {Stack, Tab, Tabs} from "@mui/material";
import {HorizontalBooksSwiper} from "../../../../../../components/context/feed/HorizontalBooksSwiper";
import React, {SyntheticEvent, useState} from "react";
import {BookEntity} from "../../../../../../../domain/entities/book/BookEntity";
import Spacer from "../../../../../../components/abs/atoms/Spacer";

export const ProfileDetailBooksTabsSection = (
    {finishedBooks, wishBooks, onBookClick}: {
        finishedBooks: Array<BookEntity>,
        wishBooks: Array<BookEntity>,
        onBookClick: (book: BookEntity) => void
    }
) => {

    const [tabIndex, setTabIndex] = useState(0)
    const handleTabsChange = (e: SyntheticEvent, newValue: any) => {
        setTabIndex(newValue)
    }

    //

    return <>
        <Stack>
            <Tabs
                value={tabIndex}
                onChange={handleTabsChange}
                indicatorColor="primary"
                sx={{borderBottom: "1px solid #eee", px: "1rem"}}
            >
                <Tab label="대화 나누고 싶은 책"/>
                <Tab label="읽고 싶은 책"/>
            </Tabs>
            <Spacer h={"2rem"}/>

            {(tabIndex === 0)
                ? <Stack>
                    <HorizontalBooksSwiper
                        books={finishedBooks}
                        onItemClick={onBookClick}
                    />
                </Stack>
                : <HorizontalBooksSwiper
                    books={wishBooks}
                    onItemClick={onBookClick}
                />
            }
        </Stack>
    </>
}
