import {Button, Stack, Typography} from "@mui/material";
import {textColorDeepGray, textColorGray} from "../../../../../../../../themes/Color";
import React from "react";
import Spacer from "../../../../../../../../components/abs/atoms/Spacer";
import {ReactComponent as EditIcon} from "../../../../../../../../../icons/icon_lock_ask.svg";


const CONTENT_MAX_LENGTH = 50

export const QuestionBottom = (
    {value, onOptionClick}: { value: string, onOptionClick: () => void }
) => {
    return <>
        <Stack
            width={"100%"}
            height={"40px"}
            sx={{backgroundColor: "#fafafa"}}
            padding={"1rem 1rem 1rem 0.5rem"}
            borderTop={"1px solid #eee"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
        >
            <Button onClick={onOptionClick}>
                <Stack direction={"row"}>
                    <EditIcon/>
                    <Spacer w={"0.5rem"}/>

                    <Typography
                        variant={"caption"}
                        color={textColorDeepGray}
                        children={"뭘 적어야할지 고민이에요"}
                        mt={"2px"}
                    />
                </Stack>
            </Button>

            <Typography variant={"caption"} color={textColorGray}>
                <span>{value}</span>
                {" / "}
                <span>{CONTENT_MAX_LENGTH}</span>
            </Typography>
        </Stack>
    </>
}
