import {Card, CardContent, Theme} from "@mui/material";
import {createStyles, makeStyles, withStyles} from "@material-ui/styles";
import styled from "@emotion/styled";

// export const StyledBookCard = withStyles({
//     root: {
//         borderRadius: "2px",
//         boxShadow: "0px 2px 4px #ccc",
//         marginBottom: "2px",
//         height: "fit-content",
//     }
// })(Card)

const StyledBookCard = styled(Card)`
    borderRadius: 2px;
    boxShadow: 0px 2px 4px #ccc;
    marginBottom: 2px;
    padding: 0px;
    & .MuiCardContent-root:last-child {
        padding-bottom: 0;
      }
`

export type BookItemProps = {
    imageUrl: string,
    width?: string,
}

export const BookItem = ({width = "100%", imageUrl}: BookItemProps) => {
    return <>
        <StyledBookCard sx={{minWidth: width, width: width, maxWidth: width}}>
            <img src={imageUrl} alt={"book"} style={{width: "100%"}}/>
        </StyledBookCard>
    </>
}
