import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReviewEntity} from "../../../../../domain/entities/review/ReviewEntity";

const initialState: Array<ReviewEntity> = []

const dailyReviewsSlice = createSlice({
    name: 'dailyReviews', //action
    initialState: initialState, //default value
    reducers: {
        pullDailyReviews: (state, action: PayloadAction<Array<ReviewEntity>>) => action.payload,
    }
})

export const {pullDailyReviews} = dailyReviewsSlice.actions
export default dailyReviewsSlice.reducer