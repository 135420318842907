// 책
export type BookEntity = {
    readonly bookId: string,
    readonly title: string,
    readonly authors: string[],
    readonly translators: string[],
    readonly publisher: string,
    readonly description: string,
    readonly thumbnailUrl: string,
    readonly directUrl: string
}

export const initialBookEntity: BookEntity = {
    bookId: "",
    title: "",
    authors: [],
    translators: [],
    publisher: "",
    description: "",
    thumbnailUrl: "",
    directUrl: ""
}
