import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useHistory } from "react-router";
import SimpleHeaderSection from "../../../components/abs/organisms/SimpleHeaderSection";
import { SignupPath } from "../../../../router/RouterPath";
import { textColorGray } from "../../../themes/Color";
import Spacer from "../../../components/abs/atoms/Spacer";
import { Picture } from "../../../components/context/organisms/image_picker/data/Picture";
import { useSignupState } from "../../../../redux/features/local/signup/SignupSlice";
import TripleImagePicker from "../../../components/context/organisms/image_picker/TripleImagePicker";

const usePicturePage = () => {
  const history = useHistory();

  const { data, mutate } = useSignupState();
  const { pictures } = data;

  const handleChangePicture = (pictures: Array<Picture>) => {
    const newSignupState = { ...data };
    newSignupState.pictures = pictures;
    mutate(newSignupState);
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleSubmit = () => {
    history.push(SignupPath.INTEREST)
  };

  return {
    pictures,
    handleChangePicture,
    handleBack,
    handleSubmit,
  };
};

const PicturePage = () => {
  const { pictures, handleChangePicture, handleBack, handleSubmit } =
    usePicturePage();

  const isEmptyImageFiles = pictures.length <= 0;

  return (
    <>
      <Stack height={"100%"}>
        <SimpleHeaderSection
          titleTextComponent={"프로필 사진"}
          onClose={handleBack}
        />
        <Spacer h={"8px"} />

        <Stack paddingX={"1rem"}>
          <Typography
            variant={"caption"}
            color={textColorGray}
            children={"사진을 1개 이상 추가해주세요."}
          />
        </Stack>
        <Spacer h={"8px"} />

        <Stack
          justifyContent={"space-between"}
          height={"100%"}
          padding={"1rem"}
        >
          <Stack width={"100%"}>
            <TripleImagePicker
              imageFiles={pictures}
              setImageFiles={handleChangePicture}
            />
          </Stack>
          <Spacer h={"2rem"} />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            size={"large"}
            disabled={isEmptyImageFiles}
            children={"계속"}
            onClick={handleSubmit}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default PicturePage;
