import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import qs from "qs";
import { SignupPath } from "../../../../router/RouterPath";

declare global {
  interface Window {
      Kakao:any;
      kakao:any;
  }
}

const KakaoBook = axios.create({
  baseURL: 'https://dapi.kakao.com', // 공통 요청 경로를 지정해준다.
  headers: {
    Authorization: 'KakaoAK '+process.env.REACT_APP_KAKAO_REST_KEY,
  },
});

// search book api
export const bookSearch = (params) => {
  return KakaoBook.get('/v3/search/book', { params });
};

export const REDIRECT_URI = "http://qihano.com/oauth/callback/kakao";
// login
const Kakao = () => {
  const code = new URL(window.location.href).searchParams.get("code");

  const history = useHistory();

  const getToken = async () => {
    const payload = qs.stringify({
      grant_type: "authorization_code",
      client_id: process.env.REACT_APP_KAKAO_REST_KEY,
      redirect_uri: REDIRECT_URI,
      code: code,
      client_secret: process.env.REACT_APP_KAKAO_CLIENT_SECRET,
    });
    try {
      // access token 가져오기
      const res = await axios.post(
        "https://kauth.kakao.com/oauth/token",
        payload
      );

      // Kakao Javascript SDK 초기화
      window.Kakao.init(process.env.REACT_APP_KAKAO_REST_KEY);
      // access token 설정
      window.Kakao.Auth.setAccessToken(res.data.access_token);
      
      history.replace(SignupPath.LOADING);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getToken();
  }, []);
  return null;
};

export default Kakao;
