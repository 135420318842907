import {Button, Stack, Typography} from "@mui/material";
import {textDisabledColor} from "../../../../../../themes/Color";
import Spacer from "../../../../../../components/abs/atoms/Spacer";
import {ArrowForwardRounded} from "@mui/icons-material";
import {getCurrentDateTime} from "../../../../../../../utils/datetime/DateTime";
import React from "react";

export type  BooksThisMonthHeaderProps = {
    onBookNextMonth: () => void
}

const BooksThisMonthHeader = (
    {onBookNextMonth}: BooksThisMonthHeaderProps
) => {
    const nextMonth = getCurrentDateTime().getMonth() + 2

    return <>
        <Stack>
            <Typography
                variant={"h6"}
                children={"이달의 PICK"}
            />
            <Typography
                variant={"caption"}
                color={textDisabledColor}
                fontWeight={500}
                children={"매칭 확률 UP! 더 많은 사람들과 생각을 나눠보세요"}
            />
            <Spacer h={"14px"}/>

            {/* <Button
                variant={"contained"}
                endIcon={<ArrowForwardRounded/>}
                onClick={onBookNextMonth}
                sx={{
                    justifyContent: "space-between",
                    minHeight: "48px"
                }}
            >
                <Typography
                    variant={"caption"}
                    fontWeight={"bold"}
                    children={`${nextMonth}월의 후보도서 미리 확인하기`}
                />
            </Button> */}
        </Stack>
    </>
}

export default React.memo(BooksThisMonthHeader)