import {AppBar, Button, IconButton, Stack, Toolbar, Typography} from "@mui/material";
import {CloseRounded} from "@mui/icons-material";
import React from "react";
import {createStyles, makeStyles} from "@material-ui/styles";
import Spacer from "../../../../../../../../components/abs/atoms/Spacer";


const useStyles = makeStyles(() => createStyles({
    appbar: {
        backgroundColor: "transparent",
    },
    toolbar: {
        justifyContent: "space-between"
    },
    nextButton: {
        borderRadius: "36px"
    }
}))


export type AddReviewDialogHeaderProps = {
    disableTempButton: boolean,
    disableSubmitButton: boolean,
    onClose: () => void,
    onTempSave: () => void,
    onSubmit: () => void,
    isPublic: boolean
}

export const AddReviewDialogHeader = (
    {disableTempButton, disableSubmitButton, onClose, onTempSave, onSubmit, isPublic}: AddReviewDialogHeaderProps
) => {
    const styles = useStyles()

    return <>
        <AppBar position="static" elevation={1} className={styles.appbar}>
            <Toolbar variant={"dense"} className={styles.toolbar}>
                <IconButton
                    size="large"
                    edge="start"
                    children={<CloseRounded/>}
                    onClick={onClose}
                />

                <Stack direction={"row"}>
                    {/* <Button
                        disabled={disableTempButton}
                        onClick={onTempSave}
                        
                    >
                        <Typography
                            variant={"caption"}
                            fontWeight={500}
                            children={"임시저장"}
                        />
                    </Button> */}
                    <Spacer w={"0.8rem"}/>

                    <Button
                        variant={"contained"}
                        className={styles.nextButton}
                        disabled={disableSubmitButton}
                        onClick={onSubmit}
                    >
                        <Typography
                            variant={"caption"}
                            fontWeight={500}
                            children={isPublic?"다음":"올리기"}
                        />
                    </Button>
                </Stack>

            </Toolbar>
        </AppBar>
    </>
}
