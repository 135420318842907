import {Stack, Typography} from "@mui/material";
import React from "react";
import {textColorLightBlack} from "../../../themes/Color";
import {PAGE_PADDING} from "../../../themes/CustomThemes";
import {SimpleToolbar, SimpleToolbarProps} from "../molecules/SimpleToolbar";


const SimpleHeaderSection = (
    p: {
        titleTextComponent: React.ReactNode
    } & SimpleToolbarProps
) => {
    return (
        <header>
            <SimpleToolbar {...p}/>

            <Stack direction={"row"} paddingX={PAGE_PADDING} paddingTop={"24px"}>
                <Typography
                    variant={"h5"}
                    color={textColorLightBlack}
                    children={p.titleTextComponent}/>
            </Stack>
        </header>
    )
}

export default SimpleHeaderSection
