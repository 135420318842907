import React from "react";
import {IconButton, Stack} from "@mui/material";
import {ArrowBackRounded} from "@mui/icons-material";

export type SimpleToolbarProps = {
    onClose: () => void
    optionComponent?: React.ReactNode,
}

export const SimpleToolbar = (
    {onClose, optionComponent}: SimpleToolbarProps
) =>
    <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        paddingX={"0.2rem"}
    >
        <IconButton
            aria-label="back"
            onClick={onClose}
            size={"large"}
            children={
                <ArrowBackRounded htmlColor={"black"}/>
            }
        />
        {optionComponent}
    </Stack>
