import {TitleWithFlatInput} from "../components/TitleWithFlatInput";
import Spacer from "../../../../../components/abs/atoms/Spacer";
import {getUserAge, UserEntity} from "../../../../../../domain/entities/user/UserEntity";
import {Card, Chip, Stack, Typography} from "@mui/material";
import React from "react";
import {disabledColor, textColorDeepGray, textColorGray} from "../../../../../themes/Color";
import {v4} from "uuid";
import { displayDate } from "../../../../../../utils/datetime/DateTime";
import { FlatInput } from "../../../../../components/abs/atoms/FlatInput";

export type ProfileInputSectionProps = {
    onUpdateNickname: () => void,
    onUpdateSelfIntroClick: () => void,
    onUpdateInterestsClick: () => void,
    onUpdateAddressClick: () => void
}

export const ProfileInputSection = (
    p: {
        user: UserEntity
    } & ProfileInputSectionProps
) => {

    const {user} = p
    const {onUpdateNickname, onUpdateSelfIntroClick, onUpdateInterestsClick, onUpdateAddressClick} = p

    const renderInterests =
        user.interests.map(it => {
            return (
                <Chip
                    key={v4()}
                    label={it}
                    variant={"outlined"}
                    sx={{
                        mb: "6px",
                        mr: "6px",
                        borderColor: "#d3d3d3",
                        color: textColorDeepGray,
                        cursor: "pointer"
                    }}
                />
            )
        })

    //

    return <>
        <Stack px={"1rem"}>
            <TitleWithFlatInput
                title={"이름"}
                fullWidth
                multiline={true}
                readOnly
                value={user.nickname}
                onClick={onUpdateNickname}
                sx={{cursor: "pointer"}}
                inputProps={{sx: {cursor: "pointer"}}}
            />
            <Spacer h={"1.5rem"}/>

            <TitleWithFlatInput
                title={"자기소개"}
                fullWidth
                multiline={true}
                readOnly
                value={user.selfIntroduction}
                placeholder={"아직 자기소개를 하지 않으셨네요! 여기를 누르세요."}
                onClick={onUpdateSelfIntroClick}
                sx={{cursor: "pointer"}}
                inputProps={{sx: {cursor: "pointer"}}}
            />
            <Spacer h={"1.5rem"}/>

            <Stack>
                <Typography
                    variant={"subtitle2"}
                    color={textColorGray}
                    children={"관심사"}
                    pl={"0.8rem"}
                />
                <Spacer h={"0.4rem"}/>
                {user.interests.length !== 0?<Card
                    sx={{
                        boxShadow: "1px 1px 1px #eee",
                        minHeight: "42px",
                        padding: "12px 12px 6px 12px",
                        cursor: "pointer"
                    }}
                    onClick={onUpdateInterestsClick}
                >
                    <div>
                        {renderInterests}
                    </div>
                </Card> : <FlatInput value={"여기를 클릭해서 관심사를 설정하세요."} disabled onClick={onUpdateInterestsClick} fullWidth/>}
            </Stack>
            <Spacer h={"1.5rem"}/>

            <TitleWithFlatInput
                title={"지역"}
                fullWidth
                readOnly
                multiline={true}
                value={user.address.profile}
                onClick={onUpdateAddressClick}
                sx={{cursor: "pointer"}}
                inputProps={{sx: {cursor: "pointer"}}}
            />
            <Spacer h={"1.5rem"}/>

            <TitleWithFlatInput
                title={"나이"}
                disabled
                fullWidth
                multiline={true}
                value={`${getUserAge(user.birthDate)}세  (${displayDate(user.birthDate)})`}
            />
            <Spacer h={"1.5rem"}/>

        </Stack>
    </>
}
