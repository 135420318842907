import React, { useEffect } from "react";
import { Button, Checkbox, Stack, Typography } from "@mui/material";
import SimpleHeaderSection from "../../../components/abs/organisms/SimpleHeaderSection";
import { useHistory } from "react-router";
import {
  CheckCircleRounded,
  CheckRounded,
  ChevronRightRounded,
} from "@mui/icons-material";
import { SignupPath } from "../../../../router/RouterPath";
import { v4 as generateUniqueKey } from "uuid";
import { VerticalScrollableStack } from "../../../components/abs/base/Scrollable";
import { disabledColor } from "../../../themes/Color";
import Spacer from "../../../components/abs/atoms/Spacer";
import { useSignupState } from "../../../../redux/features/local/signup/SignupSlice";
import { termsMock } from "../../../../domain/mocks/terms/Terms.mock";
import { AgreedTermEntity } from "../../../../domain/entities/terms/AgreedTermEntity";
import { toFormattedRequiredString } from "../../../../domain/entities/terms/TermEntity";

// 약관 체크 행
const TermCheckRowItem = (p: {
  checked: boolean;
  onCheckChange: (checked: boolean) => void;
  label: React.ReactNode;
  onLabelClick: () => void;
}) => {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={"100%"}
      paddingLeft={"1rem"}
      boxSizing={"border-box"}
    >
      <Stack direction={"row"} alignItems={"center"} width={"100%"}>
        <Checkbox
          color={"secondary"}
          size={"small"}
          checked={p.checked}
          onChange={(e) => p.onCheckChange(e.target.checked)}
          icon={<CheckRounded htmlColor={disabledColor} />}
          checkedIcon={<CheckRounded />}
        />
        <Spacer w={"0.2rem"} />

        <Button
          fullWidth
          size={"large"}
          endIcon={<ChevronRightRounded htmlColor={disabledColor} />}
          sx={{ justifyContent: "space-between" }}
          onClick={p.onLabelClick}
          children={p.label}
        />
      </Stack>
    </Stack>
  );
};

// 전체 동의 버튼
const AllCheckButton = ({
  checkAll,
  handleCheckAll,
}: {
  checkAll: boolean;
  handleCheckAll: () => void;
}) => {
  const checkIcon = checkAll ? (
    <CheckCircleRounded color={"secondary"} />
  ) : (
    <CheckCircleRounded htmlColor={disabledColor} />
  );

  return (
    <>
      <Button
        variant={"outlined"}
        size={"large"}
        sx={{ borderRadius: "8px", justifyContent: "flex-start" }}
        startIcon={checkIcon}
        onClick={handleCheckAll}
        children={
          <Typography
            variant={"subtitle1"}
            fontWeight={500}
            children={"약관 전체 동의"}
          />
        }
      />
    </>
  );
};

const UsingTermPage = () => {
  const history = useHistory();

  const { data, mutate } = useSignupState();

  const isInValidData =
    data.agreedTerms.length !== termsMock.filter((it) => it.isRequired).length;

    useEffect(()=>{
      if (isInValidData) {
        const initialAgreedTerms = termsMock.map<AgreedTermEntity>((it) => {
          return { ...it, isAgreed: false };
        });
    
        const newSignupState = { ...data };
        newSignupState.agreedTerms = initialAgreedTerms;
    
        mutate(newSignupState);
      }
    }, [])
  

  const agreedTerms = data.agreedTerms;
  const isAllChecked = agreedTerms.filter(it=>it.isRequired === true).every((it) => it.isAgreed === true);

  const handleCheckAll = () => {
    const newAgreedTerms = agreedTerms.map((it) => {
      const newState = { ...it };
      newState.isAgreed = !isAllChecked;

      return newState;
    });

    const newSignupState = { ...data };
    newSignupState.agreedTerms = newAgreedTerms;

    mutate(newSignupState);
  };

  const handleChange = (agreedTerm: AgreedTermEntity, checked: boolean) => {
    const newAgreedTerms = agreedTerms.map((it) => {
      if (it.termsIdx === agreedTerm.termsIdx) {
        const newState = { ...it };
        newState.isAgreed = checked;

        return newState;
      } else {
        return it;
      }
    });

    const newSignupState = { ...data };
    newSignupState.agreedTerms = newAgreedTerms;

    mutate(newSignupState);
  };

  const handleTermClick = (index: number) => {
    switch (index) {
      case 1:
        window.open("https://www.mhsj.kr/qihano/policy/tos")
        break;
      case 2:
        window.open("https://www.mhsj.kr/qihano/policy/location")
        break;
      case 3:
        window.open("https://www.mhsj.kr/qihano/policy/privacy")
        break;
      case 4:
        window.open("https://www.mhsj.kr/qihano/policy/marketing")
        break;
    }
  };

  const handleSubmitClick = () => {
    const newSignupState = { ...data };
    newSignupState.agreedTerms = agreedTerms;
    mutate(newSignupState);
    history.push(SignupPath.BIRTH);
  };

  const handleBackClick = () => history.goBack();

  const renderAgreedTerms = agreedTerms.map((it) => {
    return (
      <TermCheckRowItem
        key={generateUniqueKey()}
        checked={it.isAgreed}
        onCheckChange={(checked) => handleChange(it, checked)}
        label={`${toFormattedRequiredString(it.isRequired)} ${it.title}`}
        onLabelClick={() => handleTermClick(it.termsIdx)}
      />
    );
  });

  return (
    <>
      <VerticalScrollableStack height={"100%"}>
        <SimpleHeaderSection
          titleTextComponent={"이용 약관"}
          onClose={handleBackClick}
        />

        <Spacer h={"40px"} />

        <Stack
          justifyContent={"space-between"}
          height={"100%"}
          padding={"1rem"}
        >
          <Stack>
            <AllCheckButton
              checkAll={isAllChecked}
              handleCheckAll={handleCheckAll}
            />
            <Spacer h={"1rem"} />

            <Stack width={"100%"}>{renderAgreedTerms}</Stack>
          </Stack>
          <Spacer h={"2rem"} />

          <Button
            variant="contained"
            size={"large"}
            color="primary"
            fullWidth
            disabled={!isAllChecked}
            children={"동의 후 계속"}
            onClick={handleSubmitClick}
          />
        </Stack>
      </VerticalScrollableStack>
    </>
  );
};

export default UsingTermPage;
