import {
    GroupedMessagesByDate,
    GroupedMessagesByUser,
    GroupedMessagesByTime
} from "../../../../../../domain/entities/message/GroupedMessageEntity";
import {Stack, Typography} from "@mui/material";
import {LeftChatBubble, RightChatBubble} from "../../components/bubble/ChatBubble";
import Spacer from "../../../../../components/abs/atoms/Spacer";
import {v4} from "uuid";
import {textDisabledColor} from "../../../../../themes/Color";
import React, {memo} from "react";
import {UserEntity} from "../../../../../../domain/entities/user/UserEntity";
import { SessionPropertyKey } from "../../../../../../session/SessionPropertyKey";

export type ChatMessagesSectionProps = {
    partner: UserEntity,
    messages: Array<GroupedMessagesByDate>,
    onProfileClick: (user: UserEntity) => void
}

export const ChatMessagesSection = memo((
    {partner, messages, onProfileClick}: ChatMessagesSectionProps
) => {
    const myId = sessionStorage.getItem(SessionPropertyKey.USER_ID) ?? "-1"

    const renderGroupedMessagesByUser = (time: string, messagesByUserId: Array<GroupedMessagesByUser>) => {
        return messagesByUserId.map(({userId, messages}) => {
            const isMine = (userId === myId)
            return (
                <Stack key={v4()}>
                    {isMine
                        ? <RightChatBubble
                            time={time}
                            messages={messages}
                        />
                        : <LeftChatBubble
                            time={time}
                            messages={messages}
                            onProfileClick={onProfileClick}
                            partner={partner}
                        />}
                    <Spacer h={"16px"}/>
                </Stack>
            )
        })
    }

    const renderGroupedMessagesByTime = (messagesByTime: Array<GroupedMessagesByTime>) => {
        return messagesByTime
            .map(({time, messagesByUserId}) => {
                return (
                    <Stack key={v4()} width={"100%"}>
                        {renderGroupedMessagesByUser(time, messagesByUserId)}
                    </Stack>
                )
            })
    }

    const renderGroupedMessagesByDate =
        messages
            .map(({date, messagesByTime}) => {
                return (
                    <Stack
                        key={v4()}
                        alignItems={"center"}
                        width={"100%"}
                    >
                        <Typography
                            variant={"caption"}
                            fontSize={"10px"}
                            color={textDisabledColor}
                            py={"24px"}
                            children={date}
                        />
                        {renderGroupedMessagesByTime(messagesByTime)}
                    </Stack>
                )
            })

    return <>
        <Stack>
            {renderGroupedMessagesByDate}
        </Stack>
    </>
})
