import {Redirect, Route} from "react-router-dom";
import {SessionPropertyKey} from "../session/SessionPropertyKey";
import {RouteChildrenProps, RouteProps} from "react-router";
import React, {lazy, Suspense} from "react";

const lazyRenderPage = ({location}: RouteChildrenProps) =>
    lazy(() =>
        import("../ui/pages" + location.pathname).catch((e) => {
            if (/not find module/.test(e.message)) {
                // return import("./pages/NotFound.js");
            }
            if (/Loading chunk \d+ failed/.test(e.message)) {
                window.location.reload();
                return;
            }
            throw e;
        })
    )

const PrivateRoute = (
    {component, ...rest}: RouteProps
) => {
    const isValidSession =
        sessionStorage.getItem(SessionPropertyKey.USER_ID)

    if (!isValidSession) {
        alert("로그인 후 이용해주세요.")
    }

    return <>
        <Route
            {...rest}
            render={props =>
                !!isValidSession
                    ? (() => {
                        const Page = lazyRenderPage(props) //원하는 페이지로
                        return <Suspense fallback={""} children={<Page/>}/>
                    })()
                    : <Redirect to="/"/>
            }
        />
    </>
}

export default PrivateRoute