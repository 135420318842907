import { Button, Divider, Stack } from "@mui/material";
import React from "react";
import { NotificationEntity } from "../../../../../../domain/entities/notice/NotificationEntity";
import { VerticalScrollableStack } from "../../../../../components/abs/base/Scrollable";
import { NotificationItem } from "../../components/NotificationItem";
import { v4 } from "uuid";
import {
  computeDiffDay,
  getCurrentDateTime,
} from "../../../../../../utils/datetime/DateTime";
import { SwipeableListItem } from "@sandstreamdev/react-swipeable-list";
import { RightSwipeHiddenComponent } from "../../../../../pages/main/reaction/tab.contents/reaction.received/ReactionIReceivedPage";
import { v4 as generateUniqueKey } from "uuid";
import { ref, remove } from "firebase/database";
import { database } from "../../../../../../firebase-config";
import { SessionPropertyKey } from "../../../../../../session/SessionPropertyKey";

export const MyNotificationSection = ({
  notificationList,
  onItemClick
}: {
  notificationList: Array<NotificationEntity>,
  onItemClick: (notification: NotificationEntity) => void
}) => {
  const myId = sessionStorage.getItem(SessionPropertyKey.USER_ID) ?? "-1";

  const handleSwipeLeft = (notification: NotificationEntity) => {
    remove(ref(database, myId + "/notifications/" + notification.id));
  };

  const copiedData = notificationList ? [...notificationList] : [];
  const sortedData = copiedData.sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  const notificationItems = sortedData.map((it) => {
    const fewDay = computeDiffDay(getCurrentDateTime(), new Date(it.date));
    const formattedFewDay = fewDay === 0 ? "오늘" : `${fewDay}일 전`;

    const formattedReviewTitle =
      it.subTitle.length > 30 ? it.subTitle.slice(0, 30) + "..." : it.subTitle;

    return (
      <Stack key={generateUniqueKey()}>
        <SwipeableListItem
          swipeLeft={{
            content: <RightSwipeHiddenComponent />,
            action: () => handleSwipeLeft(it),
          }}
        >
          <Stack key={v4()} width={"100%"}>
            <Button sx={{ padding: "1rem" }} onClick={()=>onItemClick(it)}>
              <NotificationItem
                title={it.title}
                subtitle={formattedReviewTitle}
                dateString={formattedFewDay}
              />
            </Button>
            <Divider sx={{ mx: "1rem" }} />
          </Stack>
        </SwipeableListItem>

        <Divider sx={{ mx: "1rem" }} />
      </Stack>
    );
  });

  return (
    <>
      <VerticalScrollableStack height={"100%"}>
        {notificationItems}
      </VerticalScrollableStack>
    </>
  );
};
