import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { BookEntity } from "../../../../domain/entities/book/BookEntity";
import { RootState } from "../../../store/QihanoStore";

const initialState: Array<BookEntity> = []

const BooksSlice = createSlice({
    name: 'books', //action
    initialState: initialState, //default value
    reducers: {
        pullBooks: (state, action: PayloadAction<Array<BookEntity>>) => action.payload,
    }
})

export const {pullBooks} = BooksSlice.actions;

export const useBooksState = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.books, shallowEqual)

    const mutate = (state: Array<BookEntity>) => {
        dispatch(pullBooks(state))
    }


    return {
        mutate,
        data
    }
}

export default BooksSlice.reducer;