import {useDispatch, useSelector} from "react-redux";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MainBottomNavTypes} from "../../../../../domain/types/MainBottomNavTypes";
import {RootState} from "../../../../store/QihanoStore";

const MainBottomNavSlice = createSlice({
    name: 'mainBottomNavState',
    initialState: MainBottomNavTypes.HOME,
    reducers: {
        setMainBottomNavState: (state, action: PayloadAction<MainBottomNavTypes>) =>
            action.payload,
    }
})

const {setMainBottomNavState} = MainBottomNavSlice.actions


export const useMainBottomNavState = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.mainBottomNavState)

    const mutate = (navState: MainBottomNavTypes) =>
        dispatch(setMainBottomNavState(navState))

    return {
        data,
        mutate
    }
}

export default MainBottomNavSlice.reducer
