import React from 'react';
import Spacer from "../../../components/abs/atoms/Spacer";
import {Button, Stack, Typography} from "@mui/material";
import SimpleHeaderSection from "../../../components/abs/organisms/SimpleHeaderSection";
import {useHistory} from "react-router";
import {SignupPath} from "../../../../router/RouterPath";
import GenderType from "../../../../domain/types/Gender";
import {textColorGray} from "../../../themes/Color";
import {VerticalScrollableStack} from "../../../components/abs/base/Scrollable";
import {useSignupState} from "../../../../redux/features/local/signup/SignupSlice";

const GenderPage = () => {
    const history = useHistory()

    const {data, mutate} = useSignupState()
    const {gender} = data
    const isGenderFemale = gender === GenderType.FEMALE
    const isGenderMale = (gender === GenderType.MALE)

    const handleChangeGender = (gender: GenderType) => {
        const newSignupState = {...data}
        newSignupState.gender = gender
        mutate(newSignupState)
    }

    const handleBack = () => {
        history.goBack()
    }

    const handleSubmit = () => {
        history.push(SignupPath.ALLOW_GPS)
    }


    return <>
        <VerticalScrollableStack height={"100%"}>

            <SimpleHeaderSection
                titleTextComponent={"나는"}
                onClose={handleBack}
            />
            <Spacer h={"8px"}/>

            <Stack paddingX={"1rem"}>
                <Typography
                    variant={"caption"}
                    color={textColorGray}
                    children={"선택한 성별은 추후 변경할 수 없습니다."}
                />
            </Stack>
            <Spacer h={"60px"}/>

            <Stack justifyContent={"space-between"} height={"100%"} padding={"1rem"}>

                <Stack direction={"row"}>
                    <Button
                        variant={isGenderMale ? "contained" : "outlined"}
                        color={isGenderMale ? "secondary" : "primary"}
                        size={"large"}
                        fullWidth
                        sx={{borderRadius: "20px"}}
                        children={GenderType.MALE}
                        onClick={() => handleChangeGender(GenderType.MALE)}
                    />
                    <Spacer w="1rem"/>

                    <Button
                        variant={isGenderFemale ? "contained" : "outlined"}
                        color={isGenderFemale ? "secondary" : "primary"}
                        size={"large"}
                        fullWidth
                        sx={{borderRadius: "20px"}}
                        children={GenderType.FEMALE}
                        onClick={() => handleChangeGender(GenderType.FEMALE)}
                    />
                </Stack>
                <Spacer h={"1rem"}/>

                <Button
                    variant="contained"
                    size={"large"}
                    fullWidth
                    disabled={!gender}
                    children={"계속"}
                    onClick={handleSubmit}
                />

            </Stack>

        </VerticalScrollableStack>

    </>
}

export default GenderPage
