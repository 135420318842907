import styled from "@emotion/styled";
import {TextField} from "@mui/material";

const SearchBar = styled(TextField)`
  background-color: #efefef;

  & .MuiOutlinedInput-root {
    padding-right: 0;
  }
`



export default SearchBar
