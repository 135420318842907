import {BookEntity} from "../../../../domain/entities/book/BookEntity";
import {Stack, Typography} from "@mui/material";
import {BookItem} from "./BookItem";
import {textColorGray} from "../../../themes/Color";
import React from "react";
import BookWithInfoLayout from "./BookWithInfoLayout";

const BookWithInfo = (
    {book}: { book: BookEntity }
) => {

    const bookItem =
        <BookItem
            width={"5rem"}
            imageUrl={book.thumbnailUrl}
        />

    const infoItem =
        <Stack textAlign={"start"}>
            <Typography variant={"subtitle1"} children={book.title}/>
            <Typography variant={"caption"} color={textColorGray} children={book.authors.join(', ')}/>
        </Stack>


    return <>
        <BookWithInfoLayout bookItem={bookItem} infoItem={infoItem}/>
    </>
}

export default BookWithInfo
