// noinspection UnnecessaryLocalVariableJS

export const formatToPhoneNumber = (
    text: string,
    maxLength: number = 11
) => {
    const delimiter = " "
    const numberWithout = text.replace(/[^0-9]/g, '')

    if (numberWithout.length > maxLength)
        return text.slice(0, -1)

    const dashPlaces = [3, 7]
    const numberWithDelimiter = numberWithout
        .split('')
        .reduce<Array<string>>(
            (acc, curr, index) =>
                dashPlaces
                    .includes(index)
                    ? [...acc, delimiter, curr]
                    : [...acc, curr]
            , []
        )
        .join('')

    return numberWithDelimiter
}

export const formatToBirthDate = (
    text: string,
    maxLength: number = 8
) => {
    const delimiter = ". "
    const numberWithout = text.replace(/[^0-9]/g, '')

    if (numberWithout.length > maxLength)
        return text.slice(0, -1)

    const placesIndexes = [4, 6]
    const numberWithDelimiter = numberWithout
        .split('')
        .reduce<Array<string>>(
            (acc, curr, index) =>
                placesIndexes
                    .includes(index)
                    ? [...acc, delimiter, curr]
                    : [...acc, curr]
            , []
        )
        .join('')

    return numberWithDelimiter
}


export const formatToYear = (number: number): string => {
    if (number <= 0) {
        return ""
    }

    return (number < 9999)
        ? `${number}`
        : `${number}`.slice(0, 4)
}

export const formatToMonth = (number: number): string => {
    if (number <= 0) {
        return ""
    }

    return (number <= 12)
        ? `${number}`
        : `12`
}

export const formatToDay = (number: number): string => {
    // console.log(number)

    if (number <= 0) {
        return ""
    }

    return (number <= 31)
        ? `${number}`
        : `31`
}
