import {Button, Card, CardContent, InputBase, Stack, Typography} from "@mui/material";
import {FullSizeImage} from "../../abs/base/FullSizeImage";
import {createStyles, makeStyles} from "@material-ui/styles";
import {BookEntity} from "../../../../domain/entities/book/BookEntity";
import {textColorGray} from "../../../themes/Color";
import { AnswerEntity } from "../../../../domain/entities/message/AnswerEntity";


const useStyles = makeStyles(() => createStyles({
    inputContainer: {
        borderRadius: "4px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
        height: "100%"
    },
    inputBase: {
        padding: "0.4rem",
        height: "100%",
        alignItems: "flex-start",

        fontSize: "19px",
        lineHeight: "26px",
        letterSpacing: "-0.68px",
        color: textColorGray
    }
}))


export type QuestionBodyItemProps = {
    book: BookEntity,
    content: string,
    setContent: (value: string) => void,
    readonly?: boolean,
    greeting?: boolean,
}

export const QuestionBodyItem = (
    {
        book,
        content,
        setContent,
        readonly = false,
        greeting
    }: QuestionBodyItemProps
) => {
    const styles = useStyles()

    //

    return <>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
            <Stack width={"50%"} py={"1rem"}>
                <Card elevation={1}>
                    <FullSizeImage src={book.thumbnailUrl}/>
                </Card>
            </Stack>

            <Stack width={"55%"} height={"100%"} ml={"-25%"} zIndex={1}>
                <Card className={styles.inputContainer}>
                    <CardContent>
                    <InputBase
                        placeholder={"직접 적어보세요! 감이 잡히지 않으면 하단에서 도움을 받아도 좋아요."}
                        multiline={true}
                        className={styles.inputBase}
                        value={content}
                        onChange={e => setContent(e.target.value)}
                        readOnly={readonly}
                    />
                    </CardContent>
                    
                </Card>
            </Stack>
        </Stack>
    </>
}
