import { createTheme } from "@mui/material";
import {
  primaryColor,
  primaryVariantColor,
  textColorLightBlack,
} from "./Color";

export const PAGE_PADDING = "1rem";
export const MAX_WIDTH = "576px";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: primaryVariantColor,
    },
  },
  typography: {
    fontFamily: "Noto Sans KR",
    h1: {
      fontWeight: "bold",
    },
    h2: {
      fontWeight: "bold",
    },
    h3: {
      fontWeight: "bold",
    },
    h4: {
      fontWeight: "bold",
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      fontWeight: 500,
    },
    subtitle2: {
      fontWeight: 500,
    },
    body1: {},
    body2: {},
    caption: {
      fontSize: "13px",
    },
    overline: {},
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none !important",
        },
        outlined: {
          border: "1px solid #ddd",
          "&:hover": {
            border: "1px solid #ddd",
          },
          "&:focus": {
            border: "1px solid #ddd",
            backgroundColor: "inherit",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: textColorLightBlack,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiTabs-flexContainer": {
            gap: "1rem",
          },
          "& .MuiButtonBase-root": {
            minWidth: 0,
            padding: "12px 4px",
            fontWeight: "bold",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: "4px 6px 6px 6px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#efefef",
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          "@media (min-width: 600px)": {
            padding: "0 16px",
            left: 0,
            right: 0,
            "& .MuiSnackbar-root": {
              left: 0,
              right: 0,
            },
          },
          "& .MuiPaper-root": {
            width: "100%",
          },
        },
      },
    },
  },
});

export enum themes { //다크모드 추가 가능
  DEFAULT,
}

const changeTheme = (theme: themes) => {
  switch (theme) {
    default:
      return defaultTheme;
  }
};

export default changeTheme;
