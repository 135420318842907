import {Stack} from "@mui/material";
import {VerticalScrollableStack} from "../../../../../../components/abs/base/Scrollable";
import {AddReviewDialogHeader} from "./sections/header/AddReviewDialogHeader";
import {AddReviewTargetBookSection} from "./sections/target/AddReviewTargetBookSection";
import {ReviewBodySection, useReviewBodySection} from "../../../../../../components/context/molecules/ReviewBodySection";
import {AddReviewDialogBottom} from "./sections/bottom/AddReviewDialogBottom";
import SearchBookDialog from "../../../../SearchBookDialog";
import React, {useEffect, useState} from "react";
import {BookEntity} from "../../../../../../../domain/entities/book/BookEntity";
import {ReviewEntity} from "../../../../../../../domain/entities/review/ReviewEntity";
import {useMyBooks} from "../../../../../../../redux/features/remote/books/BookHooks";
import { BookmarkTypes } from "../../../../../../../domain/types/BookmarkTypes";
import { SessionPropertyKey } from "../../../../../../../session/SessionPropertyKey";
import { useBooksState } from "../../../../../../../redux/features/remote/books/BooksSlice";
import { useMyInfo } from "../../../../../../../redux/features/remote/users/myInfo/MyInfoSlice";
import { useReviewsState } from "../../../../../../../redux/features/remote/reviews/slice/ReviewsSlice";
import { useReviewEditDialogSlice } from "../../../../../../../redux/features/local/dialogs/review/edit/ReviewEditDialogSlice";
import { useReviewDetailDialogSlice } from "../../../../../../../redux/features/local/dialogs/review/detail/ReviewDetailDialogSlice";

export type WriteReviewData = {
    book: BookEntity,
    title: string,
    content: string,
    isPublic: boolean
}

export const WriteReviewSection = (
    {onClose, onSubmit, oldReview}: {
        onClose: () => void,
        onSubmit: (data: WriteReviewData) => void,
        oldReview?: ReviewEntity
    }
) => {
    const [openSearchDialog, setOpenSearchDialog] = useState(false)

    const [isPublic, setIsPublic] = useState(true)

    const myId = sessionStorage.getItem(SessionPropertyKey.USER_ID) ?? "-1";

    const {data: books} = useBooksState()
    const {data: myInfo} = useMyInfo()
    const {data: reviewDB} = useReviewsState()
    const {mutate: mutateReviewDetailDialog} = useReviewDetailDialogSlice()
    const {close} = useReviewEditDialogSlice()
    const {finishedBooks} = useMyBooks(myInfo)

    const [targetBook, setTargetBook] = useState<BookEntity>()

    useEffect(()=>{
        if (oldReview) setIsPublic(oldReview.isPublic);
    }, [])

    const handleBookSelect = (book: BookEntity) => {
        const review = reviewDB.find(it => it.id === myId+book.bookId)
        if(review){
            if(window.confirm("이미 이 책에 대해 작성한 글이 있습니다. 불러올까요?")){
                setOpenSearchDialog(false)
               close()
               mutateReviewDetailDialog({
                   review: review,
                   isMyReview: true,
                   isOpen: true
               })
            }
        } else{
            setTargetBook(book)
            setOpenSearchDialog(false)
        }
    }

    const handleTempSave = () => { //추후에 추가하기
        sessionStorage.setItem(SessionPropertyKey.TS_TITLE, title)
        sessionStorage.setItem(SessionPropertyKey.TS_CONTENT, content)
        alert("임시저장")
    }


    const handleAddBookClick = () => {
        setOpenSearchDialog(true)
    }

    const handleChangeTargetBook = () => {
        setOpenSearchDialog(true)
    }

    const {
        title,
        handleChangeTitle,
        content,
        handleChangeContent
    } = useReviewBodySection(
        oldReview?.title ?? "",
        oldReview?.content ?? ""
    )

    const disableTempButton = !title || (content.length < 200)
    const disableSubmitButton = disableTempButton || !targetBook

    useEffect(() => {
        oldReview && setTargetBook(oldReview.targetBook)
    }, [oldReview])

    const handleSubmit = () => {
        if (!targetBook) {
            return
        }

        onSubmit({
            book: targetBook,
            title: title,
            content: content,
            isPublic: isPublic
        })
    }

    const handleToggleReviewPublic = () => {
        setIsPublic(!isPublic)
    }

    //

    return <>
        <Stack height={"100%"}>
            <VerticalScrollableStack width={"100%"} height={"100%"}>
                <AddReviewDialogHeader
                    disableTempButton={disableTempButton}
                    disableSubmitButton={disableSubmitButton}
                    onClose={onClose}
                    onTempSave={handleTempSave}
                    onSubmit={handleSubmit}
                    isPublic={isPublic}
                />

                <AddReviewTargetBookSection
                    book={targetBook}
                    onAddBook={handleAddBookClick}
                    onChangeBook={handleChangeTargetBook}
                    oldReview={oldReview}
                />

                <Stack padding={"1rem"} height={"100%"}>
                    <ReviewBodySection
                        title={title}
                        setTitle={handleChangeTitle}
                        content={content}
                        setContent={handleChangeContent}
                    />
                </Stack>

            </VerticalScrollableStack>

            <AddReviewDialogBottom value={`${content.length}`} isPublic={isPublic} onPublicClick={handleToggleReviewPublic}/>
        </Stack>

        <SearchBookDialog
            isOpen={openSearchDialog}
            setOpen={setOpenSearchDialog}
            onItemClick={handleBookSelect}
            myBooks={finishedBooks}
            type={BookmarkTypes.FINISHED}
        />
    </>
}
