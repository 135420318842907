import {Card, InputBase} from "@mui/material";
import {InputBaseProps} from "@mui/material/InputBase/InputBase";
import styled from "@emotion/styled";
import {textColorDeepGray} from "../../../themes/Color";

const StyledCard = styled(Card)`
  box-shadow: 1px 1px 1px #eee;
`

const StyledInputBase = styled(InputBase)`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: ${textColorDeepGray};
  
  padding: 10px 12px 10px 12px;
`

export const FlatInput = (
    p: InputBaseProps
) => {
    return <>
        <StyledCard elevation={0}>
            <StyledInputBase {...p} />
        </StyledCard>
    </>
}
