import {Button, Card, Stack, Typography} from "@mui/material";
import {BookItem} from "../book/BookItem";
import AspectRatioBox, {RatioType} from "../../abs/molecules/AspectRatioBox";
import {textColorDeepGray, textColorGray} from "../../../themes/Color";
import Spacer from "../../abs/atoms/Spacer";
import styled from "@emotion/styled";
import {BookEntity} from "../../../../domain/entities/book/BookEntity";

const ContentBody = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`


const BookThumbnailSection = (
    {thumbnailUrl}: { thumbnailUrl: string }
) => {
    return <>
        <Stack
            width={"100%"}
            bgcolor={"#e5e5ee"}
            overflow={"hidden"}
            sx={{
                borderRadius: "8px"
            }}
        >
            <AspectRatioBox ratio={RatioType["35%"]}>
                <Stack alignItems={"center"} height={"100%"} justifyContent={"flex-end"} mt={"3%"}>
                    <BookItem
                        imageUrl={thumbnailUrl}
                        width={"25%"}
                    />
                </Stack>
            </AspectRatioBox>
        </Stack>
    </>
}

const BookInfoWithBookmarkBtn = (
    {book, onBookmark}: {
        book: BookEntity,
        onBookmark: (book: BookEntity) => void
    }
) => {
    return <>
        <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack>
                <Typography
                    variant={"subtitle2"}
                    fontSize={"16px"}
                    color={textColorDeepGray}
                    children={book.title}
                />
                <Typography
                    variant={"caption"}
                    fontSize={"12px"}
                    color={textColorDeepGray}
                    children={book.authors.join(', ')}
                />
            </Stack>
            <Stack>
                <Button
                    size={"small"}
                    variant={"outlined"}
                    children={"읽고 싶어요"}
                    sx={{borderRadius: "36px", whiteSpace: "nowrap"}}
                    onClickCapture={(e) => {
                        onBookmark(book)
                        e.stopPropagation()
                    }}
                />
            </Stack>
        </Stack>
    </>
}

//

export type BookCardItemProps = {
    book: BookEntity,
    onBookClick: (book: BookEntity) => void,
    onBookmark: (book: BookEntity) => void
}

export const BookCardItem = (
    {book, onBookClick, onBookmark}: BookCardItemProps
) => {

    return <>
        <Button
            sx={{
                p: 0,
                width: "fit-content",
                height: "fit-content",
                textAlign: "start",
            }}
            disableRipple={true}
            onClick={() => onBookClick(book)}
        >
            <Card>
                <Stack>
                    <Stack p={"4px"}>
                        <BookThumbnailSection thumbnailUrl={book.thumbnailUrl}/>
                    </Stack>
                    <Spacer h={"12px"}/>

                    <Stack px={"1rem"}>
                        <BookInfoWithBookmarkBtn
                            book={book}
                            onBookmark={onBookmark}
                        />
                    </Stack>
                    <Spacer h={"1rem"}/>

                    <Stack px={"1rem"}>
                        <ContentBody
                            variant={"caption"}
                            color={textColorGray}
                            children={book.description}
                        />
                    </Stack>
                    <Spacer h={"20px"}/>

                </Stack>
            </Card>
        </Button>
    </>
}
