import {withStyles} from "@material-ui/styles";
import {MAX_WIDTH} from "../../../themes/CustomThemes";
import {Drawer} from "@mui/material";

const StyledDrawer = withStyles({
    root: {
        overflow: "hidden",
        zIndex: 9999,

        "&>.MuiPaper-root": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",

            maxWidth: MAX_WIDTH,
            margin: "0 auto"
        }
    }
})(Drawer)

export default StyledDrawer
