import {UserEntity} from "../user/UserEntity";
import {BookEntity} from "../book/BookEntity";
import {GroupedMessagesByDate} from "../message/GroupedMessageEntity";
import {MessageEntity} from "../message/MessageEntity";

export type ChatRoom = {
    readonly id: string,
    readonly usersId: Array<string>,
    readonly messages: Array<GroupedMessagesByDate>,
    readonly sharedBook: BookEntity,
    readonly regDate: string
}

export const getFlattenMessages = (messages: Array<GroupedMessagesByDate>) => {
    return messages.flatMap(it =>
        it.messagesByTime.flatMap(it =>
            it.messagesByUserId.flatMap(it => it.messages)
        )
    )
}

export const getUnreadMessages = (messages: Array<MessageEntity>, readUserId: string) => {
    return messages.filter(it =>
        !(it.userIdWhoRead.some(it => it === readUserId))
    )
}

