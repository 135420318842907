import { ref, uploadString } from "firebase/storage";
import { storage } from "../../firebase-config";

export const checkFileType = (file: File, type: string): boolean => {
  return file.type.includes(type);
};

export const isFileTypeImage = (file: File): boolean =>
  checkFileType(file, "image");

export const isFileTypeImageOrVideo = (file: File): boolean => {
  const checkTypes = ["image", "video"];

  return checkTypes.some((checkType) => checkFileType(file, checkType));
};

export const inputFileToUrl = (file: File, callback: (url: string) => void) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    const fileUrl = (e.target?.result as string) ?? "";
    callback(fileUrl);
  };

  reader.readAsDataURL(file);
};

export const urlToDataUrl = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};
