import { BookmarkTypes } from './../../../../domain/types/BookmarkTypes';
import { BookDetailActionType } from './../../../../ui/dialogs/childs/book/detail/ButtonActionsCase';
import React from 'react'
import { arrayUnion, collection, doc, getDoc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import { BookEntity } from "../../../../domain/entities/book/BookEntity";
import { db } from "../../../../firebase-config";
import { pullMyPageBooks } from '../books/MyPageBooksSlice';
import { ReviewEntity } from '../../../../domain/entities/review/ReviewEntity';
import { Bookmark } from '../../../../domain/vo/Bookmarks';
import { UserEntity } from '../../../../domain/entities/user/UserEntity';


export const AddBookmark = async (myInfo: UserEntity, bookDB: Array<BookEntity>, book: BookEntity, type: number) => {
  
  //나의 책 추출
  const myBookmarks = myInfo.bookmarks.filter(bookmark=>bookmark.type === type)

  if(myInfo.bookmarks.some(it => it.book.bookId === book.bookId && it.type === type)){
    alert("이미 등록되어있는 책입니다.");
    const response = false;
    return response;
  }

  if(myBookmarks.length >= 10) {
    alert("책은 최대 10개까지 등록 가능합니다.");
    const response = false;
    return response;
  }
  
  const userRef = doc(db, "users", myInfo.uid);

  let newBookDB = [...bookDB]
  if (!bookDB.some(it => it.bookId === book.bookId)){ //DB에 책 정보가 없을 때
    newBookDB = [...newBookDB, book]
    const bookRef = doc(db, "books", book.bookId);
    await setDoc(bookRef, book)
  }

  const newMyInfo = {...myInfo}
  if(type === BookmarkTypes.FINISHED){ //읽은 책
    if(myInfo.bookmarks.some(bookmark => bookmark.book.bookId === book.bookId && bookmark.type === BookmarkTypes.WISH)){ //읽고 싶은 책 -> 읽은 책
      newMyInfo.bookmarks = [...myInfo.bookmarks.filter(bookmark => bookmark.book.bookId !== book.bookId), {book: book, type: BookmarkTypes.FINISHED}]
      await updateDoc(userRef, {
        bookmarks: newMyInfo.bookmarks
      })
    } else { // Direct 읽은 책 추가
      newMyInfo.bookmarks = [...newMyInfo.bookmarks, {book: book, type: BookmarkTypes.FINISHED}]
      await updateDoc(userRef, {
        bookmarks: arrayUnion({book: book, type: BookmarkTypes.FINISHED})
      });
    }
  } else { //읽고 싶은 책
    if(myInfo.bookmarks.some(bookmark => bookmark.book.bookId === book.bookId && bookmark.type === BookmarkTypes.FINISHED)){ //읽은 책 -> 읽고 싶은 책
      newMyInfo.bookmarks = [...myInfo.bookmarks.filter(bookmark => bookmark.book.bookId !== book.bookId), {book: book, type: BookmarkTypes.WISH}]
      await updateDoc(userRef, {
        bookmarks: newMyInfo.bookmarks
      })
      alert("읽은 책에 등록된 책을 읽고 싶은 책으로 옮겼습니다.")
    } else {
      newMyInfo.bookmarks = [...newMyInfo.bookmarks, {book: book, type: BookmarkTypes.WISH}]
      await updateDoc(userRef, {
        bookmarks: arrayUnion({book: book, type: BookmarkTypes.WISH})
      });
    }
  }

  return {newMyInfo, newBookDB}
};


export const DeleteBookmark = async (book: BookEntity, myInfo: UserEntity) => {
  const newMyInfo = { ...myInfo}
  newMyInfo.bookmarks = newMyInfo.bookmarks.filter(bookmark => bookmark.book.bookId !== book.bookId)

  const userRef = doc(db, "users", myInfo.uid);
  await updateDoc(userRef, {
    bookmarks: newMyInfo.bookmarks
  })

  return newMyInfo;
}