import {useDispatch, useSelector} from "react-redux";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../../../store/QihanoStore";
import {initialUserEntity, UserEntity} from "../../../../../domain/entities/user/UserEntity";

type ProfileEditDialogParams = {
    isOpen: boolean,
    user: UserEntity
}

const initialState: ProfileEditDialogParams = {
    isOpen: false,
    user: initialUserEntity
}

const ProfileEditDialogSlice = createSlice({
    name: 'profileEditDialog',
    initialState: initialState,
    reducers: {
        setProfileEditDialogState: (state, action: PayloadAction<ProfileEditDialogParams>) =>
            action.payload,
    }
})

export const useProfileEditDialogSlice = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.profileEditDialog)

    const mutate = (state: ProfileEditDialogParams) => {
        dispatch(setProfileEditDialogState(state))
    }

    const close = () => {
        mutate({
            ...data,
            isOpen: false
        })
    }

    return {
        close,
        mutate,
        data
    }
}

export const {setProfileEditDialogState} = ProfileEditDialogSlice.actions
export default ProfileEditDialogSlice.reducer
