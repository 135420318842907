import {InputBase, Stack} from "@mui/material";
import Spacer from "../../abs/atoms/Spacer";
import styled from "@emotion/styled";
import {ChangeEvent, useState} from "react";
import {textColorGray} from "../../../themes/Color";


const TitleInput = styled(InputBase)`
  font-size: 22px;
  font-weight: 500;
`

const ContentInput = styled(InputBase)`
  font-size: 14px;
  color: ${textColorGray};
  height: 100%;
  text-align: start;
  align-items: flex-start;
`

export const useReviewBodySection = (
    defaultTitle: string,
    defaultContent: string
) => {
    const [title, setTitle] = useState(defaultTitle)
    const [content, setContent] = useState(defaultContent)

    const handleChangeTitle = (value: string) => {
        if (title.length >= 50) {
            setTitle(value.slice(0, 50))
            return
        }

        setTitle(value)
    }

    const handleChangeContent = (value: string) => {
        if (content.length >= 800) {
            setContent(value.slice(0, 800))
            return
        }

        setContent(value)
    }

    return {
        title,
        handleChangeTitle,
        content,
        handleChangeContent
    }
}


export type ReviewBodySectionProps = {
    title: string,
    content: string,
    setTitle?: (title: string) => void,
    setContent?: (title: string) => void,
    readonly?: boolean,
    disableTitle?: boolean
}

export const ReviewBodySection = (
    {
        title,
        setTitle,
        content,
        setContent,
        readonly = false,
        disableTitle,
    }: ReviewBodySectionProps
) => {

    const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle && setTitle(e.target.value)
    }

    const handleContentChange = (e: ChangeEvent<HTMLInputElement>) => {
        setContent && setContent(e.target.value)
    }

    //

    return <>
        <Stack position={"relative"} height={"100%"}>
            <Stack height={"100%"}>
                {!disableTitle &&
                <>
                    <TitleInput
                        placeholder={"제목"}
                        value={title}
                        multiline={readonly}
                        onChange={handleTitleChange}
                        readOnly={readonly}
                    />
                    <Spacer h={"0.5rem"}/>
                </>
                }

                <ContentInput
                    placeholder={"이 책에 대한 내 생각을 적어보세요 (200자 이상)"}
                    multiline={true}
                    value={content}
                    onChange={handleContentChange}
                    readOnly={readonly}
                />
            </Stack>
        </Stack>
    </>
}
