import {useDispatch, useSelector} from "react-redux";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ProfileDetailDialogProps} from "../../../../../ui/dialogs/childs/profile/detail/ProfileDetailDialog";
import {RootState} from "../../../../store/QihanoStore";
import {initialUserEntity} from "../../../../../domain/entities/user/UserEntity";


const initialState: ProfileDetailDialogProps = {
    isOpen: false,
    user: initialUserEntity
}

const ProfileDetailDialogSlice = createSlice({
    name: 'profileDetailDialog',
    initialState: initialState,
    reducers: {
        setProfileDetailDialogState: (state, action: PayloadAction<ProfileDetailDialogProps>) =>
            action.payload,
    }
})

export const useProfileDetailDialogSlice = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.profileDetailDialog)

    const mutate = (state: ProfileDetailDialogProps) => {
        dispatch(setProfileDetailDialogState(state))
    }

    const close = () => {
        mutate({
            ...data,
            isOpen: false
        })
    }


    return {
        close,
        mutate,
        data
    }
}

export const {setProfileDetailDialogState} = ProfileDetailDialogSlice.actions
export default ProfileDetailDialogSlice.reducer
