import { SlideUpTransition } from "../../animations/Transitions";
import {
  AppBar,
  Button,
  IconButton,
  InputBase,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { useState } from "react";
import { BookEntity } from "../../../domain/entities/book/BookEntity";
import BookWithInfo from "../../components/context/book/BookWithInfo";
import { v4 } from "uuid";
import { textColorGray } from "../../themes/Color";
import Spacer from "../../components/abs/atoms/Spacer";
import { searchKakaoBook } from "../../../redux/features/remote/books/BookApi";
import { ReactComponent as SearchIcon } from "../../../icons/icon_search.svg";
import QihanoDialog from "../../components/context/base/QihanoDialog";

const HeaderWithSearchBar = ({
  searchWord,
  setSearchWord,
  onClose,
  onSearchClick,
}: {
  searchWord: string;
  setSearchWord: (text: string) => void;
  onClose: () => void;
  onSearchClick: (text: string) => void;
}) => {
  const disableSearchIcon = searchWord === "";

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearchClick(searchWord);
    }
  };

  //

  return (
    <>
      <AppBar position={"relative"} color={"transparent"} elevation={1}>
        <Toolbar>
          <IconButton
            edge="start"
            onClick={onClose}
            children={<CloseRoundedIcon htmlColor={"black"} />}
          />
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="책제목/저자/출판사를 검색해보세요"
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            onKeyUp={handleKeyUp}
            autoFocus
          />
          <IconButton
            disabled={disableSearchIcon}
            onClick={() => onSearchClick(searchWord)}
            children={<SearchIcon />}
          />
        </Toolbar>
      </AppBar>
    </>
  );
};

const SearchBookDialog = ({
  isOpen,
  setOpen,
  onItemClick,
  myBooks,
  type,
}: {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  onItemClick: (book: BookEntity, type: number) => void;
  myBooks?: Array<BookEntity>;
  type: number;
}) => {
  const [searchWord, setSearchWord] = useState("");
  const [resultBooks, setResultBooks] = useState<Array<BookEntity>>([]);

  const handleBookClick = (book: BookEntity) => {
    if(book.title.indexOf('(') !== -1){
      const newBookState = {...book, title: book.title.slice(0, book.title.indexOf('('))};
      onItemClick(newBookState, type);
    } else {
      onItemClick(book, type);
    }
    setSearchWord("");
    setResultBooks([]);
  };

  const handleSearchBook = (text: string) => {
    searchKakaoBook(text)
      .then((response) => {
        setResultBooks(response);
      })
      .catch(e => console.log(e));
  };

  const handleClose = () => {
    setOpen(false);
  };

  //

  const renderMyBooksSection = resultBooks.length === 0 && myBooks && myBooks.length !== 0 && (
    <Stack>
      <Typography
        variant={"caption"}
        color={textColorGray}
        fontWeight={500}
        children={"내 서재의 책"}
      />
      <Spacer h={"1rem"} />

      {myBooks.map((book) => (
        <Button
          key={v4()}
          fullWidth
          onClick={() => handleBookClick(book)}
          children={<BookWithInfo book={book} />}
          sx={{ mb: 2 }}
        />
      ))}
    </Stack>
  );

  const renderResultBooks = resultBooks.map((book) => (
    <Button
      key={v4()}
      fullWidth
      onClick={() => handleBookClick(book)}
      children={<BookWithInfo book={book} />}
      sx={{ mb: 2 }}
    />
  ));

  return (
    <>
      <QihanoDialog
        fullScreen={true}
        open={isOpen}
        TransitionComponent={SlideUpTransition}
      >
        <Stack width={"100%"} height={"100%"}>
          <HeaderWithSearchBar
            searchWord={searchWord}
            setSearchWord={setSearchWord}
            onClose={handleClose}
            onSearchClick={handleSearchBook}
          />

          <Stack width={"100%"} padding={"1.5rem 1rem"}>
            {renderMyBooksSection}

            {renderResultBooks}
          </Stack>
        </Stack>
      </QihanoDialog>
    </>
  );
};

export default SearchBookDialog;
