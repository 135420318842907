import React from 'react';
import {Button, Chip, Stack, Typography} from "@mui/material";
import SimpleHeaderSection from "../../../components/abs/organisms/SimpleHeaderSection";
import {useHistory} from "react-router";
import Spacer from "../../../components/abs/atoms/Spacer";
import {SignupPath} from "../../../../router/RouterPath";
import {textColorDeepGray, textColorGray} from "../../../themes/Color";
import {v4} from "uuid";
import {VerticalScrollableStack} from "../../../components/abs/base/Scrollable";
import {useSignupState} from "../../../../redux/features/local/signup/SignupSlice";
import {Interest} from "../../../../domain/types/Interests";
import {MAX_INTEREST_COUNT} from "../../../../domain/policy/interests.policy";

const interestModel = [
    {
        title: "회원님의 관심사와 맞는 사람을 찾아드릴게요",
        data: Object.values(Interest)
    },
    // {
    //     title: "저는 이렇게 읽어요",
    //     data: Object.values(Habit)
    // },
    // {
    //     title: "저는 이런 감성이 좋아요",
    //     data: Object.values(Emotion)
    // },
    // {
    //     title: "저는 이런 생각을 가지고 있어요",
    //     data: Object.values(ThinkingAboutBooks)
    // }
]

//

export const useInterest = () => {
    const history = useHistory()

    const {data, mutate} = useSignupState()
    const {interests: selectedChips} = data // selectedChips는 이해하기 편하라고 이름 지어준 거

    const isEmptySelectedChips = selectedChips.length <= 0
    const isOverMaxCount = selectedChips.length >= MAX_INTEREST_COUNT

    const isSelectedChip = (interest: Interest) =>
        selectedChips.some((it) => it === interest)

    const handleChipClick = (interest: Interest) => {
        const oldTarget =
            selectedChips.find((it) => it === interest)

        const newSignupState = {...data}
        if (oldTarget) {
            newSignupState.interests =
                selectedChips.filter((it) => it !== oldTarget)

            mutate(newSignupState)
            return
        }

        if (isOverMaxCount) {
            return
        }

        newSignupState.interests = [...selectedChips, interest]
        mutate(newSignupState)
    }

    const handleBack = () => {
        history.goBack()
    }

    const handleSkip = () => {
        const newSignupState = {...data}
        newSignupState.interests = []
        mutate(newSignupState)
        history.push(SignupPath.REPRESENT_BOOK)
    }

    const handleSubmit = () => {
        history.push(SignupPath.REPRESENT_BOOK)
    }

    return {
        selectedChips,
        isEmptySelectedChips,
        isOverMaxCount,
        isSelectedChip,
        handleChipClick,
        handleBack,
        handleSkip,
        handleSubmit
    }
}

const InterestPage = () => {
    const {
        selectedChips,
        isEmptySelectedChips,
        isOverMaxCount,
        isSelectedChip,
        handleChipClick,
        handleBack,
        handleSkip,
        handleSubmit
    } = useInterest()

    //

    const renderInterests =
        interestModel.map(({title, data}) =>
            <Stack key={v4()}>
                <Typography
                    variant={"caption"}
                    color={textColorGray}
                    fontWeight={500}
                    children={title}
                />
                <Spacer h={"12px"}/>

                <Stack direction={"row"} gap={"0.5rem"} flexWrap={"wrap"}>
                    {Object
                        .values(data)
                        .map((it) =>
                            <Chip
                                key={v4()}
                                clickable={isOverMaxCount ? (isSelectedChip(it)) : true}
                                label={it}
                                variant={isSelectedChip(it) ? "filled" : "outlined"}
                                color={isSelectedChip(it) ? "secondary" : "default"}
                                onClick={() => handleChipClick(it)}
                            />
                        )
                    }
                </Stack>

                <Spacer h={"2rem"}/>
            </Stack>
        )


    return <>
        <Stack height={"100%"}>
            <VerticalScrollableStack height={"100%"}>

                <Stack>
                    <SimpleHeaderSection
                        titleTextComponent={"내 취향은"}
                        onClose={handleBack}
                        optionComponent={
                            <Button onClick={handleSkip}>
                                <Typography
                                    variant={"caption"}
                                    color={textColorDeepGray}
                                    children={"건너뛰기"}
                                />
                            </Button>
                        }
                    />
                    <Spacer h={"8px"}/>

                    <Stack paddingX={"1rem"}>
                        <Typography
                            variant={"caption"}
                            color={textColorGray}
                            children="나를 잘 나타낼 수 있는 독서키워드를 골라주세요"
                        />
                    </Stack>
                </Stack>
                <Spacer h={"34px"}/>

                <Stack height={"100%"} padding={"1rem"}>
                    {renderInterests}
                </Stack>

            </VerticalScrollableStack>

            <Stack px={"1rem"} pb={"1rem"}>
                <Button
                    variant="contained"
                    color="primary"
                    size={"large"}
                    fullWidth
                    disabled={isEmptySelectedChips}
                    children={`계속 (${selectedChips.length}/${MAX_INTEREST_COUNT})`}
                    onClick={handleSubmit}
                />
            </Stack>
        </Stack>
    </>
}

export default InterestPage
