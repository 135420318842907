import { Button, Stack, TextField, Typography } from "@mui/material";
import { SlideUpTransition } from "../../../../../animations/Transitions";
import React, { ChangeEvent, useState } from "react";
import { VerticalScrollableStack } from "../../../../../components/abs/base/Scrollable";
import QihanoDialog from "../../../../../components/context/base/QihanoDialog";
import Spacer from "../../../../../components/abs/atoms/Spacer";
import SimpleHeaderSection from "../../../../../components/abs/organisms/SimpleHeaderSection";
import { textColorGray } from "../../../../../themes/Color";

export type UpdateSelfIntroDialogProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  defaultValue: string;
  onSubmitClick: (value: string) => void;
};

const SELFINTRO_MAX_LENGTH = 100;

const UpdateSelfIntroDialog = ({
  isOpen,
  setOpen,
  defaultValue,
  onSubmitClick,
}: UpdateSelfIntroDialogProps) => {
  const [selfIntro, setSelfIntro] = useState(defaultValue);

  const handleBack = () => {
    setOpen(false);
  };

  const handleSelfIntroChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length >= SELFINTRO_MAX_LENGTH) {
        setSelfIntro(e.target.value.slice(0, SELFINTRO_MAX_LENGTH))
        return
    }
    setSelfIntro(e.target.value);
  };

  const handleSubmit = () => {
    onSubmitClick(selfIntro);
  };

  //

  return (
    <QihanoDialog
      fullScreen={true}
      open={isOpen}
      TransitionComponent={SlideUpTransition}
    >
      <VerticalScrollableStack height={"100%"}>
        <Stack>
          <SimpleHeaderSection
            titleTextComponent={"자기소개"}
            onClose={handleBack}
          />
          <Spacer h={"8px"} />

          <Stack paddingX={"1rem"}>
            <Typography
              variant={"caption"}
              color={textColorGray}
              children={"정성을 담아 나를 표현해보세요!"}
            />
          </Stack>
        </Stack>
        <Spacer h={"24px"} />

        <Stack
          height={"100%"}
          px={"1rem"}
          pb={"1rem"}
          justifyContent={"space-between"}
        >
          <Stack>
            <TextField
              variant={"outlined"}
              placeholder={"※ 개인정보를 남길 경우 이용이 제한될 수 있습니다."}
              value={selfIntro}
              onChange={handleSelfIntroChange}
              multiline={true}
              rows={3}
            />
            <Typography align={"right"} variant={"caption"} color={textColorGray}>
              <span>{selfIntro.length}</span>
              {" / "}
              <span>{SELFINTRO_MAX_LENGTH}</span>
            </Typography>
          </Stack>

          <Spacer h={"2rem"} />

          <Button
            size={"large"}
            variant={"contained"}
            children={"확인"}
            onClick={handleSubmit}
          />
        </Stack>
      </VerticalScrollableStack>
    </QihanoDialog>
  );
};

export default UpdateSelfIntroDialog;
