import Spacer from "../../../../../components/abs/atoms/Spacer";
import {Avatar, Button, Stack, Typography} from "@mui/material";
import {textColorDeepGray} from "../../../../../themes/Color";
import React from "react";
import {UserEntity} from "../../../../../../domain/entities/user/UserEntity";

export type ChatHeaderProps = {
    partner: UserEntity,
    onProfile: () => void
}

export const ChatHeader = (
    p: ChatHeaderProps
) => {

    const {partner, onProfile} = p

    //

    return <>
        <Stack width={"100%"}>
            <Spacer h={"1rem"}/>

            <Stack alignItems={"center"}>
                <Avatar
                    src={partner.profileImages[0].url}
                    sx={{
                        width: "56px",
                        height: "56px"
                    }}
                />
                <Spacer h={"0.5rem"}/>

                <Typography
                    variant={"subtitle1"}
                    color={textColorDeepGray}
                    children={partner.nickname}
                />

                <Button
                    color={"secondary"}
                    sx={{
                        width: "fit-content",
                        padding: 0
                    }}
                    onClick={onProfile}
                >
                    <Typography
                        variant={"caption"}
                        children={"프로필 보기"}
                    />
                </Button>
            </Stack>
        </Stack>
    </>
}
