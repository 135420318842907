import React, {ChangeEvent, useState} from 'react';
import {Button, Stack, TextField, Typography} from "@mui/material";
import SimpleHeaderSection from "../../../components/abs/organisms/SimpleHeaderSection";
import {useHistory} from "react-router";
import {textColorGray, textColorLightBlack} from "../../../themes/Color";
import {VerticalScrollableStack} from "../../../components/abs/base/Scrollable";
import Spacer from "../../../components/abs/atoms/Spacer";
import styled from "@emotion/styled";
import {useSignupState} from "../../../../redux/features/local/signup/SignupSlice";
import {formatToBirthDate} from "../../../../utils/formatter/Formatter";
import {SignupPath} from "../../../../router/RouterPath";
import {validateBirthDate} from "../../../../domain/policy/birthDate.policy";

const BirthDateTextField = styled(TextField)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  width: 16rem;

  & .MuiInput-input {
    line-height: 20px;
    letter-spacing: -0.92px;
    font-size: 24px;

    color: ${textColorLightBlack};
  }
`

const BirthDayPage = () => {
    const history = useHistory()

    const {data, mutate} = useSignupState()
    const {birthDay} = data
    const [error, setError] = useState("")

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
          handleSubmit();
        }
      };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const formattedValue = formatToBirthDate(value)

        const newSignupState = {...data}
        newSignupState.birthDay = formattedValue
        mutate(newSignupState)
    }

    const handleBack = () => {
        history.goBack()
    }

    const handleSubmit = () => {
        validateBirthDate(birthDay)
            .then(() => {
                history.push(SignupPath.NAME)
            })
            .catch(e => setError(e.message))

    }

    const isSubmitDisable = (birthDay.length < 12)

    //

    return <>
        <VerticalScrollableStack height={"100%"}>

            <SimpleHeaderSection
                titleTextComponent={"내 생일은"}
                onClose={handleBack}
            />
            <Spacer h={"8px"}/>

            <Stack paddingX={"1rem"}>
                <Typography
                    variant={"caption"}
                    color={textColorGray}
                    children={"입력한 나이는 추후 변경할 수 없습니다."}
                />
            </Stack>
            <Spacer h={"64px"}/>

            <Stack justifyContent={"space-between"} height={"100%"} padding={"1rem"}>

                <BirthDateTextField
                    required
                    variant="standard"
                    autoComplete="off"
                    placeholder={"YYYY. MM. DD"}
                    type="tel"
                    value={birthDay}
                    onChange={handleChange}
                    error={!!error}
                    helperText={!!error && error}
                    onKeyUp={handleKeyUp}
                />
                <Spacer h={"2rem"}/>

                <Button
                    size={"large"}
                    variant="contained"
                    fullWidth
                    disabled={isSubmitDisable}
                    children={"계속"}
                    onClick={handleSubmit}
                />

            </Stack>
        </VerticalScrollableStack>
    </>
}

export default BirthDayPage
