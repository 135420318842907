import {AppBar, IconButton, Stack, Toolbar} from "@mui/material";
import React from "react";
import {CloseRounded,} from "@mui/icons-material";
import {ReactComponent as EditIcon} from "../../../../../../../../../icons/icon_lock_ask.svg";
import {ReactComponent as DeleteIcon} from "../../../../../../../../../icons/icon_delete.svg";
import {textColorGray} from "../../../../../../../../themes/Color";


const Left = (
    {onClose}: { onClose: () => void, }
) => {
    return <>
        <Stack direction={"row"} alignItems={"center"}>
            <IconButton
                edge="start"
                onClick={onClose}
                children={
                    <CloseRounded htmlColor={textColorGray}/>
                }
            />
        </Stack>
    </>
}


type RightProps = {
    onEdit: () => void,
    onDelete: () => void
}

const Right = ({onEdit, onDelete}: RightProps) => {
    return <>
        <Stack direction={"row"} >
            <IconButton
                onClick={onEdit}
                children={
                    <EditIcon fill={textColorGray}/>
                }
            />

            <IconButton
                onClick={onDelete}
                children={
                    <DeleteIcon fill={textColorGray}/>
                }
            />
        </Stack>
    </>
}


export type MyReviewDetailHeaderProps = {
    onClose: () => void,
    isMyOldReview?: boolean
} & RightProps

export const MyReviewDetailHeader = (p: MyReviewDetailHeaderProps) => {
    return <>
        <AppBar position={"relative"} color={"transparent"} elevation={0}>
            <Toolbar sx={{justifyContent: "space-between"}}>
                <Left {...p}/>
                {p.isMyOldReview?<></>:<Right {...p}/>}
            </Toolbar>
        </AppBar>
    </>
}
