import {Button, Divider, Stack, Typography} from "@mui/material";
import {textColorGray, textDisabledColor} from "../../../../../themes/Color";
import Spacer from "../../../../../components/abs/atoms/Spacer";
import {BookItem} from "../../../../../components/context/book/BookItem";
import React from "react";
import {BookEntity} from "../../../../../../domain/entities/book/BookEntity";

export type ChatSharedBookSectionProps = {
    book: BookEntity,
    onBookClick: (book: BookEntity) => void
}

export const ChatSharedBookSection = (
    {book, onBookClick}: ChatSharedBookSectionProps
) => {
    return <>
        <Stack direction={"row"}>
            <Divider orientation={"vertical"} sx={{mr: "1rem"}}/>

            <Stack>
                <Typography
                    variant={"caption"}
                    color={textDisabledColor}
                    fontWeight={500}
                    children={"함께 읽은 책은"}
                />
                <Typography
                    variant={"caption"}
                    color={textColorGray}
                    fontWeight={500}
                    children={`${book.title} ･ ${book.authors.join(', ')}`}
                />
                <Spacer h={"1rem"}/>

                <Button
                    sx={{
                        padding: 0,
                        justifyContent: "flex-start",
                        width: "15%"
                    }}
                    onClick={() => onBookClick(book)}
                >
                    <BookItem imageUrl={book.thumbnailUrl}/>
                </Button>
            </Stack>
        </Stack>
    </>
}
