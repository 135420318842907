import styled from "@emotion/styled";

interface SpacerModel {
    w?: string,
    h?: string
}

const Spacer = styled.div<SpacerModel>`
  min-width: ${(props) => props.w};
  min-height: ${(props) => props.h}
`

export default Spacer
