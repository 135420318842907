import React, { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Button, Divider, Stack, TextField, Typography } from "@mui/material";
import SimpleHeaderSection from "../../../components/abs/organisms/SimpleHeaderSection";
import Spacer from "../../../components/abs/atoms/Spacer";
import { PAGE_PADDING } from "../../../themes/CustomThemes";
import { textDisabledColor } from "../../../themes/Color";
import SocialLoginButton from "../../../components/context/atoms/SocialLoginButton";
import { VerticalScrollableStack } from "../../../components/abs/base/Scrollable";
import { SignupPath } from "../../../../router/RouterPath";
import { useSignupState } from "../../../../redux/features/local/signup/SignupSlice";
import { initialSignupFormData } from "../../../../domain/vo/SignupFormData";
import GeneralPath from "../../../../router/RouterPath";
import { textColorDeepGray } from "../../../themes/Color";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../../firebase-config";
import { SessionPropertyKey } from "../../../../session/SessionPropertyKey";

const LoginPage = () => {
  const history = useHistory();

  const { data, mutate } = useSignupState();

  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");

  const handleEmailLogin = () => {
    // const newSignupState = { ...data };
    // newSignupState.email = email;
    // mutate(newSignupState);
    signInWithEmailAndPassword(auth, email, pw).then((userCredential) => {
      const user = userCredential.user;
      sessionStorage.setItem(SessionPropertyKey.USER_ID, user.uid);
      window.location.replace(GeneralPath.MAIN);
    })
    .catch((error) => {
      const errorCode = error.code;
      console.log(error.message);
    });
  };

  useEffect(() => {
    mutate(initialSignupFormData);
  }, [mutate]);

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePwChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPw(e.target.value);
  };

  const handleFindPassword = () => {
    history.push(GeneralPath.UPDATE_PASSWORD);
  };

  const handleSignup = () => {
    history.push(SignupPath.TERMS);
  }

  const handleBack = () => {
    history.goBack();
  };

  const handleKakaoLogin = () => {
    alert("kakao login");
  };

  const handleNaverLogin = () => {
    alert("naver login");
  };

  const handleGoogleLogin = () => {
    alert("google login");
  };

  const handleAppleLogin = () => {
    alert("apple login");
  };

  //

  const renderLoginForm = (
    <Stack>
      <TextField
        size={"small"}
        variant={"outlined"}
        placeholder={"이메일"}
        type={"email"}
        value={email}
        onChange={handleEmailChange}
      />
      <Spacer h={"1rem"} />
      <TextField
        size={"small"}
        placeholder={"비밀번호"}
        type="password"
        variant="outlined"
        autoComplete="off"
        value={pw}
        onChange={handlePwChange}
      />
      <Spacer h={"1rem"} />
      <Button
        variant={"contained"}
        size={"large"}
        children={"로그인"}
        onClick={handleEmailLogin}
      />
      <Stack direction={"row"} width={"100%"} alignItems={"center"}>
        <Button
          sx={{ width: "fit-content", padding: 1 }}
          onClick={handleFindPassword}
        >
          <Typography
            variant={"caption"}
            color={textColorDeepGray}
            fontWeight={"500"}
            children={"비밀번호를 잊으셨나요?"}
            sx={{ textDecoration: "underline" }}
          />
        </Button>
        <Button
          sx={{ width: "fit-content", padding: 1 }}
          onClick={handleSignup}
        >
          <Typography
            variant={"caption"}
            color={textColorDeepGray}
            fontWeight={"500"}
            children={"회원가입"}
            sx={{ textDecoration: "underline" }}
          />
        </Button>
      </Stack>
    </Stack>
  );

  const renderSectionDivider = (
    <Stack direction={"row"} width={"100%"} alignItems={"center"}>
      <Divider sx={{ flexGrow: 1 }} />
      <Typography
        variant={"caption"}
        color={textDisabledColor}
        fontWeight={500}
        children={"또는"}
        paddingX={"1rem"}
      />
      <Divider sx={{ flexGrow: 1 }} />
    </Stack>
  );

  const renderSocialLoginButtons = (
    <Stack>
      <SocialLoginButton
        model={{
          icon: <img src="/assets/icons/logo-kakao.svg" alt="kakao" />,
          text: "카카오로 계속하기",
        }}
        onClick={handleKakaoLogin}
        variant={"outlined"}
      />
      <Spacer h={"1rem"} />

      <SocialLoginButton
        model={{
          icon: <img src="/assets/icons/logo-naver.svg" alt="naver" />,
          text: "네이버로 계속하기",
        }}
        onClick={handleNaverLogin}
        variant={"outlined"}
      />
      <Spacer h={"1rem"} />

      <SocialLoginButton
        model={{
          icon: <img src="/assets/icons/logo-google.svg" alt="google" />,
          text: "구글로 계속하기",
        }}
        onClick={handleGoogleLogin}
        variant={"outlined"}
      />
      <Spacer h={"1rem"} />

      <SocialLoginButton
        model={{
          icon: <img src="/assets/icons/logo-apple.svg" alt="apple" />,
          text: "애플로 계속하기",
        }}
        onClick={handleAppleLogin}
        variant={"outlined"}
      />
    </Stack>
  );

  return (
    <>
      <VerticalScrollableStack height={"100%"}>
        <SimpleHeaderSection
          titleTextComponent={"로그인"}
          onClose={handleBack}
        />
        <Spacer h={"55px"} />

        <Stack paddingX={PAGE_PADDING}>
          {renderLoginForm}
          <Spacer h={"80px"} />

          <Stack>
            {renderSectionDivider}
            <Spacer h={"2rem"} />

            {renderSocialLoginButtons}
            <Spacer h={"74px"} />
          </Stack>
        </Stack>
      </VerticalScrollableStack>
    </>
  );
};

export default LoginPage;
