import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../store/QihanoStore";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AnswerEditDialogProps} from "../../../../../../ui/dialogs/childs/answer/edit/AnswerEditDialog";
import {initialReviewEntity} from "../../../../../../domain/entities/review/ReviewEntity";


const initialState: AnswerEditDialogProps = {
    isOpen: false,
    review: initialReviewEntity
}

const AnswerEditDialogSlice = createSlice({
    name: 'AnswerEditDialog',
    initialState: initialState,
    reducers: {
        setAnswerEditDialogState: (state, action: PayloadAction<AnswerEditDialogProps>) =>
            action.payload,
    }
})

export const useAnswerEditDialogSlice = () => {
    const dispatch = useDispatch()
    const data = useSelector((state: RootState) => state.answerEditDialog)

    const mutate = (state: AnswerEditDialogProps) => {
        dispatch(setAnswerEditDialogState(state))
    }

    const close = () => {
        mutate({
            ...data,
            isOpen: false
        })
    }


    return {
        close,
        mutate,
        data
    }
}

export const {setAnswerEditDialogState} = AnswerEditDialogSlice.actions
export default AnswerEditDialogSlice.reducer
