import {AppBar, Button, IconButton, Stack, Toolbar, Typography} from "@mui/material";
import {ArrowBackRounded} from "@mui/icons-material";
import React from "react";
import {createStyles, makeStyles} from "@material-ui/styles";


const useStyles = makeStyles(() => createStyles({
    appbar: {
        backgroundColor: "transparent",
    },
    toolbar: {
        justifyContent: "space-between"
    },
    nextButton: {
        borderRadius: "36px"
    }
}))


export type QuestionHeaderProps = {
    disableSubmitButton: boolean,
    onBack: () => void,
    onSubmit: () => void
}

export const QuestionHeader = (
    {disableSubmitButton, onBack, onSubmit}: QuestionHeaderProps
) => {
    const styles = useStyles()

    return <>
        <AppBar position="static" elevation={1} className={styles.appbar}>
            <Toolbar variant={"dense"} className={styles.toolbar}>
                <IconButton
                    size="large"
                    edge="start"
                    children={<ArrowBackRounded/>}
                    onClick={onBack}
                />

                <Stack direction={"row"}>
                    <Button
                        variant={"contained"}
                        className={styles.nextButton}
                        disabled={disableSubmitButton}
                        onClick={onSubmit}
                    >
                        <Typography
                            variant={"caption"}
                            fontWeight={500}
                            children={"올리기"}
                        />
                    </Button>
                </Stack>
            </Toolbar>
        </AppBar>
    </>
}
